import * as Yup from "yup";

const isValidBIC = (bic) => /^[0-9A-Z]{4}[A-Z]{2}[0-9A-Z]{2}([0-9A-Z]{3})?$/.test(bic.toUpperCase());

const baseVisaB2BSchema = {
    InfoType: Yup.string().required('Network Type is required'),
    type: Yup.string().required('Beneficiary Type is required'),
    entityId: Yup.string().required('Beneficiary Nickname is required, however it can be the same as name'),
    name: Yup.string().required('Beneficiary Name is required'),
    bic: Yup.string().required('BIC or Swift is required').test('isValidBIC', 'Invalid BIC format', isValidBIC),
    paymentType: Yup.string().required('Payment Type is required'),
    street1: Yup.string().required('Street Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
};

let VisaB2BSchema = Yup.object().shape(baseVisaB2BSchema);

const applyRegexConstraints = (fieldSchema, pattern, sample = '') =>
    fieldSchema.matches(new RegExp(`^${pattern}$`), `Invalid format${sample ? ` - required format should look like ${sample}` : ''}`);

const updateFieldSchema = (countryData, config) => {
    const pattern = countryData[config.key]?.regex || countryData[config.key];
    const sample = countryData[config.key]?.sample || '';
    if (config.regex) {
        return applyRegexConstraints(Yup.string().required(config.message), pattern, sample);
    } else if (config.pattern) {
        return config.pattern.required(config.message);
    } else {
        return Yup.string().required(config.message).min(3, `${config.label} must be at least 3 characters`).max(140, `${config.label} must be at most 140 characters`);
    }
};

export const updateVisaB2BSchema = (countryCode, bicCountryCode, ibanSupportedCountries, localeData) => {
    const countrySchema = { ...baseVisaB2BSchema };
    const bicSchema = {};
    const postalCodeData = localeData[countryCode]?.postalCode || {};
    const bicCountryData = localeData[bicCountryCode] || {};
    const ibanData = ibanSupportedCountries.find(item => item.country === bicCountryCode) || {};

    if (postalCodeData.regex) {
        const postalCodePattern = postalCodeData.regex;
        const postalCodeSample = postalCodeData.sample || '';
        countrySchema.postalCode = applyRegexConstraints(
            Yup.string()
                .transform(value => value?.toUpperCase())
                .required('Postal Code / Zip Code is required'),
            postalCodePattern,
            postalCodeSample
        );
    }

    const countryFieldConfigs = [
        { key: "phone", label: 'Phone Number', message: 'Phone Number is required', pattern: Yup.string().matches(/^\+?\d[\d-. ]+\d$/, 'Invalid phone number format') },
        { key: "nationalId", label: 'National ID', message: 'National ID is required', regex: true },
        { key: "branch", label: 'Branch', message: 'Branch is required', regex: true },
        { key: "bank", label: 'Bank', message: 'Bank is required', regex: true },
        { key: "contact", label: 'Beneficiary Contact', message: 'Beneficiary Contact is required' },
        { key: "agent", label: 'Agent', message: 'Agent is required' }
    ];

    countryFieldConfigs.forEach(config => {
        if (bicCountryData[config.key]) {
            countrySchema[config.key] = updateFieldSchema(bicCountryData, config);
        }
    });

    if (!countrySchema.contact && bicCountryData.contact) {
        countrySchema.contact = Yup.string()
            .required('Beneficiary Contact is required')
            .min(3, 'Beneficiary Contact must be at least 3 characters')
            .max(140, 'Beneficiary Contact must be at most 140 characters');
    }

    // Handle branch field separately to accommodate different cases
    if (typeof bicCountryData.branch === 'string') {
        const branchPattern = bicCountryData.branch;
        countrySchema.branch = applyRegexConstraints(
            Yup.string()
                .required('Branch is required'),
            branchPattern
        );
    } else if (bicCountryData.branch === true) {
        countrySchema.branch = Yup.string()
            .required('Branch is required')
            .min(3, 'Branch must be at least 3 characters')
            .max(140, 'Branch must be at most 140 characters');
    }

    const createConditionalSchema = (key, pattern, message) =>
        Yup.string().test('conditional required', `${key} is required`, function (value) {
            return this.parent.paymentType === key ? !!value : true;
        }).matches(pattern, message);

    if (bicCountryCode) {
        bicSchema.iban = ibanData.regex
            ? createConditionalSchema('iban', new RegExp(`^${ibanData.regex}$`), `Invalid IBAN format - required format should look like ${ibanData.sample}`)
            : createConditionalSchema('iban', /^[A-Z]{2}[0-9]{2}[0-9A-Z]{11,30}$/, 'Invalid IBAN format');

        bicSchema.account = bicCountryData.account
            ? createConditionalSchema('account', new RegExp(`^${bicCountryData.account}$`), `Invalid account format`)
            : createConditionalSchema('account', /^\d+$/, 'Account Number must be in correct format');
    }

    VisaB2BSchema = Yup.object().shape({ ...countrySchema, ...bicSchema });
};

export const getVisaB2BSchema = () => VisaB2BSchema;