// material-ui
import {useContext} from "react";
import ConfigContext from "../store/context/ConfigContext";


// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const webConfig = useContext(ConfigContext);
  const logo = webConfig.LOGO_URL;
  return (
    <img src={logo} alt="Logo" width="225" height="70" />
  );
};

export default Logo;
