import React, {useContext} from "react";
// 3rd party
import {useNavigate} from "react-router-dom";
// project imports
import ApprovalsDataGrid from "../ui-component/approvals/approvals-table/ApprovalsDataGrid";
import MainCard from "../ui-component/cards/MainCard";
import {RolesContext} from "../store/context/RolesContext";
import useFetchApprovalsData from "../store/hooks/useFetchApprovalsData";

//-------------------------------------APPROVALS VIEW --------------------------------------//

const Approvals = () => {
    const {
        data,
        fetchData,
        dateFilter,
        isShowDateFilter,
        setIsShowDateFilter
    } = useFetchApprovalsData("/approvals/v1/list");

    const {roles} = useContext(RolesContext);
    const navigate = useNavigate();

    if (!roles.includes("approve::payment")) {
        navigate("/");
    }

    return (
        <MainCard title={""} content={false}>
            <ApprovalsDataGrid
                data={data}
                refreshData={fetchData}
                dateFilter={dateFilter}
                isShowDateFilter={isShowDateFilter}
                setIsShowDateFilter={setIsShowDateFilter}
            />
        </MainCard>
    );
}

export default Approvals;