// assets
import {IconArrowsUpDown, IconReportMoney, IconReplace, IconRoute, IconUserPlus, IconUsers, IconWallet, IconSquareRoundedPlus, IconListSearch, IconNotification, IconHelp, IconLicense, IconBuildingBank, IconListDetails} from '@tabler/icons-react';

const icons = {IconReportMoney, IconArrowsUpDown, IconUserPlus, IconUsers, IconReplace, IconWallet, IconRoute, IconSquareRoundedPlus, IconNotification, IconListSearch, IconHelp, IconLicense, IconBuildingBank, IconListDetails };

const menuItems = [
  {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
      {
        id: 'payment',
        title: 'Make Payment',
        type: 'item',
        url: '/payment',
        icon: icons.IconReplace,
        requiredRoles: ['payment_create'],
        breadcrumbs: false,
      },
      {
        id: 'bankPayment',
        title: 'Bank Initiated Payment',
        type: 'item',
        url: '/bank-transaction',
        icon: icons.IconBuildingBank,
        requiredRoles: ['bank::payment'],
        breadcrumbs: false,
      },
      {
        id: 'transfer',
        title: 'FX Trade',
        type: 'item',
        url: '/transfer',
        icon: icons.IconArrowsUpDown,
        requiredRoles: ['ledgers::transfer'],
        breadcrumbs: false,
      },
      {
        id: 'quote',
        title: 'Get FX Quote',
        type: 'item',
        url: '/quote',
        icon: icons.IconRoute,
        requiredRoles: ['quote'],
        breadcrumbs: false,
      },
      {
        id: 'transaction',
        title: 'Transaction History',
        type: 'item',
        url: '/transaction-history',
        icon: icons.IconWallet,
        requiredRoles: ['payment::view'],
        breadcrumbs: false,
      },
      {
        id: 'bankTransactions',
        title: 'Transaction History All',
        type: 'item',
        url: '/bank-transaction-history',
        icon: icons.IconLicense,
        requiredRoles: ['bank::payment'],
        breadcrumbs: false,
      },
    ],
  },
  {
    id: 'actions',
    title: '',
    caption: '',
    type: 'group',
    children: [
      {
        id: 'createBeneficiary',
        title: 'Create Beneficiary',
        type: 'item',
        url: '/create-beneficiary',
        icon: icons.IconUserPlus,
        requiredRoles: ['beneficiary_create'],
        breadcrumbs: false,
      },
      {
        id: 'viewBeneficiary',
        title: 'View Beneficiaries',
        type: 'item',
        url: '/view-beneficiary',
        icon: icons.IconUsers,
        requiredRoles: ['beneficiaries::view'],
        breadcrumbs: false,
      },
    ],
  },
  {
    id: 'approvals',
    title: '',
    caption: '',
    type: 'group',
    children: [
      {
        id: 'approvals',
        title: 'Approvals',
        type: 'item',
        url: '/approvals',
        icon: icons.IconNotification,
        requiredRoles: ['approve::payment', '!bank::payment'],
        breadcrumbs: false,
      },
      {
        id: 'bankApprovals',
        title: 'Bank Approvals',
        type: 'item',
        url: '/bank-approvals',
        icon: icons.IconListDetails,
        requiredRoles: ['bank::payment', 'approve::payment'],
        breadcrumbs: false,
      },
      {
        id: 'ledgers',
        title: 'MCA',
        type: 'item',
        url: '/ledgers',
        icon: icons.IconReportMoney,
        requiredRoles: ['ledgers::view'],
        breadcrumbs: false,
      },
    ],
  },
  {
    id: 'users',
    title: '',
    caption: '',
    type: 'group',
    children: [
      {
        id: 'inviteUser',
        title: 'Invite User',
        type: 'item',
        url: '/invite-user',
        icon: icons.IconSquareRoundedPlus,
        requiredRoles: ['invite'],
        breadcrumbs: false,
      },
      {
        id: 'userLists',
        title: 'View Users',
        type: 'item',
        url: '/users',
        icon: icons.IconListSearch,
        requiredRoles: ['users::view'],
        breadcrumbs: false,
      },
    ],
  },
{
    id: 'info',
    title: '',
    caption: '',
    type: 'group',
    children: [
      {
        id: 'help',
        title: 'Knowledge Base',
        type: 'item',
        url: 'https://docs.mozrt.io/docs/about-mfx',
        icon: icons.IconHelp,
        requiredRoles: [],
        breadcrumbs: false,
        target: true,
      },
    ],
  },
];

export default menuItems;
