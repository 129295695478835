import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

const NonFormikLedgerDropDown = ({ ledgerData = [], loading, error, setSelectedLedgerId }) => {
    const [selectedLedgerId, setSelectedLedgerIdLocal] = useState('');

    useEffect(() => {
        if (ledgerData && ledgerData.length > 0) {
            const usdLedger = ledgerData.find((ledger) => ledger.currency === 'USD');
            const defaultLedger = ledgerData.find((ledger) => ledger.default === true);

            if (ledgerData.length === 1 && !selectedLedgerId) {
                setSelectedLedgerIdLocal(ledgerData[0].ledgerId);
                setSelectedLedgerId(ledgerData[0].ledgerId);
            } else if (usdLedger && !selectedLedgerId) {
                setSelectedLedgerIdLocal(usdLedger.ledgerId);
                setSelectedLedgerId(usdLedger.ledgerId);
            } else if (defaultLedger && !selectedLedgerId) {
                setSelectedLedgerIdLocal(defaultLedger.ledgerId);
                setSelectedLedgerId(defaultLedger.ledgerId);
            }
        }
    }, [ledgerData, selectedLedgerId, setSelectedLedgerId]);

    const handleLedgerChange = (event) => {
        setSelectedLedgerIdLocal(event.target.value);
        setSelectedLedgerId(event.target.value);
    };

    if (loading) return <CircularProgress />;
    if (error) return <Typography variant="body1">Error: {error.message}</Typography>;

    if (ledgerData.length === 1) {
        setSelectedLedgerId(ledgerData[0].ledgerId);
        return null;
    }

    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel id="ledger-label">Select Ledger</InputLabel>
            <Select
                labelId="ledger-label"
                id="ledger-select"
                value={selectedLedgerId}
                onChange={handleLedgerChange}
                label="Select Ledger"
            >
                {ledgerData.map((ledger) => (
                    <MenuItem key={ledger.ledgerId} value={ledger.ledgerId}>
                        {ledger.description || ledger.ledgerId}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

NonFormikLedgerDropDown.propTypes = {
    ledgerData: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    setSelectedLedgerId: PropTypes.func.isRequired,
};

export default NonFormikLedgerDropDown;