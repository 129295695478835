// Project imports
import {formatDateToMonthDayYearWritten} from 'store/getDate'
import getFormattedCurrencyAmount from 'store/getFormattedCurrencyAmount'
import ensureNotNullAll from 'store/pdf-generator/ensureNotNullAll'

// Assets
import pdfTemplateMozrt from '../../../assets/pdf/mozrt-wire-confirmation-template.pdf'
import pdfTemplateTriad from '../../../assets/pdf/tbb-wire-confirmation-template.pdf'
import pdfTemplateChiMoney from '../../../assets/pdf/chimoney-wire-confirmation-template.pdf'
import pdfTemplateFfb from '../../../assets/pdf/ffb-wire-confirmation-template2.pdf'

// ============================|| Helper function to mask the account number ||============================ //

export const maskAccountNumber = (accountNumber) => {
    if (!accountNumber) return '';
    const accountStr = accountNumber.toString(); // Ensure it's a string
    const lastFourDigits = accountStr.slice(-4);
    const maskedDigits = accountStr.slice(0, -4).replace(/\d/g, 'x');
    return `${maskedDigits}${lastFourDigits}`;
};

// ============================|| FORMAT MULTILINE ADDRESS ||============================ //
const formatAddress = (info) => {
    const {address, city, country, postalCode, regionCode} = info || {};
    const parts = [city, country, regionCode, postalCode]
    const filteredParts = parts.filter(part => part !== '' && part !== undefined)

    const lines = []
    // Add the address as the first line only if it is present
    if (address !== undefined && address !== '') {
        lines.push(address)
    }
    // Loop through the remaining parts and construct lines
    for (let i = 0; i < filteredParts.length; i += 2) {
        // Combine pairs of elements, separated by a comma if the second element is present
        const line = filteredParts[i] + (filteredParts[i + 1] ? `, ${filteredParts[i + 1]}` : '')
        lines.push(line);
    }
    return lines.join('\n');
};

// ============================|| VISA B2B - PDF TEMPLATE OPTIONS ||============================ //

const visab2bTemplateOptionsBank = (transactionData, templateName) => {
    const isoDateString = transactionData.createdAt;
    const beneficiaryBankDetails = transactionData.receiver.details.visab2b
    const originatorDetails = transactionData.originator.details


    const paymentDetails = ensureNotNullAll({

        transactionId: transactionData.id,
        formattedDate: formatDateToMonthDayYearWritten(isoDateString),
        beneficiaryName: transactionData.receiver.entityId,
        currency: transactionData.currency,
        formattedAmount: getFormattedCurrencyAmount(transactionData.amount, transactionData.currency),
        purpose: transactionData.description,
        comments: '',
        addenda: transactionData.addenda,
    })

    const beneficiaryBankInfo = ensureNotNullAll({

        name: beneficiaryBankDetails?.bic,
        account: beneficiaryBankDetails?.account || beneficiaryBankDetails?.iban,
        address: beneficiaryBankDetails?.address?.street1,
        city: beneficiaryBankDetails?.address?.city,
        country: beneficiaryBankDetails?.address?.country,
        regionCode: beneficiaryBankDetails?.address?.regionCode,
        postalCode: beneficiaryBankDetails?.address?.postalCode,
    })

    const senderInfo = ensureNotNullAll({

        name: originatorDetails?.name,
        address: originatorDetails?.address.street1,
        city: originatorDetails?.address.city,
        country: originatorDetails?.address.country,
        regionCode: originatorDetails?.address.regionCode,
        postalCode: originatorDetails?.address.postalCode,
        debit_account: maskAccountNumber(transactionData?.originator?.accountNumber),
    })

    let selectedPdfTemplate;

    if (templateName === null || templateName === undefined) {
        templateName = "Triad Business Bank";
        console.log("Missing Realm Name in Login Response")
    }

    switch (templateName) {
        case "Mozrt":
            selectedPdfTemplate = pdfTemplateFfb;
            break;
        case "FFB":
            selectedPdfTemplate = pdfTemplateFfb;
            break;
        case "Triad Business Bank":
            selectedPdfTemplate = pdfTemplateTriad;
            break;
        case "TBB Dev":
            selectedPdfTemplate = pdfTemplateFfb;
            break;
        case "Chimoney":
            selectedPdfTemplate = pdfTemplateFfb;
            break;
        default:
            console.log("default case", templateName);
            selectedPdfTemplate = pdfTemplateFfb;
            break;
    }

    const templateData = [
        {fieldName: 'date', fieldValue: paymentDetails.formattedDate},
        {fieldName: 'transaction_id', fieldValue: paymentDetails.transactionId},
        {fieldName: 'amount', fieldValue: paymentDetails.formattedAmount},
        {fieldName: 'beneficiary_name', fieldValue: paymentDetails.beneficiaryName},
        {fieldName: 'beneficiary_account', fieldValue: beneficiaryBankInfo.account},
        {fieldName: 'beneficiary_bank_name', fieldValue: beneficiaryBankInfo.name},
        {fieldName: 'beneficiary_bank', fieldValue: formatAddress(beneficiaryBankInfo)},
        // {fieldName: 'beneficiary_bank', fieldValue: formatAddress(beneficiaryBankInfo)},
        {fieldName: 'by_order_of', fieldValue: senderInfo.name},
        {fieldName: 'by_order_of_address', fieldValue: formatAddress(senderInfo)},
        {fieldName: 'debit_account', fieldValue:  senderInfo.debit_account},
        {fieldName: 'purpose', fieldValue: paymentDetails.purpose},
        {fieldName: 'addenda', fieldValue: paymentDetails.addenda},

    ]

    return {templateData, pdfTemplate: selectedPdfTemplate, fileNamePrefix: 'Transaction-id-'};
}

export default visab2bTemplateOptionsBank