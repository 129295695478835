import React from 'react';
import { TextField, Autocomplete, Paper } from '@mui/material';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledAutocomplete = styled(Autocomplete)(() => ({
    "& .MuiFormControl-root": {
        width: "100%",
    },
    "& .MuiInputBase-input": {
        minHeight: "28.5px",
        display: "flex",
        alignItems: "center",
    },
    "& .MuiAutocomplete-inputRoot": {
        paddingRight: "45px !important",
    },
    "& .MuiInputLabel-root": {
        top: '50%',
        transform: 'translate(0, -50%)',
        paddingLeft: '14px',
    },
    "& .Mui-focused .MuiInputLabel-root": {
        top: '0',
        transform: 'translate(0, -100%) scale(0.75)',
        paddingLeft: '14px',
    },
    "& .MuiInputLabel-shrink": {
        top: '0',
        transform: 'translate(0, -35%) scale(0.75)',
        paddingLeft: '20px',
    }
}));

const CustomPaper = (props) => (
    <Paper
        {...props}
        elevation={3}
        sx={{ boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.5)' }}
    />
);

const CustomStyledAutocomplete = ({
                                      name,
                                      label,
                                      options,
                                      onValueChange,
                                      disabled
                                  }) => {
    const { values, touched, errors, setFieldValue } = useFormikContext();

    const handleChange = (event, newValue) => {
        setFieldValue(name, newValue || null);
        if (onValueChange) onValueChange(newValue);
    };

    return (
        <StyledAutocomplete
            PaperComponent={CustomPaper}
            ListboxProps={{ style: { maxHeight: '30rem' }, position: 'bottom-start' }}
            value={values[name] || null}
            onChange={handleChange}
            options={options}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={Boolean(touched[name] && errors[name])}
                    helperText={touched[name] && errors[name]}
                    disabled={disabled}
                />
            )}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disabled={disabled}
        />
    );
};

CustomStyledAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onValueChange: PropTypes.func,
    disabled: PropTypes.bool
};

export default CustomStyledAutocomplete;