import * as Yup from "yup";

// ============================|| InterLedger - Validation ||============================ //
export const InterLedgerSchema = Yup.object().shape({
    InfoType: Yup.string().required('Network Type is required'),
    type: Yup.string().required('Beneficiary Type is required'),
    entityId: Yup.string().required('Entity Reference is required'),
    name: Yup.string().required('Name is required'),
    paymentPointer: Yup.string().required('Payment Pointer is required'),
    street1: Yup.string().required('Street Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    postalCode: Yup.string().required('Zip Code/ Postal Code is required'),
});