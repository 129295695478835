import React, {useState} from 'react';
import {Box, Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from '@mui/material';
import {IconArrowDown} from "@tabler/icons-react";

export function SplitButton({options, isDisabled}) {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const anchorRef = React.useRef(null);

    const handleMenuItemClick = (index) => {
        setSelectedIndex(index);
        setOpen(false);
        if (!isDisabled) {
            options[index].action();
        }
    };

    const handleToggle = () => {
        if (!isDisabled) {
            setOpen((prevOpen) => !prevOpen);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Box sx={{mb: 10}}>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Button
                    onClick={options[selectedIndex].action}
                    color="primary"
                    disabled={isDisabled}
                >
                    {options[selectedIndex].label}
                </Button>
                <Button
                    disabled={isDisabled}
                    color="primary"
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <IconArrowDown/>
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition
                    disablePortal style={{zIndex: 9999}}>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}

                    >
                        <Paper
                            style={{backgroundColor: 'white', zIndex: 9999, boxShadow: '0px 2px 4px rgba(0,0,0,0.25)'}}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option.label}
                                            selected={index === selectedIndex}
                                            onClick={() => handleMenuItemClick(index)}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
}