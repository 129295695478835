const getDecimalPlaces = (currency) => {
    switch (currency) {
        case 'BIF':
        case 'CLP':
        case 'DJF':
        case 'GNF':
        case 'ISK':
        case 'JPY':
        case 'KMF':
            return 0;
        case 'BHD':
        case 'IQD':
        case 'JOD':
        case 'KWD':
        case 'LYD':
        case 'OMR':
        case 'TND':
            return 3;
        case 'CLF':
        case 'UYW':
            return 4;
        default:
            return 2;
    }
};

const shouldFixDecimalScale = (currency) => {
    return getDecimalPlaces(currency) !== 0;
};

export { getDecimalPlaces, shouldFixDecimalScale };
