export const mapInitialEntityValues = (initialValues, defaultInfoType) => {
    if (!initialValues) {
        return {
            InfoType: defaultInfoType,
            type: "",
            entityId: "",
            name: '',
            achType: 'dda',
            aba: '',
            account: '',
            bic: '',
            iban: '',
            branch: '',
            street1: '',
            city: '',
            state: '',
            country: '',
            postalCode: '',
            paymentPointer: '',
            phoneNumber: '',
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            bankAddressLine1: "",
            bankAddressLine2: "",
            bankAddressLine3: "",
            swift: "",
            bankName: "",
            totp: '',
        };
    }

    const details = initialValues.details || {};
    const { visab2b, ach, wire } = details;

    let infoType;
    if (visab2b) {
        infoType = 'visab2b';
    } else if (ach) {
        infoType = 'ach';
    } else if (wire) {
        infoType = 'wire';
    } else {
        infoType = defaultInfoType;
    }

    const address = (visab2b && visab2b.address) || {};

    return {
        InfoType: infoType,
        type: details.type || "",
        entityId: initialValues.entityId || "",
        name: details.name || '',
        achType: ach ? ach.type || 'dda' : 'dda',
        aba: ach ? ach.aba || '' : '',
        account: ach ? ach.account || '' : (visab2b ? visab2b.account || '' : (wire ? wire.account || '' : '')),
        bic: visab2b ? visab2b.bic || '' : '',
        iban: visab2b ? visab2b.iban || '' : '',
        branch: visab2b ? visab2b.branch || '' : '',
        bank: visab2b ? visab2b.bank || '' : '',
        street1: address.street1 || '',
        city: address.city || '',
        state: address.regionCode || '',
        country: address.country || '',
        postalCode: address.postalCode || '',
        nationalId: visab2b ? visab2b.nationalId || '' : '',
        phone: visab2b ? visab2b.phone || '' : '',
        agent: visab2b ? visab2b.agent || '' : '',
        contact: visab2b ? visab2b.contact || '' : '',
        paymentPointer: wire ? wire.paymentPointer || '' : '',
        phoneNumber: ach ? ach.phoneNumber || '' : '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        bankAddressLine1: '',
        bankAddressLine2: '',
        bankAddressLine3: '',
        swift: '',
        bankName: wire && wire.bank ? wire.bank.name || '' : '',
        totp: '',
    };
};
