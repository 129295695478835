
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import CustomFormField from "../form-fields/CustomFormField";
import CustomDropdown from "../form-fields/CustomDropdown";
import { useCountryRegions } from "../../store/hooks/useCountryRegions";
import { Divider, Grid } from "@mui/material";
import { getCurrencies } from "../../store/hooks/useConfiguredCurrencies";
import axios from "axios";
import { useAuthHeaders } from "../../auth/AuthHeaders";
import ConfigContext from "../../store/context/ConfigContext";
import { handleBicChangeEvent, handleCountryChangeEvent } from "./handleCountryOrBicChange";

export const ibanRegex = /[A-Z]{2}[0-9]{2}[0-9A-Z]{11,30}/;

//----------------------------  Visa b2b beneficiary creation form  --------------------------------------//
export const VisaB2BForm = ({
                                values,
                                handleChange,
                                handleBlur,
                                touched,
                                errors,
                                setFieldValue,
                                onSchemaUpdate,
                            }) => {
    const [localeData, setLocaleData] = useState({});
    const [ibanSupportedCountries, setIbanSupportedCountries] = useState(new Set());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formFields, setFormFields] = useState([]);
    const [postalCodeField, setPostalCodeField] = useState(null);
    const [supportsIban, setSupportsIban] = useState(false);
    const [supportsAccount, setSupportsAccount] = useState(false);

    const config = useContext(ConfigContext);
    const { countries, regions, handleCountryChange } = useCountryRegions(handleChange);
    const currencyOptions = getCurrencies();
    const authHeaders = useAuthHeaders();

    // Fetch locale data
    const fetchLocaleData = useCallback(async () => {
        try {
            const response = await axios.post(`${config.API_URL}/locale/v1/get`, {}, {
                headers: authHeaders
            });
            const localeData = response.data;
            const ibanSupportedCountries = new Set(Object.entries(localeData)
                .filter(([_, data]) => data.iban)
                .map(([countryCode, data]) => ({ country: countryCode, ...data.iban })));
            setLocaleData(localeData);
            setIbanSupportedCountries(ibanSupportedCountries);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }, []);

    const handleCountryChangeCallback = useCallback((event) => {
        handleCountryChangeEvent({
            values,
            setFieldValue,
            localeData,
            setPostalCodeField,
            event,
            handleCountryChange,
            ibanSupportedCountries,
            onSchemaUpdate
        });
    }, [values.country]);

    const handleBicChangeCallback = useCallback(() => {
        handleBicChangeEvent({
            values,
            setFieldValue,
            localeData,
            ibanSupportedCountries,
            currencyOptions,
            setFormFields,
            setSupportsIban,
            setSupportsAccount,
            onSchemaUpdate,
            handleCountryChange,
            setPostalCodeField
        });
    }, [values.bic, localeData]);

    useEffect(() => {
        fetchLocaleData();
    }, [fetchLocaleData]);

    useEffect(() => {
        // Handle initial load or updates to the country field
        if (values.country) {
            handleCountryChangeCallback({ target: { name: 'country', value: values.country } });
        }
    }, [values.country, handleCountryChangeCallback]);

    useEffect(() => {
        if (values.bic) {
            handleBicChangeCallback();
        }
    }, [values.bic, handleBicChangeCallback]);



    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={4}>
                <CustomFormField
                    name="bic"
                    label="BIC / Swift"
                    value={values.bic}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                />
            </Grid>
            {supportsIban && (
                <Grid item xs={6} sm={4}>
                    <CustomFormField
                        name="iban"
                        label="Enter IBAN"
                        value={values.iban}
                        handleChange={(event) => {
                            if (event.target.name === 'iban') {
                                event.target.value = event.target.value.replace(/\s+/g, '');
                            }
                            handleChange(event);
                        }}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                    />
                </Grid>
            )}
            {supportsAccount && (
                <Grid item xs={12} sm={4}>
                    <CustomFormField
                        name="account"
                        label="Enter Account"
                        value={values.account}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                    />
                </Grid>
            )}
            <Grid item xs={12} sm={4}>
                {values.bic && (
                    <CustomDropdown
                        name="currency"
                        label="Preferred Currency"
                        value={values.currency}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        options={[
                            ...currencyOptions.map(option => ({
                                value: option.value,
                                label: option.label,
                            })),
                            { value: 'None', label: 'None' }
                        ]}
                    />
                )}
            </Grid>
            {formFields.map(field => (
                <Grid item xs={12} sm={4} key={field.name}>
                    <CustomFormField
                        name={field.name}
                        label={field.label}
                        value={values[field.name]}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                    />
                </Grid>
            ))}
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} sm={4}>
                <CustomDropdown
                    name="country"
                    label="Country"
                    value={values.country}
                    handleChange={handleCountryChangeCallback}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    options={countries}
                    maxHeight={"50vh"}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                {values.country && (
                    <CustomDropdown
                        name="state"
                        label="Region/State"
                        value={values.state}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        options={regions}
                    />
                )}
            </Grid>
            <Grid item xs={12} sm={4}>
                <CustomFormField
                    name="city"
                    label="City"
                    value={values.city}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                />
            </Grid>
            <Grid item xs={12} sm={8}>
                <CustomFormField
                    name="street1"
                    label="Street Address"
                    value={values.street1}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    validateChars={true}
                />
            </Grid>

            {postalCodeField && (
                <Grid item xs={12} sm={4}>
                    <CustomFormField
                        name={postalCodeField.name}
                        label={postalCodeField.label}
                        value={values[postalCodeField.name]}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                    />
                </Grid>
            )}
        </Grid>
    );
};

VisaB2BForm.propTypes = {
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    touched: PropTypes.object,
    errors: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    onSchemaUpdate: PropTypes.func.isRequired
};