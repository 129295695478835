import React, { useState } from 'react';
import { Button, Card, CardHeader, Divider, Typography } from '@mui/material';
import CreateEntityModal from "../extended/modal/CreateEntityModal";
import { useTheme } from "@mui/material/styles";

const CreateBeneficiaryWidget = () => {
    const theme = useTheme();
    const [isModalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    return (
        <>
            <Card
                sx={{
                    border: '1px solid',
                    borderColor: theme.palette.primary.light,
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    minHeight: '150px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    ':hover': {
                        boxShadow: '2px 2px 24px 0 rgb(32 40 45 / 8%)'
                    },
                }}
            >
                <CardHeader sx={{ p: 2.5 }} title={<Typography variant="h4">Create a New Beneficiary</Typography>} />
                <Divider
                    sx={{
                        opacity: 1,
                        borderColor: theme.palette.primary.light
                    }}
                />
                <Typography variant="h6" gutterBottom align="center" sx={{ flexGrow: 1 }}>
                </Typography>
                <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{ mb: 2, alignSelf: 'center' }}>
                    Create Beneficiary
                </Button>
            </Card>
            <CreateEntityModal open={isModalOpen} handleClose={handleCloseModal} />
        </>
    );
}

export default CreateBeneficiaryWidget;