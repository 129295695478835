import React, {useState} from 'react';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarQuickFilter
} from '@mui/x-data-grid';
import RefreshIcon from '@mui/icons-material/Refresh';
import {Box, Button, TextField} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {DatePicker} from "@mui/x-date-pickers";
import {TuneRounded} from "@mui/icons-material";
import {datePickerStyle} from "../approvals/approvals-table/ApprovalsToolbar";

// ==============================|| CUSTOM FILTER DATA GRID ||============================== //
function QuickSearchToolbar() {
    const theme = useTheme();
    return (
        <Box
            sx={{
                pb: 1,
                pl: 3,
                pr: 2,
                pt: 2,
                '& .MuiFormControl-root > .MuiInputBase-root': {
                    p: 0.6,
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: 2,
                    background: theme.palette.grey[50]
                },
                '& .MuiFormControl-root > .MuiInputBase-root:after': {
                    display: 'none'
                },
                '& .MuiFormControl-root > .MuiInputBase-root:before': {
                    display: 'none'
                },
                '& .MuiFormControl-root > .Mui-focused': {
                    border: `1px solid ${theme.palette.primary.main}`
                },
                '& .MuiSvgIcon-root': {
                    color: `${theme.palette.primary.main} !important`,
                },

            }}
        >
            <GridToolbarQuickFilter/>
        </Box>
    );
}

// ==============================|| Export All Columns Function ||============================== //

const csvOptions = {
    allColumns: true,
};
// ==============================|| RefreshQueryToolbar With All Fields ||============================== //

export const RefreshQueryToolbar = ({onRefresh}) => {
    return (
        <GridToolbarContainer sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: "center"}}>
                <GridToolbarColumnsButton color="secondary"/>
                <GridToolbarExport color="secondary" csvOptions={csvOptions}/>
                <Button
                    onClick={onRefresh}
                    size="small"
                    startIcon={<RefreshIcon/>}
                    color="secondary"
                    sx={{marginLeft: '8px', textTransform: 'none'}}
                >
                    Refresh
                </Button>
            </Box>
            <QuickSearchToolbar/>
        </GridToolbarContainer>
    );
}

// ==============================|| RefreshQueryToolbar With Date Picker ||============================== //
export const RefreshQueryWithDatePicker = ({onRefresh, startDate, setStartDate, endDate, setEndDate}) => {
    const [showDatePickers, setShowDatePickers] = useState(false);

    const handleFilterClick = () => {
        setShowDatePickers(!showDatePickers);
    };

    return (
        <GridToolbarContainer sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: "center"}}>
                    <GridToolbarColumnsButton/>
                    <GridToolbarExport/>
                    <Button
                        onClick={onRefresh}
                        size="small"
                        startIcon={<RefreshIcon/>}
                        color="primary"
                        sx={{marginLeft: '8px', textTransform: 'none'}}
                    >
                        Refresh
                    </Button>
                    <Button
                        onClick={handleFilterClick}
                        size="small"
                        startIcon={<TuneRounded/>}
                        color="primary"
                        sx={{marginLeft: '8px', textTransform: 'none'}}
                    >
                        Date Range
                    </Button>
                </Box>
                <QuickSearchToolbar/>
            </Box>
            {showDatePickers && (
                <Box sx={{display: 'flex', gap: 2, ml:1, mt:-2, width: '100%'}}>
                    <DatePicker
                        sx={datePickerStyle}
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DatePicker
                        sx={datePickerStyle}
                        label="End Date"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Box>
            )}
        </GridToolbarContainer>

    );
}
