import {useContext} from "react";
import {RolesContext} from "../store/context/RolesContext";
import {useNavigate} from "react-router-dom";
import {BankTransaction} from "../requests/credit-request/BankTransaction/BankTransaction";
import {Container} from "@mui/material";


const BankTransactionView = () => {
    const {roles} = useContext(RolesContext)
    const navigate = useNavigate();

    if (!roles.includes("bank::payment")) {
        navigate("/");
    }

    return<Container maxWidth={"lg"}>
        <BankTransaction/>
    </Container>

}
export default BankTransactionView;