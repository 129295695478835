import {useContext} from "react";
import {RolesContext} from "../store/context/RolesContext";
import {useNavigate} from "react-router-dom";
import TransactionHistory from "../requests/TransactionHistory";
import useLedgers from "../store/hooks/useLedgerDetails";


const ViewTransactions = () => {
    const {roles} = useContext(RolesContext)
    const navigate = useNavigate();
    const {activeLedgers, loading: ledgerLoading, error} = useLedgers();

    if (!roles.includes("payment::view")) {
        navigate("/");
    }

    return <TransactionHistory activeLedgers={activeLedgers} ledgerLoading={ledgerLoading} error={error} />
}
export default ViewTransactions;