import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import React, {useState} from "react";
import OtpInput from "react18-input-otp";
import {reformatDetailsForRendering, renderBankDetails} from "../../../store/renderBankDetails";

export const CreateBeneficiaryTOTPModal = ({isModalOpen, setIsModalOpen, onConfirm, values}) => {
    const [localTotp, setLocalTotp] = useState('');
    const formattedDetails = reformatDetailsForRendering(values);

    const handleConfirm = () => {
        const totpRequestData = {
            ...values,
            totp: localTotp,
        };
        onConfirm(totpRequestData);
        setIsModalOpen(false);

    };

    return (
        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <DialogTitle sx={{
                fontSize: '1.5em',
                fontWeight: 'bold',
                color: '#333',
                padding: '20px',
                marginBottom: '15px',
                border: '1px solid #ddd',
                transition: 'all 0.5s ease'
            }}>Confirm Beneficiary Details</DialogTitle>
            <DialogContent>
                {renderBankDetails(formattedDetails, values.name)}
                <Divider sx={{my: 2}}/>
                <Typography variant="h4" sx={{mb: 2}}>
                    Enter 6-digit authentication code and click confirm to proceed.
                </Typography>
                <OtpInput
                    value={localTotp}
                    onChange={setLocalTotp}
                    numInputs={6}
                    containerStyle={{justifyContent: 'space-between'}}
                    inputStyle={{
                        width: '100%',
                        margin: '8px',
                        padding: '10px',
                        border: `1px solid `,
                        borderRadius: 4,
                        ':hover': {
                            borderColor: "#000"
                        }
                    }}
                    focusStyle={{
                        outline: 'none',
                        border: `2px solid #000`
                    }}
                    shouldAutoFocus={true}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsModalOpen(false)}
                        variant={"contained"}
                        sx={{backgroundColor: "#FF0000", color: "#fff"}}
                        size={"large"}
                >
                    Cancel
                </Button>
                <Button onClick={handleConfirm}
                        sx={{backgroundColor: '#008000', color: '#fff'}}
                        variant="contained"
                        size="large">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
