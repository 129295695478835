import React, {useContext} from "react";

// material-ui
import {Box} from "@mui/material";
// project imports
import {useNavigate} from "react-router-dom";
import {RolesContext} from "../store/context/RolesContext";
import GetLedgers from "../requests/GetLedgers";

// ============================|| PAGE - Ledgers ||============================ //
const Ledgers = () => {
    const {roles} = useContext(RolesContext)
    const navigate = useNavigate();

    if (!roles.includes("ledgers::view")) {
        navigate("/");
    }

    return (
        <Box sx={{p: 2}}>
            <GetLedgers/>
        </Box>
    );
};

export default Ledgers;