import {useContext, useEffect, useState} from 'react';

// project imports
import {useAuthHeaders} from "../../auth/AuthHeaders";
import ConfigContext from "../context/ConfigContext";

// third-party
import axios from 'axios';


// -------------Get Detailed Entity Results -----------------//

const useEntityDetails = (EntityId, clientId) => {
    const [entityDetails, setEntityDetails] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const authHeaders = useAuthHeaders();
    const config = useContext(ConfigContext);
    const sessionId = sessionStorage.getItem("clientId");
    const finalClientId = clientId || sessionId;

    const fetchEntityDetails = async () => {
        try {
            const response = await axios.post(`${config.API_URL}/figaro/v1/beneficiaries`, {
                clientId: finalClientId,
                request: {
                    entityId: EntityId
                }

            }, {
                headers: authHeaders,
            });
            setEntityDetails(response.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                sessionStorage.clear();
                window.location.href = '/token-expiry';
            }
            console.error("Error fetching entity details:", error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (EntityId && finalClientId) {
            fetchEntityDetails();
        }
    }, [EntityId, finalClientId]);

    return { entityDetails, error, loading };
};

export default useEntityDetails;
