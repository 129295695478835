import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import TermsOfServiceModal from "../ui-component/extended/modal/TosModal";

const Tos = () => {
    const [showTOSModal, setShowTOSModal] = useState(false);
    const {state} = useLocation();
    const tos = state?.tos;


    useEffect(() => {
        setShowTOSModal(true);
    }, []);

    const handleCloseTOSModal = () => {
        setShowTOSModal(false);
    };


    return <>
        {showTOSModal && tos && (
            <TermsOfServiceModal
                open={showTOSModal}
                onClose={handleCloseTOSModal}
                tosReference={tos.reference}
                tosUrl={tos.url}
            />
        )}
    </>
}
export default Tos;
