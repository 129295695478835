import React, {useState} from 'react';
// materialUI imports
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';

// 3rd Party imports
import OtpInput from "react18-input-otp";
import useEntityDetails from "../../../store/hooks/useEntityDetails";
import {renderBankDetails} from "../../../store/renderBankDetails";
import {getDisplayedAmounts} from "./ConfirmationModal";
import {useFormikContext} from "formik";

const styles = {
    card: {
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        backgroundColor: 'white',
    },
    header: {
        backgroundColor: '#f2f2f2',
        padding: '18px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
    },
    table: {
        border: 'none',
    },
    tableCell: {
        fontSize: '18px',
        fontWeight: 'normal',
    },
};

const BankConfirmationModal = ({
                                   open,
                                   onCancel,
                                   transaction,
                                   onTOTPChange
                               }) => {
    const {setFieldValue, handleSubmit} = useFormikContext();
    const [totp, setTotp] = useState('');
    const {entityDetails} = useEntityDetails(transaction.receiverDetails.value, transaction.sendingClient.value);

    if (!transaction) {
        return null;
    }

    const {sellAmount, buyAmount, currency, rate, inverseRate, fxQuoteId} = transaction;
    const {sendsAmount, receivesAmount} = getDisplayedAmounts(sellAmount, buyAmount, "USD", currency);

    const handleConfirmClick = async () => {
        setFieldValue('totp', totp);
        setFieldValue('fxQuoteId', fxQuoteId);
        setFieldValue('entityId', transaction.receiverDetails.value);
        setFieldValue('entityId', transaction.receiverDetails.value);
        handleSubmit();
    };

    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogContent>
                <Paper style={styles.card}>
                    <div style={styles.header}>
                        <Typography variant="h2">Transaction Confirmation</Typography>
                    </div>
                    <TableContainer component={Paper} style={styles.table}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{...styles.tableCell}}>
                                        {entityDetails?.details?.name} receives: <b>{receivesAmount}</b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{...styles.tableCell}}>
                                        {transaction.sendingClient.label} sends: <b>{sendsAmount}</b>
                                    </TableCell>
                                </TableRow>
                                {rate !== "1" &&
                                    <TableRow>
                                        <TableCell sx={{...styles.tableCell}}>
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <div>Rate: <b>{rate}</b></div>
                                                <div>Inverse Rate: <b>{inverseRate}</b></div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                }
                                <TableRow>
                                    <TableCell sx={{...styles.tableCell}}> <Typography variant="h3"
                                                                                       gutterBottom> Beneficiary
                                        Details:</Typography>
                                        {entityDetails?.details && renderBankDetails(entityDetails.details, entityDetails.details.name)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{...styles.tableCell}}>
                                        Enter 6-digit authentication code:
                                        <OtpInput
                                            value={totp}
                                            onChange={(totpNumber) => {
                                                setTotp(totpNumber);
                                                onTOTPChange(totpNumber);
                                            }}
                                            numInputs={6}
                                            containerStyle={{justifyContent: 'space-between'}}
                                            inputStyle={{
                                                width: '100%',
                                                margin: '8px',
                                                padding: '10px',
                                                border: `1px solid`,
                                                borderRadius: 4,
                                                ':hover': {
                                                    borderColor: "#000"
                                                }
                                            }}
                                            focusStyle={{
                                                outline: 'none',
                                                border: `2px solid #000`
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="contained" sx={{backgroundColor: "#FF0000", color: "#fff"}}
                        size="large">
                    Cancel
                </Button>
                <Button
                    onClick={handleConfirmClick}
                    sx={{backgroundColor: '#008000', color: '#fff'}}
                    variant="contained"
                    size="large"
                    disabled={totp.length !== 6}
                >
                    Approve
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BankConfirmationModal;