import React, {useContext, useEffect, useState} from 'react';
// material-ui
import {DataGrid} from '@mui/x-data-grid';
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import {styled} from "@mui/system";
// project imports
import TransactionSummary from "../ui-component/TransactionSummary";
import {RefreshQueryWithDatePicker} from "../ui-component/datagrid/RefreshQueryToolbar";
import ConfigContext from "../store/context/ConfigContext";
import {useAuthHeaders} from "../auth/AuthHeaders";
import {bankTransactionHistoryColumns} from "../ui-component/datagrid/transactionHistoryColumns";
// third-party
import axios from 'axios';
import {useClientAccount} from "../store/context/ClientAccountContext";
import {RolesContext} from "../store/context/RolesContext";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {CustomNoRowsOverlay} from "../ui-component/datagrid/CustomEmptyDataTable";

// ============================|| Material UI TableGrid Style  ||============================ //
export const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .MuiDataGrid-toolbarContainer': {
        marginBottom: '16px',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: theme.palette.primary.main,
        fontWeight: 'bold',
        color: '#FFF',
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '.75rem',
        },
    },
    '& .MuiDataGrid-sortIcon': {
        color: '#FFF', // white color
    },
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root': {
        color: '#FFF',
    },

}));

// ============================|| Bank Transaction History Page  ||============================ //
const Transactions = ({compactView = false}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [refreshFlag, setRefreshFlag] = useState(false);

    const endOfDay = dayjs().endOf('day');
    const threeDaysAgo = dayjs().subtract(3, 'day').startOf('day');

    const [startDate, setStartDate] = useState(dayjs(threeDaysAgo));
    const [endDate, setEndDate] = useState(endOfDay);


    const authHeaders = useAuthHeaders();
    const config = useContext(ConfigContext);
    const {clientId} = useClientAccount();
    const {roles} = useContext(RolesContext)
    const navigate = useNavigate();

    if (!roles.includes("bank::payment")) {
        navigate("/");
    }

    async function fetchTransactionHistory() {
        try {
            const response = await axios.post(`${config.API_URL}/bank/v1/transactions`, {
                bankId: clientId,
                begin: startDate,
                end: endDate,

            }, {
                headers: authHeaders,
            });

            const processedTransactions = response.data.map((tx, index) => {
                let extractedData = extractTransactionData(tx);
                extractedData.id = index;
                return extractedData;
            });

            setData(processedTransactions.reverse());
        } catch (error) {
            if (error.response && error.response.status === 401) {
                sessionStorage.clear();
                window.location.href = '/token-expiry';
            } else {
                console.error("An error occurred:", error);
            }
        } finally {
            setLoading(false);
        }
    }

    // Fetch transaction history when refreshFlag or clientId changes
    useEffect(() => {
        setLoading(true);
        fetchTransactionHistory();
    }, [refreshFlag, clientId, startDate, endDate]);

    const extractTransactionData = (transaction) => {
        const {id, reference, created, state, amount, currency, fx, client, entity} = transaction;
        return {
            id,
            reference,
            createdAt: created,
            state,
            sendAmount: amount,
            sendCurrency: currency,
            receiveAmount: fx && fx.amount ? fx.amount : amount,
            receiveCurrency: fx && fx.currency ? fx.currency : currency,
            client: client.name,
            clientId: client.id,
            entity: entity.name,
            clientRate: fx && fx.clientRate ? fx.clientRate : '',
            actualSellAmount: fx && fx.actualSellAmount ? fx.actualSellAmount : ''
        };
    };

    const handleRowClickForTransactionDetailModal = async (sendingClient, reference) => {
        try {
            const response = await axios.post(`${config.API_URL}/figaro/v1/payment`, {
                clientId: sendingClient,
                request: {
                    creditReference: reference,
                }
            }, {
                headers: authHeaders,
            });

            setSelectedTransaction(response.data);
            setModalOpen(true);
        } catch (error) {
            console.error("Error fetching transaction details:", error);
        }
    };

    const handleRefresh = () => {
        setRefreshFlag(!refreshFlag);
    };

    return (
        <div style={{height: compactView ? '400px' : '75vh', width: '100%', marginTop: "2vh"}}>
            <StyledDataGrid
                rows={data}
                columns={bankTransactionHistoryColumns}
                onRowClick={(param) => {
                    handleRowClickForTransactionDetailModal(param.row.clientId, param.row.reference);
                }}
                slots={{
                    toolbar: compactView ? null : () => <RefreshQueryWithDatePicker onRefresh={handleRefresh}
                                                                                    data={data} startDate={startDate}
                                                                                    setStartDate={setStartDate}
                                                                                    endDate={endDate}
                                                                                    setEndDate={setEndDate}/>,
                    noRowsOverlay: CustomNoRowsOverlay
                }}
                sx={{
                    boxShadow: 5,
                    border: 2,
                    borderColor: 'primary.light',
                    backgroundColor: '#fff',
                    '& .MuiDataGrid-cell:hover': {
                        color: 'primary.main',
                    },
                }}
                loading={loading}
                pageSizeOptions={compactView ? [5] : [5, 10, 25]}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            type: false,
                            clientRate: false,
                            actualSellAmount: false,
                            sendCurrency: false,
                            receiveCurrency: false
                        },
                    },
                    sorting: {
                        sortModel: [{field: 'createdAt', sort: 'desc'}],
                    },
                    pagination: {
                        paginationModel: {
                            pageSize: compactView ? 5 : 25,
                        }
                    },
                }}
            />
            <Dialog
                open={isModalOpen}
                onClose={() => setModalOpen(false)}
                fullWidth
                maxWidth={"md"}
            >
                <DialogContent>
                    <TransactionSummary
                        selectedTransaction={selectedTransaction}
                        refetchTransactions={handleRefresh}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setModalOpen(false)} color="secondary" variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default Transactions;
