import React, {useContext, useEffect, useRef, useState} from 'react';
// 3rd Party imports
import * as Yup from 'yup';
import {Formik} from 'formik';
import axios from "axios";

// materialUI imports
import {Box} from '@mui/material';
// project imports
import {useAuthHeaders} from "../auth/AuthHeaders";
import ConfigContext from "../store/context/ConfigContext";
import {useClientAccount} from "../store/context/ClientAccountContext";
import {ResponseDisplay} from "./response/ResponseDisplay";
import useFetchRate from "../store/hooks/useFetchRate";
import TransferForm from "./TransferForm";
import dayjs from "dayjs";


// ============================|| Create - Transfer ||============================ //
const CreateTransfer = () => {
    const responseRef = useRef(null);
    const [response, setResponse] = useState(null);
    const [showTotpInput, setShowTotpInput] = useState(false);
    const [localTotp, setLocalTotp] = useState('');
    const [showFetchRateButton, setShowFetchRateButton] = useState(false);
    const [fromCurrency, setFromCurrency] = useState('');
    const [toCurrency, setToCurrency] = useState('');
    const [fxQuoteId, setFxQuoteId] = useState(null);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [isSubmitted, setIsSubmitted] = useState(false);

    const authHeaders = useAuthHeaders();
    const config = useContext(ConfigContext);
    const {clientId} = useClientAccount();
    const {rate, fetchRate, apiResponse, error: apiError} = useFetchRate(config, authHeaders, clientId, "Spearhead Corporate Services PVT Li");

    const handleCreateTransferSubmit = async (values, {setSubmitting}) => {
        if (!showTotpInput) {
            setShowTotpInput(true);
            setSubmitting(false);
        } else {
            try {
                const options = {
                    method: 'POST',
                    url: `${config.API_URL}/figaro/v1/transfer`,
                    headers: authHeaders,
                    data: {
                        clientId: clientId,
                        totp: localTotp,
                        request: {
                            currency: fromCurrency,
                            amount: values.amount,
                            description: values.description,
                            reference: values.reference,
                            fromLedgerId: values.fromLedgerId,
                            toLedgerId: values.toLedgerId,
                            date: values.settlementDate,
                            ...(fxQuoteId && {fxQuoteId: fxQuoteId}),
                        },
                    },
                };
                const response = await axios.request(options);
                setResponse(response.data);
                setShowTotpInput(false);
                setIsSubmitted(true);
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setSubmitting(false);
            }
        }
    };

    useEffect(() => {
        if (apiResponse && apiResponse.response.id) {
            setFxQuoteId(apiResponse.response.id);
        }
    }, [apiResponse]);

    const handleFetchRate = async (values) => {
        await fetchRate(toCurrency, "buyAmount", values.amount, values.fromLedgerId, selectedDate);
    };

    return (
        <Box>
            <Formik
                initialValues={{
                    quoteId: '',
                    currency: 'USD',
                    amount: '',
                    description: '',
                    reference: '',
                    fromLedgerId: '',
                    toLedgerId: '',
                    totpCode: '',
                    settlementDate: new Date(),

                }}
                validationSchema={Yup.object().shape({
                    amount: Yup.string().required('Amount is required'),
                    description: Yup.string().required('Description is required'),
                    reference: Yup.string().required('Reference is required'),
                    fromLedgerId: Yup.string().required('From Ledger ID is required'),
                    toLedgerId: Yup.string().required('To Ledger ID is required'),
                })}

                onSubmit={async (values, formikHelpers) => {
                    await handleCreateTransferSubmit(values, formikHelpers);
                }}

            >
                {formikProps => (
                    <TransferForm
                        {...formikProps}
                        handleSubmit={formikProps.handleSubmit}
                        responseRef={responseRef}
                        response={response}
                        setResponse={setResponse}
                        showTotpInput={showTotpInput}
                        setShowTotpInput={setShowTotpInput}
                        localTotp={localTotp}
                        setLocalTotp={setLocalTotp}
                        showFetchRateButton={showFetchRateButton}
                        setShowFetchRateButton={setShowFetchRateButton}
                        handleFetchRate={handleFetchRate}
                        rate={rate}
                        apiResponse={apiResponse}
                        fromCurrency={fromCurrency}
                        setFromCurrency={setFromCurrency}
                        toCurrency={toCurrency}
                        setToCurrency={setToCurrency}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        isSubmitted={isSubmitted}
                        apiError={apiError}
                    />
                )}
            </Formik>
            {response && (
                <Box mt={2} ref={responseRef}>
                    <ResponseDisplay response={response}/>
                </Box>
            )}
        </Box>
    );
};

export default CreateTransfer;