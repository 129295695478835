import React, { useState } from 'react';
import {Grid, Typography, IconButton, Tooltip, Divider} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import OtpInput from 'react18-input-otp';
import CustomFormField from "../../../form-fields/CustomFormField";
import { useCountryRegions } from "../../../../store/hooks/useCountryRegions";
import CustomDropdown from "../../../form-fields/CustomDropdown";

//-------------------------------------|| Country Regions Dropdown || --------------------------------------//
const CountryRegionsDropdown = ({ handleChange, values, handleBlur, touched, errors }) => {
    const { countries, regions, handleCountryChange } = useCountryRegions(handleChange, 'US');

    return (
        <>
            <Grid item xs={12} md={6}>
                <CustomDropdown
                    name="country"
                    label="Country"
                    value={values.country}
                    handleChange={handleCountryChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    options={countries}
                    maxHeight={"50vh"}
                />
            </Grid>
            {regions.length > 0 && (
                <Grid item xs={12} md={6}>
                    <CustomDropdown
                        name="stateProvince"
                        label="Region/State"
                        value={values.stateProvince}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        options={regions}
                    />
                </Grid>
            )}
        </>
    );
};

//-------------------------------------|| Create Client Modal Fields || --------------------------------------//
const CreateClientFields = ({ values, handleChange, handleBlur, touched, errors }) => {
    const [showStreet2, setShowStreet2] = useState(false);

    const handleAddStreet2 = () => {
        setShowStreet2(true);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
                <CustomFormField
                    name="name"
                    label="Name"
                    value={values.name}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                />
            </Grid>
            <Grid container spacing={2} item xs={12}>
                <CountryRegionsDropdown
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomFormField
                    name="street1"
                    label="Street Address"
                    value={values.street1}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    validateChars={true}
                />
            </Grid>
            {!showStreet2 && (
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Tooltip title="Add second address line">
                        <IconButton onClick={handleAddStreet2} aria-label="add street address 2" size="large">
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
            {showStreet2 && (
                <Grid item xs={12} md={6}>
                    <CustomFormField
                        name="street2"
                        label="Street Address 2"
                        value={values.street2}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        validateChars={true}
                    />
                </Grid>
            )}
            <Grid item xs={12} md={6}>
                <CustomFormField
                    name="city"
                    label="City"
                    value={values.city}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomFormField
                    name="postalZipCode"
                    label="Zip Code / Postal Code"
                    value={values.postalZipCode}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    validateChars={true}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomFormField
                    name="accountNumber"
                    label="Account Number"
                    value={values.accountNumber}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                />
            </Grid>
            <Grid item xs={12} mb={2}>
                <Divider sx={{mb:2}}/>
                <Typography>Enter 6-digit authentication code:</Typography>
                <OtpInput
                    value={values.totp}
                    onChange={(otp) => {
                        handleChange({ target: { name: 'totp', value: otp } });
                    }}
                    numInputs={6}
                    containerStyle={{ justifyContent: 'space-between' }}
                    inputStyle={{
                        width: '100%',
                        margin: '8px',
                        padding: '10px',
                        border: `1px solid`,
                        borderRadius: 4,
                        ':hover': {
                            borderColor: "#000"
                        }
                    }}
                    focusStyle={{
                        outline: 'none',
                        border: `2px solid #000`
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default CreateClientFields;