import React, {useState} from "react";

// materialUI imports
import {Box, Button, Dialog, DialogActions, DialogContent, Fade, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import TransactionSummary from "ui-component/TransactionSummary";

// 3rd Party Imports
import {Link} from "react-router-dom";



// ============================|| RESPONSE DISPLAY ||============================ //

export const ResponseDisplay = ({response, selectedTransaction, typeOfRequest}) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const theme = useTheme();

    const getErrorDetails = () => {
        if (response?.error) {
            switch (response.error.code) {
                case "InvalidTOTP":
                    return {
                        header: "Authentication Error",
                        message: "Oh no! Something seems to be wrong with the code you entered. Please try again."
                    };
                case "AlreadyRegistered":
                    return {
                        header: "Thank you, ",
                        message: response.error.message
                    };
                default:
                    return {
                        header: "Error",
                        message: response.error.message
                    };
            }
        }
        if (typeOfRequest === "Beneficiary Creation" && response?.duplicate) {
            return {
                header: "Duplicate Beneficiary",
                message: "Oh no! It looks like that beneficiary nickname is already in use."
            };
        }
        return {
            header: "Uh-Oh! Something went wrong...",
            message: "An unexpected error has occurred, please try again."
        };
    };

    if (!response || response.error || (typeOfRequest === "Beneficiary Creation" && response?.duplicate)) {
        const { header, message } = getErrorDetails();

        return (
            <>
            <Fade in={!!response}>
                <Typography variant="h4" sx={{color: theme.palette.error.main}}>
                    {header}
                </Typography>
            </Fade>
            <Typography sx={{mt: 1}} variant="h6">
                {message}
            </Typography>

                {response?.error?.transactionSummaryExists && (
                    <>
                        <Dialog
                            open={isModalOpen}
                            onClose={() => setModalOpen(false)}
                            fullWidth
                            maxWidth={"md"}
                        >
                            <DialogContent>
                                <TransactionSummary selectedTransaction={selectedTransaction} />
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={() => setModalOpen(false)} color="secondary" variant={"contained"}>
                                    Close
                                </Button>
                            </DialogActions>

                        </Dialog>

                        <Typography sx={{mt: 1}} variant="h6">
                            View your archived summary <Link onClick={() => setModalOpen(true)}>here</Link>.
                        </Typography>

                    </>
                )}
            </>
        );
    }

    const renderProperty = (property, value) => {
        if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
                // If the value is an array, render its items
                return (
                    <Box key={property}>
                        <Typography variant="body1">
                            <strong>{property}:</strong>
                        </Typography>
                        {value.map((item, index) => (
                            <Typography variant="body1" key={index}>
                                {item}
                            </Typography>
                        ))}
                    </Box>
                );
            } else {
                // If the value is an object, recursively render its properties
                return (
                    <Box key={property}>
                        <Typography variant="body2">
                            <strong>{property}</strong>
                        </Typography>
                        {Object.entries(value).map(([nestedProp, nestedValue]) =>
                            renderProperty(nestedProp, nestedValue)
                        )}
                    </Box>
                );
            }
        }

        // If the value is not an object or array, render the property and its value
        return (
            <Typography variant="body1" key={property}>
                <strong>{property}:</strong> {value}
            </Typography>
        );
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                {typeOfRequest} Complete:
            </Typography>
            {typeof response === 'object' && true
                ? Object.entries(response).map(([property, value]) => renderProperty(property, value))
                : null}
        </Box>
    );
};