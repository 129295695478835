import {useNavigate} from "react-router-dom";
import {useContext} from "react";
// project imports
import {RolesContext} from "../store/context/RolesContext";
import GetEntities from "../requests/entities/GetEntities";

/// ============================|| View Beneficiaries  ||============================ //
const ViewBeneficiaries = () => {
    const {roles} = useContext(RolesContext)
    const navigate = useNavigate();

    if (!roles.includes("beneficiaries::view")) {
        navigate("/");
    }
    return <GetEntities/>
}
export default ViewBeneficiaries;