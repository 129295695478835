
// ==============================|| UPDATE PDF-LIB DOCUMENT ||============================== //

const updatePDFLibDocument = async (pdfLibDoc, templateData) => {

    const PDFDoc    = pdfLibDoc,
          form      = await PDFDoc.getForm() // a PDF-lib method

    templateData.forEach( entry => {
        const textField = form.getTextField(entry.fieldName) // pdf-Lib TextField obj
        textField.setText(entry.fieldValue)
    })
    
    const allFields = await form.getFields(); // a PDF-lib method
    allFields.forEach(field => {
        field.enableReadOnly();
    });
          

    return await PDFDoc.save() // returns an array buffer
}

export default updatePDFLibDocument