import React, {useContext, useEffect} from "react";
import {Box, Card, CardHeader, Divider, Grid, Typography} from "@mui/material";
import GetFxQuote from "../requests/GetFxQuote";
import CreateBeneficiaryWidget from "../ui-component/dashboard/CreateBeneficiaryWidget";
import {RolesContext} from "../store/context/RolesContext";
import TransactionHistory from "../requests/TransactionHistory";
import StartPayment from "../ui-component/dashboard/StartPayment";
import {useTheme} from "@mui/material/styles";
import {useLocation, useNavigate} from "react-router-dom";
import dayjs from 'dayjs';
import {fadeIn} from "../ui-component/extended/Transitions";
import useLedgers from "../store/hooks/useLedgerDetails";

const Home = () => {

    const {roles} = useContext(RolesContext);
    const theme = useTheme();
    const {state} = useLocation();
    const tos = state?.tos;
    const clientId = sessionStorage.getItem('clientId');
    const navigate = useNavigate();
    const {activeLedgers, loading: ledgerLoading, error} = useLedgers();

    useEffect(() => {
        if (tos) {
            const effectiveDate = dayjs(tos.effective);
            const acceptedDate = tos.accepted ? dayjs(tos.accepted) : null;

            if (!acceptedDate || effectiveDate.isAfter(acceptedDate)) {
                navigate('/Tos', {
                    state: {
                        tos: tos,
                    },
                    replace: true
                });
            }
        }
    }, [tos]);

    const renderRolesMessage = () => {
        if (clientId) {
            return 'No permissions are associated with your account. To request access and permissions, contact your administrator.';
        }
        return 'Please, select a client account to continue.';
    };

    if (!roles || roles.length === 0) {
        return (
            <Typography variant="h2" align="center" sx={{mt: 2, animation: `${fadeIn} 3s`}}>
                {renderRolesMessage()}
            </Typography>
        );
    }

    return <>
        <Grid container justifyContent="center" spacing={2}>
            {roles.includes("quote") && (
                <Grid item xs={12} sm={6}>
                    {/*todo:Refactor and make a component*/}
                    <Card
                        sx={{
                            border: '1px solid',
                            borderColor: theme.palette.primary.light,
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            minHeight: '150px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            ':hover': {
                                boxShadow: '2px 2px 24px 0 rgb(32 40 45 / 8%)'
                            },
                        }}
                    >
                        <CardHeader sx={{p: 2.5}} title={<Typography variant="h4">Get Fx Quote</Typography>}/>
                        <Divider
                            sx={{
                                opacity: 1,
                                borderColor: theme.palette.primary.light
                            }}
                        />
                        <Box sx={{p: 2}}>
                            <GetFxQuote verticalResponse={true} activeLedgers={activeLedgers} ledgerLoading={ledgerLoading}/>
                        </Box>
                    </Card>
                </Grid>
            )}
            <Grid item xs={12} sm={6}>
                <Grid container direction="column" spacing={2}>
                    {roles.includes("beneficiary_create") && (
                        <Grid item>
                            <CreateBeneficiaryWidget/>
                        </Grid>
                    )}
                    {roles.includes("payment_create") && (
                        <Grid item>
                            <StartPayment/>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {roles.includes("payment::view") && (
                    <TransactionHistory compactView={true} activeLedgers={activeLedgers} ledgerLoading={ledgerLoading} error={error} />
                )}
            </Grid>
        </Grid>
    </>
}
export default Home;
