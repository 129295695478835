import { useEffect, useState } from 'react';
import { allCountries } from '../country-data-mozrt';

export const useCountryRegions = (handleChange, defaultCountry = null) => {
    const [regions, setRegions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const blockedCountries = JSON.parse(sessionStorage.getItem("blockedCountries")) || [];

    // Filter out the blocked countries
    const countries = allCountries
        .filter(countryArray => !blockedCountries.includes(countryArray[1]))
        .map(countryArray => ({
            value: countryArray[1],
            label: countryArray[0]
        }));

    const handleCountryChange = (event) => {
        const selectedValue = event.target.value;
        const selectedCountryOption = countries.find(country => country.value === selectedValue);
        setSelectedCountry(selectedCountryOption);
        handleChange(event);
    };

    // Check if defaultCountry is provided.
    useEffect(() => {
        if (defaultCountry) {
            const defaultCountryOption = countries.find(country => country.value === defaultCountry);
            if (defaultCountryOption) {
                setSelectedCountry(defaultCountryOption);
            }
        }
    }, [defaultCountry]);

    // Logic to get the regions or as us canadians say provinces and populate them
    useEffect(() => {
        if (selectedCountry) {
            const countryData = allCountries.find(countryArray => countryArray[1] === selectedCountry.value);
            if (countryData) {
                const newRegions = countryData[2].map(regionArray => ({ value: regionArray[1], label: regionArray[0] }));
                setRegions(newRegions);
            } else {
                setRegions([]);
            }
        }
    }, [selectedCountry]);

    return { countries, regions, handleCountryChange, selectedCountry };
};
