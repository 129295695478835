// 3rd Party Imports
import pdfGenerator from "./pdfGenerator";

const addBankTransactionFlag = (data) => {
    return {
        ...data,
        credit: {
            ...data.credit,
            bank: {
                isBankTransaction: true
            }
        }
    };
};

// ==============================|| DOWNLOAD PDF ||============================== //

export const downloadPDF = async (selectedTransaction, bankTransaction) => {
    // Add bank transaction flag if bankTransaction is true
    let transactionData = selectedTransaction;
    if (bankTransaction) {
        transactionData = addBankTransactionFlag(selectedTransaction);
    }

    const transactionId = transactionData.credit.id;
    const { arrayBuffer, fileNamePrefix } = await pdfGenerator(transactionData);

    // using the array buffer, create a `Blob` and a download URL
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    // simulate a click event to trigger the download in the browser
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileNamePrefix}${transactionId}.pdf`;
    link.click();
};
