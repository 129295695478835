import React, { useContext } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { RolesContext } from "../../../store/context/RolesContext";


const CutoffModal = ({ open, handleClose, cutOffTime }) => {
    const navigate = useNavigate();
    const { roles } = useContext(RolesContext);

    const handleGenerateFXRate = () => {
        navigate('/quote');
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="cutoff-modal-title" aria-describedby="cutoff-modal-description">
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: '8px',
                p: 2,
                minWidth: '300px',
                maxWidth: '500px',
                textAlign: 'center',
            }}>
                <Typography variant="h3" sx={{ m: 2 }}>
                    Unable to book transactions outside of banking hours.
                </Typography>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Please return <b>{cutOffTime}</b> to continue.
                </Typography>
                <Button variant="contained" color="secondary" onClick={handleClose} sx={{ mr: 1, px: 5 }}>
                    Close
                </Button>
                {roles.includes("quote") && (
                    <Button variant="contained" color="primary" onClick={handleGenerateFXRate}>
                        Need A Rate?
                    </Button>
                )}
            </Box>
        </Modal>
    );
};

export default CutoffModal;