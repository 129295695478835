import { styled } from "@mui/system";
import { Tooltip } from "@mui/material";
import React from "react";

const StyledDot = styled('div')(({ theme, status, size }) => {
    const baseStyle = {
        height: size === 'large' ? '12.5px' : '10px',
        width: size === 'large' ? '12.5px' : '10px',
        borderRadius: '50%',
        display: 'inline-block',
        animation: 'pulsate 3s infinite',
    };

    const statusColors = {
        ready: theme.palette.success.main,
        pending: theme.palette.warning.main,
        rejected: theme.palette.error.main,
        failed: theme.palette.error.main,
        disabled: theme.palette.error.main,
    };

    return {
        ...baseStyle,
        backgroundColor: statusColors[status] || 'transparent',
        '@keyframes pulsate': {
            '0%': { transform: 'scale(1)' },
            '50%': { transform: 'scale(1.2)' },
            '100%': { transform: 'scale(1)' },
        },
    };
});

const PulsatingDot = ({ status, large = false }) => {
    return (
        <Tooltip title={status.charAt(0).toUpperCase() + status.slice(1)} arrow>
            <StyledDot status={status} size={large ? 'large' : 'small'} />
        </Tooltip>
    );
};

export default PulsatingDot;