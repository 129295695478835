// project imports
import PulsatingDot from "../../PulsatingDot";
import React from "react";
import DataGridStatusChip from "../../datagrid/DataGridStatusChip";

// ============================ || APPROVALS TABLE COLUMNS  || ============================ //
export const ApprovalsColumns = [
    {
        field: 'overallState',
        headerName: 'Status',
        flex: .5,
        disableColumnMenu: true,
        renderCell: (params) => (
            <DataGridStatusChip state={params.value}/>
        ),

    },
    {
        field: 'expiry',
        headerName: 'Expiry',
        width: 175,
        sortable: true,
        disableColumnMenu: true

    },
    {
        field: 'receiverName',
        headerName: 'Recipient',
        flex: 1,
        disableColumnMenu: true

    },
    {
        field: 'sellCombo',
        headerName: 'Sell',
        flex: .75,
        disableColumnMenu: true
    },
    {
        field: 'buyCombo',
        headerName: 'Buy',
        flex: .75,
        disableColumnMenu: true
    },
    {
        field: 'required',
        headerName: 'Required',
        flex: .5,
        disableColumnMenu: true
    },
    {
        field: 'approved',
        headerName: 'Completed',
        flex: .50,
        disableColumnMenu: true,
        renderCell: (params) => {
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <span>{params.value}</span>
                    {params.row.overallState === 'pending' &&
                        <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                            <PulsatingDot status="pending" large />
                        </div>
                    }
                </div>
            );
        }
    },
];
// ============================ || APPROVALS TABLE COLUMNS Mobile  || ============================ //
export const ApprovalsColumnsMobile = [
    {
        field: 'overallState',
        headerName: 'Status',
        flex: .5,
        disableColumnMenu: true,
        renderCell: (params) => (
            <DataGridStatusChip state={params.value}/>
        ),
    },
    {
        field: 'expiry',
        headerName: 'Expiry',
        width: 175,
        sortable: true,
        disableColumnMenu: true

    },
    {
        field: 'receiverName',
        headerName: 'Recipient',
        flex: 1,
        disableColumnMenu: true,
        headerAlign: 'right',
        align: 'right',
    },
];