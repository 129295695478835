import React, {useContext, useEffect, useState} from "react";
import {Box, Grid, IconButton, InputAdornment, Tooltip} from '@mui/material';
import CustomFormField from "../../ui-component/form-fields/CustomFormField";
import CustomDropdown from "../../ui-component/form-fields/CustomDropdown";
import {ResponseDisplay} from "../response/ResponseDisplay";
import {CreateBeneficiaryTOTPModal} from "../../ui-component/extended/modal/CreateBeneficiaryTOTPModal";
import {VisaB2BForm} from "../../ui-component/beneficiary-form/Visab2bForm";
import {ACHSchema} from "../../ui-component/beneficiary-form/AchValidationSchema";
import {ACHForm} from "../../ui-component/beneficiary-form/AchForm";
import {InterLedgerForm} from "../../ui-component/beneficiary-form/InterledgerForm";
import {WireForm} from "../../ui-component/beneficiary-form/WireForm";
import {SplitButton} from "../../ui-component/extended/SplitButton";
import useCreateBeneficiary from "../../store/hooks/useCreateBeneficiary";
import ConfigContext from "../../store/context/ConfigContext";
import MainCard from "../../ui-component/cards/MainCard";
import {WireSchema} from "../../ui-component/beneficiary-form/WireValidationSchema";
import {InterLedgerSchema} from "../../ui-component/beneficiary-form/InterledgerValidationSchema";
import {mapInitialEntityValues} from "./mapInitialEntityValues";
import {getVisaB2BSchema} from "../../ui-component/beneficiary-form/Visab2bValidationSchema";
import {Form, Formik} from "formik";
import {IconSwitchHorizontal} from "@tabler/icons-react";
import * as Yup from "yup";

/// ============================|| Default - Validation ||============================ //
export const DefaultSchema = Yup.object().shape({
    InfoType: Yup.string().required('Network Type is required'),
});

/// ============================||  Base Form - Create Beneficiary ||============================ //
const CreateEntityForm = ({sendingClient, handleCloseBankModal, initialValues}) => {
    const [selectedOption, setSelectedOption] = useState('');
    const config = useContext(ConfigContext);
    const [response, setResponse] = useState(null);
    const handleCreateBeneficiary = useCreateBeneficiary(setResponse, sendingClient, handleCloseBankModal);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const infoTypes = config.BENEFICIARY_TYPE || [];
    const determineSchema = (infoType) => {
        switch (infoType) {
            case 'ach':
                return ACHSchema;
            case 'visab2b':
                return getVisaB2BSchema();
            case 'interLedger':
                return InterLedgerSchema;
            case 'wire':
                return WireSchema;
            default:
                return DefaultSchema;
        }
    };
    const defaultInfoType = infoTypes.length > 0 ? infoTypes[0].value : '';
    const [currentSchema, setCurrentSchema] = useState(
        determineSchema(mapInitialEntityValues(initialValues, defaultInfoType).InfoType)
    );

    const handleCopyNameToEntityId = (name, setFieldValue) => {
        setFieldValue("entityId", name);
    };

    const createAnother = selectedOption === 'Submit and Create Another';

    const handleSchemaUpdate = (newSchema) => {
        setCurrentSchema(newSchema);
    };

    useEffect(() => {
        if (initialValues) {
            const mappedValues = mapInitialEntityValues(initialValues, defaultInfoType);
            setCurrentSchema(determineSchema(mappedValues.InfoType));
        }
    }, [initialValues]);

    return (
        <MainCard title={"Create A New Beneficiary"}>
            <Box sx={{overflow: 'auto'}}>
                <Formik
                    initialValues={mapInitialEntityValues(initialValues, defaultInfoType)}
                    enableReinitialize={true}
                    validationSchema={currentSchema}
                    onSubmit={(values, {resetForm}) => {
                        handleCreateBeneficiary(values, resetForm, createAnother);
                    }}
                >
                    {({
                          values,
                          handleChange,
                          handleBlur,
                          setFieldValue,
                          touched,
                          errors,
                          dirty,
                          isValid,
                          resetForm,
                          setErrors,
                          validateForm
                      }) => (
                        <Form>
                            {infoTypes.length > 1 ? (
                                <Grid item xs={8} sx={{mb: 1}}>
                                    <CustomDropdown
                                        name="InfoType"
                                        label="Payment System"
                                        value={values.InfoType}
                                        handleChange={(e) => {
                                            handleChange(e);
                                            if (e.target.name === 'InfoType') {
                                                setCurrentSchema(determineSchema(e.target.value));
                                                setFieldValue("InfoType", e.target.value);
                                            }
                                        }}
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                        options={infoTypes.map((infoType) => ({
                                            value: infoType.value,
                                            label: infoType.label,
                                        }))}
                                    />
                                </Grid>
                            ) : (
                                <input type="hidden" name="InfoType" value={defaultInfoType}/>
                            )}
                            <Grid container spacing={1}>
                                <Grid item xs={12} lg={8}>
                                    <CustomDropdown
                                        name="type"
                                        label="Beneficiary Category "
                                        value={values.type}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                        options={[
                                            {value: 'person', label: 'Person'},
                                            {value: 'company', label: 'Company'},
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <CustomFormField
                                        name="name"
                                        label="Beneficiary Name"
                                        value={values.name}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                        validateChars={true}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <CustomFormField
                                        name="entityId"
                                        label="Beneficiary Nickname (Reference)"
                                        value={values.entityId}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                        endAdornment={
                                            values.name && (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title="Copy Beneficiary Name as Reference. If no nickname is required, use the same value as name. Any value added will be shown to you in the application">
                                                        <IconButton
                                                            onClick={() => handleCopyNameToEntityId(values.name, setFieldValue)}>
                                                            <IconSwitchHorizontal/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            )
                                        }
                                    />
                                </Grid>
                                {values.InfoType === 'ach' &&
                                    <Grid item xs={12}>
                                        <ACHForm values={values} handleChange={handleChange} handleBlur={handleBlur}
                                                 touched={touched} errors={errors}/>
                                    </Grid>
                                }
                                {values.InfoType === 'visab2b' &&
                                    <Grid item xs={12}>
                                        <VisaB2BForm values={values} handleChange={handleChange}
                                                     handleBlur={handleBlur}
                                                     touched={touched} errors={errors}
                                                     setFieldValue={setFieldValue}
                                                     onSchemaUpdate={handleSchemaUpdate}
                                                     validateForm={validateForm}
                                        />
                                    </Grid>
                                }
                                {values.InfoType === 'interLedger' &&
                                    <Grid item xs={12}>
                                        <InterLedgerForm values={values} handleChange={handleChange}
                                                         handleBlur={handleBlur}
                                                         touched={touched} errors={errors}/>
                                    </Grid>
                                }
                                {values.InfoType === 'wire' &&
                                    <Grid item xs={12}>
                                        <WireForm values={values} handleChange={handleChange}
                                                  handleBlur={handleBlur} setErrors={setErrors}
                                                  touched={touched} errors={errors}/>
                                    </Grid>
                                }
                            </Grid>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <SplitButton
                                        isDisabled={!isValid || !dirty}
                                        options={[
                                            {
                                                label: 'Submit', action: () => {
                                                    setSelectedOption('Submit');
                                                    setIsModalOpen(true);
                                                }
                                            },
                                            {
                                                label: 'Submit and Create Another', action: () => {
                                                    setSelectedOption('Submit and Create Another');
                                                    setIsModalOpen(true);
                                                }
                                            }
                                        ]}
                                    />

                                    <CreateBeneficiaryTOTPModal
                                        isModalOpen={isModalOpen}
                                        setIsModalOpen={setIsModalOpen}
                                        onConfirm={(updatedValues) => {
                                            handleCreateBeneficiary(updatedValues, resetForm, createAnother);
                                        }}
                                        values={values}
                                    />
                                </Grid>
                                {response && (
                                    <Grid item xs={12} sm={8}>
                                        <Box>
                                            <ResponseDisplay response={response}
                                                             typeOfRequest={"Beneficiary Creation"}/>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Form>
                    )}
                </Formik>

            </Box>
        </MainCard>
    );
};

export default CreateEntityForm;