import {Link} from 'react-router-dom';
import {useEffect, useState} from "react";
// material-ui
import {Divider, Grid} from '@mui/material';

// project imports
import Logo from 'ui-component/Logo';
import PasswordLessLogin from "../auth/PasswordLessLogin";
import AuthWrapper from "../ui-component/auth/AuthWrapper";
import AuthCardWrapper from "../ui-component/auth/AuthCardWrapper";
import {getRandomImage} from "../store/getRandomImage";

// assets


// ================================|| AUTH1 - LOGIN ||================================ //
const LoginScreen = () => {
    const [backgroundImage, setBackgroundImage] = useState('');

    useEffect(() => {
        setBackgroundImage(getRandomImage());
    }, []);

    return (
        <AuthWrapper>
            <Grid container justifyContent="space-between" alignItems="center" sx={{minHeight: '100vh'}}>
                <Grid item container justifyContent="center" md={6} lg={7}>
                    <AuthCardWrapper>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Link to="#" aria-label="theme-logo">
                                    <Logo/>
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                <PasswordLessLogin/>
                            </Grid>
                            <Grid item xs={12} sx={{mt: 4}}>
                                <Divider/>
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <Grid item container direction="column" alignItems="flex-end" xs={12}>*/}
                            {/*        <Typography*/}
                            {/*            component={Link}*/}
                            {/*            to="/pages/register/register1"*/}
                            {/*            variant="subtitle1"*/}
                            {/*            sx={{textDecoration: 'none'}}*/}
                            {/*        >*/}
                            {/*            Don&apos;t have an account?*/}
                            {/*        </Typography>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </AuthCardWrapper>
                </Grid>
                <Grid item md={6} lg={5}
                      sx={{
                          height: "100vh",
                          maxHeight: "100vh",
                          display: {xs: 'none', md: 'block'},
                          backgroundImage: `url(${backgroundImage})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                      }}>
                </Grid>
            </Grid>
        </AuthWrapper>
    );
};

export default LoginScreen;