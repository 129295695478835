import React, {useContext, useEffect, useState} from 'react';

//3rd party imports
import axios from "axios";


// material-ui
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Link,
    Typography
} from '@mui/material';

import Paper from "@mui/material/Paper";
import {useTheme} from "@mui/material/styles";

// project imports
import ConfigContext from "../../../store/context/ConfigContext";
import {AuthContext} from "../../../auth/AuthContext";
import {useAuthHeaders} from "../../../auth/AuthHeaders";
import {useNavigate} from "react-router-dom";


const ESignDisclosureModal = ({open, onClose}) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogContent dividers>
                <Box sx={{padding: '5px'}}>
                    <Typography variant="body1" component="p" gutterBottom>
                        <b>E-Sign Disclosure</b>
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        This E-Sign Disclosure ("Disclosure") applies to your use of the Mozrt software program
                        ("Software")
                        provided by your bank. By electronically signing below, you agree to conduct transactions
                        electronically and to be bound by the terms and conditions of this Disclosure.
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        <b>1. Electronic Signature</b>
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        If you consent to receiving notices and disclosures exclusively in electronic format as
                        described
                        herein, then select the checkbox next to "I agree to use electronic records and signatures"
                        before
                        clicking "continue" within the System. By clicking "I Agree" or by electronically signing below,
                        you
                        acknowledge and agree that your electronic signature is the legal equivalent of your handwritten
                        signature.
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        <b>2. Consent to Electronic Transactions</b>
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        You consent to receive communications and transact with your bank electronically. This includes,
                        but
                        is not limited to, agreements, notices, disclosures, and other communications related to the
                        Software.
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        <b>3. Use of Electronic Signatures</b>
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        You agree that your electronic signature on this Disclosure and any other agreements or
                        documents
                        related to the Software is valid, binding, and enforceable.
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        <b>4. Paper Copies</b>
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        You have the right to request a paper copy of any electronic record or agreement related to the
                        Software. To request a paper copy, please contact your bank, or support@mozrt.com. A fee may be
                        charged for providing paper copies.
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        <b>5. Hardware and Software Requirements</b>
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        To electronically sign documents and access electronic records, you will need a computer or
                        mobile
                        device with internet access and compatible software. You are responsible for maintaining the
                        necessary hardware and software to access electronic documents.
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        <b>6. Withdrawal of Consent</b>
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        You may withdraw your consent to conduct transactions electronically at any time by contacting
                        your
                        bank, or Mozrt at support@mozrt.com. However, withdrawal of consent may result in the
                        termination of
                        your access to the Software.
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        <b>7. Acknowledgment</b>
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                        By selecting the checkbox next to "I agree to use electronic records and signatures," then
                        clicking
                        "continue" within the system, you acknowledge that you have read, understood, and agree to be
                        bound
                        by the terms and conditions of this E-Sign Disclosure.
                    </Typography>
                    <Button variant="contained" onClick={onClose} sx={{mt: 2}}>Close</Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
        ;
};


//------------------------------------- TermsOfServiceModal  --------------------------------------//
const TermsOfServiceModal = ({open, onClose, tosUrl, tosReference}) => {
    const [step, setStep] = useState(1);
    const theme = useTheme();
    const config = useContext(ConfigContext);
    const authHeaders = useAuthHeaders();
    const {removeAuthToken, setIsAuthenticated} = useContext(AuthContext);
    const [isAgreed, setIsAgreed] = useState(false);
    const [showESignDisclosure, setShowESignDisclosure] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const navigate = useNavigate();


    const handleOpenESignDisclosure = () => {
        setShowESignDisclosure(true);
    };

    const handleAgree = () => {
        setStep(2);
    };
    const handleDisagree = () => {
        sessionStorage.clear();
        removeAuthToken();
        setIsAuthenticated(false);
    };

    const handleTosSubmit = async () => {
        try {
            await axios.post(
                `${config.API_URL}/login/v1/accept`,
                {reference: tosReference},
                {headers: authHeaders}
            );
            navigate('/')
            onClose();
        } catch (error) {
            console.error("Error submitting Terms of Service agreement:", error);
        }
    };

    useEffect(() => {
        if (tosUrl) {
            const cleanUrl = tosUrl.trim();
            const fetchHTML = async () => {
                try {
                    const response = await fetch(cleanUrl);
                    const html = await response.text();
                    setHtmlContent(html);
                } catch (error) {
                    console.error('Error fetching HTML:', error);
                }
            };

            fetchHTML();
        }
    }, [tosUrl]);

    return (
        <Dialog
            open={open}
            maxWidth={step === 1 ? "sm" : "lg"}
            fullWidth
            disableEscapeKeyDown
        >
            {step === 1 ? (
                <>
                    <DialogTitle sx={{fontSize: "1.3rem", fontWeight: "600"}}>Electronic Record and Signature
                        Disclosure</DialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom sx={{mb: 2}}>
                            Before you continue, please review our
                            <Link onClick={handleOpenESignDisclosure} sx={{fontWeight: 'bold', ml: 0.5}}>
                                electronic record and signature disclosure.
                            </Link>
                        </Typography>
                        <Paper sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            backgroundColor: 'background.default',
                            border: 1,
                            borderColor: 'transparent',
                            borderRadius: 1
                        }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isAgreed}
                                        onChange={(event) => setIsAgreed(event.target.checked)}
                                        name="agreeCheckbox"
                                        color="primary"
                                    />
                                }
                                label="I agree to use electronic records and signatures."
                            />
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleAgree} color="primary" disabled={!isAgreed}>
                            Continue
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogContent>
                        <Box sx={{width: '100%', height: '90vh'}}>
                            <div dangerouslySetInnerHTML={{__html: htmlContent}}/>
                        </Box>

                    </DialogContent>
                    <DialogActions sx={{pr: 2.5}}>
                        <Button
                            sx={{color: theme.palette.error.dark, borderColor: theme.palette.error.dark}}
                            onClick={handleDisagree}
                            color="secondary"
                        >
                            CANCEL
                        </Button>
                        <Button variant="contained" size="small" onClick={handleTosSubmit}>
                            I ACCEPT
                        </Button>
                    </DialogActions>
                </>
            )}
            <ESignDisclosureModal open={showESignDisclosure} onClose={() => setShowESignDisclosure(false)}/>
        </Dialog>

    );
};

export default TermsOfServiceModal;