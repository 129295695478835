import {styled} from "@mui/system";
import {Box} from "@mui/material";

export const FadeInBox = styled(Box)`
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;