import { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import dayjs from 'dayjs';
// project imports
import {useAuthHeaders} from "../../auth/AuthHeaders";
import ConfigContext from "../context/ConfigContext";

//-------------------------------------APPROVALS Data Hook --------------------------------------//
const useFetchApprovalsData = (url) => {
    const [data, setData] = useState({});
    const [dateFilter, setDateFilter] = useState({ begin: dayjs().subtract(3, 'day').startOf('day'), end: dayjs().endOf('day') });
    const [isShowDateFilter, setIsShowDateFilter] = useState(false);
    const authHeaders = useAuthHeaders();
    const { API_URL } = useContext(ConfigContext);

    const fetchData = useCallback(async (begin, end) => {
        setDateFilter({ begin, end });
        try {
            const response = await axios.post(
                `${API_URL}${url}`,
                {
                    realm: sessionStorage.getItem('clientId'),
                    begin,
                    end
                },
                { headers: authHeaders }
            );
            setData(response.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                sessionStorage.clear();
                window.location.href = '/token-expiry';
            } else {
                console.error('Error fetching data:', error);
            }
        }
    }, [url]);

    useEffect(() => {
        fetchData(dateFilter.begin, dateFilter.end);
    }, [fetchData, dateFilter.begin, dateFilter.end]);

    return { data, fetchData, dateFilter, isShowDateFilter, setIsShowDateFilter };
};

export default useFetchApprovalsData;
