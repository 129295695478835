import { useField } from "formik";
import useLedgers from "../../store/hooks/useLedgerDetails";
import PropTypes from "prop-types";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {useEffect} from "react";
import getFormattedCurrencyAmount from "../../store/getFormattedCurrencyAmount";

// ============================|| Ledger Dropdown with Currency Selector ||============================ //
const LedgerDropdown = ({ name, maxHeight, textSize, label, setSelectedCurrency, externalOnChange, defaultCurrency, ...props }) => {
    const [field, meta, helpers] = useField(name);
    const isError = Boolean(meta.touched && meta.error);
    const { activeLedgers, loading, error } = useLedgers();

    useEffect(() => {
        // Set default value based on defaultCurrency prop
        if (defaultCurrency && !field.value && activeLedgers.length > 0) {
            const ledgerWithCurrency = activeLedgers.find(ledger => ledger.currency === defaultCurrency);
            if (ledgerWithCurrency) {
                helpers.setValue(ledgerWithCurrency.ledgerId);
                if (setSelectedCurrency) {
                    setSelectedCurrency(defaultCurrency);
                }
            }
        }
    }, [defaultCurrency, field.value, helpers, activeLedgers, setSelectedCurrency]);

    const handleChange = (event) => {
        helpers.setValue(event.target.value);
        if (externalOnChange) {
            externalOnChange(event);
        }
        const selectedLedger = activeLedgers.find(ledger => ledger.ledgerId === event.target.value);
        if (selectedLedger && setSelectedCurrency) {
            setSelectedCurrency(selectedLedger.currency);
        }
    };

    return (
        <FormControl fullWidth error={isError} variant="outlined">
            <InputLabel id="ledger-label">{label}</InputLabel>
            <Select
                labelId="ledger-label"
                label={label}
                id="ledger-select"
                value={field.value}
                onChange={handleChange}
                variant="outlined"
                MenuProps={{
                    style: {
                        maxHeight: maxHeight,
                    },
                }}
                sx={{
                    padding: '5.5px 1.5px!important',
                }}
                {...props}
            >
                {loading ? (
                    <MenuItem disabled>Loading...</MenuItem>
                ) : error ? (
                    <MenuItem disabled>Error loading ledgers</MenuItem>
                ) : (
                    activeLedgers.map(ledger => (
                        <MenuItem key={ledger.ledgerId} value={ledger.ledgerId}
                                  sx={{ fontSize: textSize || 'inherit' }}
                        >
                            {ledger.description || ledger.ledgerId} - {getFormattedCurrencyAmount(ledger.available,ledger.currency)}
                        </MenuItem>
                    ))
                )}
            </Select>
            {isError && <div style={{ color: '#f44336', fontSize: "0.75rem", marginLeft: "14px", marginTop: "4px" }}>{meta.error}</div>}
        </FormControl>
    );
}

LedgerDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    maxHeight: PropTypes.number,
    textSize: PropTypes.string,
    label: PropTypes.string.isRequired,
    setSelectedCurrency: PropTypes.func,
    externalOnChange: PropTypes.func,
    defaultCurrency: PropTypes.string,
    props: PropTypes.object
};

export default LedgerDropdown;