import React, { useState } from 'react';
import PropTypes from 'prop-types';
// materialUI imports
import { Box, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// project imports
import { ResponseDisplay } from "../../../requests/response/ResponseDisplay";
import CreateEntityForm from "../../../requests/entities/CreateEntity";

/// ============================|| Create Beneficiary Modal  ||============================ //
const CreateEntityModal = ({ open, handleClose, sendingClient, initialValues }) => {
    const [response, setResponse] = useState(null);

    const handleCloseModal = () => {
        setResponse(null);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleCloseModal}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '95vw', md: '85vw', lg: '65vw' },
                    maxWidth: '95vw',
                    maxHeight: '90vh',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    borderRadius: 8,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Header Box */}
                <Box sx={{ marginBottom: '15px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <IconButton onClick={handleCloseModal} aria-label="close" size="small">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>

                {/* Body of the Modal */}
                <Box sx={{ overflow: 'auto' }}>
                    <CreateEntityForm
                        sendingClient={sendingClient}
                        handleCloseBankModal={handleCloseModal}
                        initialValues={initialValues}
                    />
                </Box>

                {/* Response Display */}
                {response && (
                    <Box sx={{ mt: 2 }}>
                        <ResponseDisplay response={response} typeOfRequest={"Beneficiary Creation"} />
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

CreateEntityModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    sendingClient: PropTypes.object,
    initialValues: PropTypes.object,
};

export default CreateEntityModal;
