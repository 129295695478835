import {displayBeneficiary} from "./displayBeneficiary";

export const getBeneficiaryOptions = (beneficiaries) => {
    if (!Array.isArray(beneficiaries)) {
        return [];
    }

    // Filter to get only 'ready' state beneficiaries
    return beneficiaries
        .filter(entity => entity.state === 'ready')
        .map(entity => ({
            value: entity.reference,
            label: displayBeneficiary(entity),
            network: entity.network,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
};