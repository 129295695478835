import React, {useContext, useEffect, useState} from 'react';

// materialUI imports
import {Box} from '@mui/material';

// project imports
import {useAuthHeaders} from '../../auth/AuthHeaders';
import CreditResponse from "../response/CreditResponse";
import CreditForm from "./CreditForm";
import {formattedDate} from "../../store/getDate";
import getFormattedAmount from 'store/getFormattedAmount';
import ConfigContext from "../../store/context/ConfigContext";
import useEntityDetails from "../../store/hooks/useEntityDetails";
import useSubmitCredit from "../../store/hooks/useSubmitCredit";

// 3rd Party imports
import * as Yup from 'yup';
import {Formik} from 'formik';


// ============================|| Create - Credit ||============================ //
const CreateCredit = ({EntityId, entity, Response}) => {
    const responseRef = React.useRef(null);
    const [paymentType] = useState(Response.id ? "Foreign" : "Domestic");
    const config = useContext(ConfigContext);
    const authHeaders = useAuthHeaders();
    const [confirmationData, setConfirmationData] = useState(null);
    const [responseModalOpen, setResponseModalOpen] = useState(false);
    const {entityDetails} = useEntityDetails(EntityId);

    const {
        handleSubmit: handleCreditSubmit,
        response: creditResponse,

    } = useSubmitCredit(authHeaders, config, paymentType);

    const handleConfirmationDataChange = (data) => {
        setConfirmationData(data)
    }
    useEffect(() => {
        if (creditResponse) {
            setResponseModalOpen(true);
        }
    }, [creditResponse]);

    const handleCustomChange = (e, handleChange) => {
        const {name, value} = e.target;
        if (name === 'amount' && !isNaN(value)) {
            handleChange({target: {name, value: getFormattedAmount(value)}});
        } else {
            handleChange(e);
        }
    }

    const clearCreditResponse = () => {
        setResponseModalOpen(false);
        setConfirmationData(null);
    };

    return (
        <Box mt={2}>
            <Formik
                initialValues={{
                    reference: `${EntityId} - ${formattedDate}`,
                    account: undefined,
                    amount: '',
                    currency: Response.currency ? Response.currency : 'USD',
                    entityId: EntityId || (entity ? entity.reference : ''),
                    when: 'standard',
                    description: '',
                    fxQuoteId: Response.id ? Response.id : "",
                    totpValue: ""
                }}
                validationSchema={Yup.object().shape({
                    reference: Yup.string().required('Internal Tracking Reference is required'),
                    account: Yup.string(),
                    amount: Yup.number().required('Amount is required'),
                    currency: Yup.string().required('Currency is required'),
                    entityId: Yup.string().required('Entity Reference is required'),
                    when: Yup.string().required('When is required'),
                    description: Yup.string()
                        .required('Description is required')
                        .max(35, 'Description must be a maximum of 35 characters'),
                })}

                onSubmit={async (values, {setSubmitting}) => {
                    const formData = {
                        ...values,
                        totpValue: values.totpValue
                    };
                    await handleCreditSubmit(formData, {setSubmitting});
                    setSubmitting(false);
                }}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      touched,
                      errors,
                      setSubmitting,
                      validateForm,
                      setTouched,
                  }) => (
                    <CreditForm
                        values={values}
                        handleChange={(e) => handleCustomChange(e, handleChange)}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        validateForm={validateForm}
                        setTouched={setTouched}
                        requestData={Response}
                        onConfirmationDataChange={handleConfirmationDataChange}
                        handleSubmit={handleSubmit}
                        setSubmitting={setSubmitting}
                        fxCredit={true}
                        entityDetails={entityDetails}
                    />
                )}
            </Formik>
            {creditResponse && (
                <Box mt={2} ref={responseRef}>
                    <CreditResponse
                        response={creditResponse}
                        open={responseModalOpen}
                        clearResponse={clearCreditResponse}
                    />
                </Box>
            )}
        </Box>

    );
};

export default CreateCredit;
