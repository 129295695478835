import React, {useContext} from "react";

// material-ui
import {Button, Grid, Typography} from "@mui/material";

// project imports
import {AuthContext} from "../auth/AuthContext";
import AnimateButton from "../ui-component/extended/AnimateButton";
import {useNavigate} from "react-router-dom";

const Welcome = () => {
    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext);
    const navigate = useNavigate();
    const {removeAuthToken} = useContext(AuthContext);


    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} lg={6}>
                {isAuthenticated ? (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h1" sx={{mt: 5, mb: 2}}>
                                404 - Oops! We can't find the page you're looking for. It might have been moved,
                                deleted or you don't have the proper permissions.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    sx={{width: '100%'}}
                                    onClick={() => {
                                        removeAuthToken();
                                        setIsAuthenticated(false);
                                        navigate("/authentication");
                                    }}
                                >
                                    Logout
                                </Button>
                            </AnimateButton>
                        </Grid>

                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h2" sx={{mt: 5, mb: 1}}>
                                You're not logged in
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    sx={{width: '100%'}}
                                    onClick={() => {
                                        navigate("/authentication");
                                    }}
                                >
                                    Login
                                </Button>
                            </AnimateButton>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default Welcome;
