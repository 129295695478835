import dayjs from "dayjs";
import {DATE_TIME_DISPLAY_FORMAT, DATE_DISPLAY_FORMAT, ISO_8601_DISPLAY_FORMAT, US_DATE_DISPLAY_FORMAT} from './constant'

const today = new Date();
export const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}-${today.getFullYear()} -${String(today.getHours()).padStart(2, '0')}:${String(today.getMinutes()).padStart(2, '0')}`;

export function formatDateToMonthDayYearWritten(isoDate) {
    return dayjs(isoDate).format(DATE_DISPLAY_FORMAT)
}

export function formatDateToMonthDayYearWithTime(isoDate) {
    return dayjs(isoDate).format(DATE_TIME_DISPLAY_FORMAT);
}

export function getTwoDaysLaterDateInYearMonthDay() {
    return dayjs().add(2, 'd').format(ISO_8601_DISPLAY_FORMAT);
}

export function formatDateToYearMonthDay(isoDate) {
    return dayjs(isoDate).format(ISO_8601_DISPLAY_FORMAT);
}

export function formatDateToMonthDayYear(isoDate) {
    return dayjs(isoDate).format(US_DATE_DISPLAY_FORMAT)
}

export function dateComparator(date1, date2) {
    return dayjs(date1, DATE_TIME_DISPLAY_FORMAT).subtract(dayjs(date2, DATE_TIME_DISPLAY_FORMAT));
};
