import React, {useEffect, useState} from "react";
// material-ui
import {
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
// project imports
import {useBeneficiaries} from "../../store/context/EntityContext";
import useEntityDetails from "../../store/hooks/useEntityDetails";
import {renderBankDetails} from "../../store/renderBankDetails";
import MainCard from "../cards/MainCard";
import { displayBeneficiary } from "store/displayBeneficiary";
import {getBeneficiaryOptions} from "../../store/getBeneficiariesOptions";

// ==============================|| CreateBeneficiary Step of the Make Payment Flow ||============================== //
const BeneficiaryStepper = ({handleNext, handleBeneficiarySelection, disableNext}) => {
    const [beneficiary, setBeneficiary] = useState({});
    const { entityData: beneficiaries, triggerReload } = useBeneficiaries();
    const {entityDetails} = useEntityDetails(beneficiary.value);

    const beneficiaryOptions = getBeneficiaryOptions(beneficiaries);

    useEffect(() => {
        if (Array.isArray(beneficiaries) && beneficiaries.length === 0) {
            triggerReload();
        }
    }, [beneficiaries]);

    useEffect(() => {
        if (entityDetails && entityDetails.details) {
            const newBeneficiary = {
                ...beneficiary,
                currency: entityDetails.details.currency || beneficiary.currency,
            };
            handleBeneficiarySelection(newBeneficiary);
        }
    }, [entityDetails, beneficiary, handleBeneficiarySelection]);

    const handleBeneficiaryChange = (event) => {
        const selectedBeneficiary = beneficiaryOptions.find(option => option.value === event.target.value);
        setBeneficiary(selectedBeneficiary || {});
    };

    return (
        <Grid container spacing={4} mt={4}>
            <Grid item xs={12}>
                <Card elevation={3}>
                    <CardContent>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="column" justifyContent="space-between"
                                     height="280px">
                                    <Box mb={3}>
                                        <h2>Choose Your Beneficiary</h2>
                                        <p>Please select from the available options.</p>
                                    </Box>

                                    <FormControl
                                        variant="outlined"
                                        sx={{
                                            width: "100%",
                                            mb: 3,
                                        }}
                                    >
                                        <InputLabel id="beneficiary-label"
                                                    sx={{fontSize: '1rem'}}>Select Beneficiary</InputLabel>
                                        <Select
                                            labelId="beneficiary-label"
                                            id="beneficiary"
                                            name="beneficiary"
                                            label="Select Beneficiary"
                                            value={beneficiary.value || ''}
                                            onChange={handleBeneficiaryChange}
                                            sx={{fontSize: '1rem'}}
                                        >
                                            {beneficiaryOptions.map((option, index) => (
                                                <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {disableNext ? (
                                      <Typography> Unable to book transactions outside of banking hours. </Typography>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            disabled={!beneficiary.value || disableNext}
                                            onClick={handleNext}
                                        >
                                            Next
                                        </Button>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {entityDetails && entityDetails.details && (
                                    <MainCard title={"View Bank Details"} content>
                                        {renderBankDetails(entityDetails.details, entityDetails.details.name)}
                                    </MainCard>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default BeneficiaryStepper;
