// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const OPEN_MODAL = '@customization/OPEN_MODAL';
export const CLOSE_MODAL = '@customization/CLOSE_MODAL';


// ==============================|| CUSTOMIZATION - ACTION CREATORS ||============================== /
export const openModal = () => ({
    type: OPEN_MODAL
});

// Action creator for closing the modal
export const closeModal = () => ({
    type: CLOSE_MODAL
});