// RolesContext.js

import {createContext, useEffect, useState} from 'react';

export const RolesContext = createContext(null);

export function RolesProvider({ children }) {
    const [roles, setRoles] = useState([]);

    const setRolesDynamically = (rolesData) => {
        setRoles(rolesData);
    };

    useEffect(() => {
        const storedRoles = sessionStorage.getItem('userRoles');
        if (storedRoles) {
            setRoles(JSON.parse(storedRoles));
        }
    }, []);

    return (
        <RolesContext.Provider value={{ roles, setRolesDynamically }}>
            {children}
        </RolesContext.Provider>
    );
}