import cnFlag from "../assets/images/cn.png";
import flagAed from "../assets/images/flag-ae.png";
import flagAmd from "../assets/images/flag-am.png";
import flagBmd from "../assets/images/flag-bm.png";
import flagBr from "../assets/images/flag-br.png";
import flagChf from "../assets/images/flag-ch.png";
import flagCl from "../assets/images/flag-cl.png";
import flagCrc from "../assets/images/flag-cr.png";
import flagDk from "../assets/images/flag-dk.png";
import flagDo from "../assets/images/flag-do.png";
import flagEg from "../assets/images/flag-eg.png";
import flagGt from "../assets/images/flag-gt.png";
import flagHu from "../assets/images/flag-hu.png";
import flagId from "../assets/images/flag-id.png";
import flagIl from "../assets/images/flag-il.png";
import flagIn from "../assets/images/flag-in.png";
import flagJm from "../assets/images/flag-jm.png";
import flagJo from "../assets/images/flag-jo.png";
import flagKe from "../assets/images/flag-ke.png";
import flagKr from "../assets/images/flag-kr.png";
import flagKy from "../assets/images/flag-ky.png";
import flagLa from "../assets/images/flag-la.png";
import flagLb from "../assets/images/flag-lb.png";
import flagLk from "../assets/images/flag-lk.png";
import flagLt from "../assets/images/flag-lt.png";
import flagLv from "../assets/images/flag-lv.png";
import flagLy from "../assets/images/flag-ly.png";
import flagNg from "../assets/images/flag-ng.png";
import flagNo from "../assets/images/flag-no.png";
import flagNz from "../assets/images/flag-nz.png";
import flagPe from "../assets/images/flag-pe.png";
import flagPh from "../assets/images/flag-ph.png";
import flagPk from "../assets/images/flag-pk.png";
import flagPl from "../assets/images/flag-pl.png";
import flagQa from "../assets/images/flag-qa.png";
import flagRm from "../assets/images/flag-my.png";
import flagRon from "../assets/images/flag-ro.png";
import flagRsd from "../assets/images/flag-rs.png";
import flagSc from "../assets/images/flag-sc.png";
import flagSe from "../assets/images/flag-se.png";
import flagTh from "../assets/images/flag-th.png";
import flagTr from "../assets/images/flag-tr.png";
import flagTw from "../assets/images/flag-tw.png";
import flagUah from "../assets/images/flag-ua.png";
import flagUgx from "../assets/images/flag-ug.png";
import flagVnd from "../assets/images/flag-vn.png";
import flagZm from "../assets/images/flag-zm.png";
import generic from "../assets/images/generic.png";
import mxnFlag from "../assets/images/mx_flag.png";
import audFlag from "../assets/images/aud_flag.png"
import cadFlag from "../assets/images/cad_flag.png"
import eurFlag from "../assets/images/eur_flag.png"
import gbpFlag from "../assets/images/gbp_flag.png"
import usdFlag from "../assets/images/usd_flag.png"
import hkdFlag from "../assets/images/hk_flag.png"
import jpyFlag from "../assets/images/jpy_flag.png"
import sgFlag from "../assets/images/sg_flag.png"

export const currencyOptions = [
    { value: 'AUD', label: 'AUD', icon: audFlag, country: 'AU' },
    { value: 'AED', label: 'AED', icon: flagAed, country: 'AE' },
    { value: 'AMD', label: 'AMD', icon: flagAmd, country: 'AM' },
    { value: 'BMD', label: 'BMD', icon: flagBmd, country: 'BM' },
    { value: 'BRL', label: 'BRL', icon: flagBr, country: 'BR' },
    { value: 'CAD', label: 'CAD', icon: cadFlag, country: 'CA' },
    { value: 'CHF', label: 'CHF', icon: flagChf, country: 'CH' },
    { value: 'CLP', label: 'CLP', icon: flagCl, country: 'CL' },
    { value: 'CNY', label: 'CNY', icon: cnFlag, country: 'CN' },
    { value: 'CRC', label: 'CRC', icon: flagCrc, country: 'CR' },
    { value: 'DKK', label: 'DKK', icon: flagDk, country: 'DK' },
    { value: 'DOP', label: 'DOP', icon: flagDo, country: 'DO' },
    { value: 'EGP', label: 'EGP', icon: flagEg, country: 'EG' },
    { value: 'EUR', label: 'EUR', icon: eurFlag, country: 'EU' },
    { value: 'GBP', label: 'GBP', icon: gbpFlag, country: 'GB' },
    { value: 'GTQ', label: 'GTQ', icon: flagGt, country: 'GT' },
    { value: 'HKD', label: 'HKD', icon: hkdFlag, country: 'HK' },
    { value: 'HUF', label: 'HUF', icon: flagHu, country: 'HU' },
    { value: 'IDR', label: 'IDR', icon: flagId, country: 'ID' },
    { value: 'ILS', label: 'ILS', icon: flagIl, country: 'IL' },
    { value: 'INR', label: 'INR', icon: flagIn, country: 'IN' },
    { value: 'JMD', label: 'JMD', icon: flagJm, country: 'JM' },
    { value: 'JPY', label: 'JPY', icon: jpyFlag, country: 'JP' },
    { value: 'JOD', label: 'JOD', icon: flagJo, country: 'JO' },
    { value: 'KES', label: 'KES', icon: flagKe, country: 'KE' },
    { value: 'KRW', label: 'KRW', icon: flagKr, country: 'KR' },
    { value: 'KYD', label: 'KYD', icon: flagKy, country: 'KY' },
    { value: 'LAK', label: 'LAK', icon: flagLa, country: 'LA' },
    { value: 'LBP', label: 'LBP', icon: flagLb, country: 'LB' },
    { value: 'LKR', label: 'LKR', icon: flagLk, country: 'LK' },
    { value: 'LTL', label: 'LTL', icon: flagLt, country: 'LT' },
    { value: 'LVL', label: 'LVL', icon: flagLv, country: 'LV' },
    { value: 'LYD', label: 'LYD', icon: flagLy, country: 'LY' },
    { value: 'MXN', label: 'MXN', icon: mxnFlag, country: 'MX' },
    { value: 'NGN', label: 'NGN', icon: flagNg, country: 'NG' },
    { value: 'NOK', label: 'NOK', icon: flagNo, country: 'NO' },
    { value: 'NZD', label: 'NZD', icon: flagNz, country: 'NZ' },
    { value: 'PEN', label: 'PEN', icon: flagPe, country: 'PE' },
    { value: 'PHP', label: 'PHP', icon: flagPh, country: 'PH' },
    { value: 'PKR', label: 'PKR', icon: flagPk, country: 'PK' },
    { value: 'PLN', label: 'PLN', icon: flagPl, country: 'PL' },
    { value: 'QAR', label: 'QAR', icon: flagQa, country: 'QA' },
    { value: 'MYR', label: 'MYR', icon: flagRm, country: 'MY' },
    { value: 'RON', label: 'RON', icon: flagRon, country: 'RO' },
    { value: 'RSD', label: 'RSD', icon: flagRsd, country: 'RS' },
    { value: 'SCR', label: 'SCR', icon: flagSc, country: 'SC' },
    { value: 'SEK', label: 'SEK', icon: flagSe, country: 'SE' },
    { value: 'SGD', label: 'SGD', icon: sgFlag, country: 'SG' },
    { value: 'THB', label: 'THB', icon: flagTh, country: 'TH' },
    { value: 'TRY', label: 'TRY', icon: flagTr, country: 'TR' },
    { value: 'TWD', label: 'TWD', icon: flagTw, country: 'TW' },
    { value: 'UAH', label: 'UAH', icon: flagUah, country: 'UA' },
    { value: 'UGX', label: 'UGX', icon: flagUgx, country: 'UG' },
    { value: 'USD', label: 'USD', icon: usdFlag, country: 'US' },
    { value: 'VND', label: 'VND', icon: flagVnd, country: 'VN' },
    { value: 'XCD', label: 'XCD', icon: generic, country: 'XC' },
    { value: 'ZMW', label: 'ZMW', icon: flagZm, country: 'ZM' },
    // { value: 'AED', label: 'AED 🇦🇪' },
    // { value: 'AFN', label: 'AFN 🇦🇫' },
    // { value: 'ALL', label: 'ALL 🇦🇱' },
    // { value: 'AMD', label: 'AMD 🇦🇲' },
    // { value: 'ANG', label: 'ANG 🇳🇱' },
    // { value: 'AOA', label: 'AOA 🇦🇴' },
    // { value: 'ARS', label: 'ARS 🇦🇷' },
    // { value: 'AUD', label: 'AUD', icon: audFlag },
    // { value: 'AWG', label: 'AWG 🇦🇼' },
    // { value: 'AZM', label: 'AZM 🇦🇿' },
    // { value: 'AZN', label: 'AZN 🇦🇿' },
    // { value: 'BAM', label: 'BAM 🇧🇦' },
    // { value: 'BBD', label: 'BBD 🇧🇧' },
    // { value: 'BDT', label: 'BDT 🇧🇩' },
    // { value: 'BGN', label: 'BGN 🇧🇬' },
    // { value: 'BHD', label: 'BHD 🇧🇭' },
    // { value: 'BIF', label: 'BIF 🇧🇮' },
    // { value: 'BMD', label: 'BMD 🇧🇲' },
    // { value: 'BND', label: 'BND 🇧🇳' },
    // { value: 'BOB', label: 'BOB 🇧🇴' },
    // { value: 'BRL', label: 'BRL 🇧🇷' },
    // { value: 'BSD', label: 'BSD 🇧🇸' },
    // { value: 'BTN', label: 'BTN 🇧🇹' },
    // { value: 'BWP', label: 'BWP 🇧🇼' },
    // { value: 'BYN', label: 'BYN 🇧🇾' },
    // { value: 'BZD', label: 'BZD 🇧🇿' },
    // { value: 'CAD', label: 'CAD', icon: cadFlag },
    // { value: 'CDF', label: 'CDF 🇨🇩' },
    // { value: 'CHF', label: 'CHF 🇨🇭' },
    // { value: 'CLP', label: 'CLP 🇨🇱' },
    // { value: 'CNY', label: 'CNY 🇨🇳' },
    // { value: 'COP', label: 'COP 🇨🇴' },
    // { value: 'CRC', label: 'CRC 🇨🇷' },
    // { value: 'CUP', label: 'CUP 🇨🇺' },
    // { value: 'CVE', label: 'CVE 🇨🇻' },
    // { value: 'CZK', label: 'CZK 🇨🇿' },
    // { value: 'DJF', label: 'DJF 🇩🇯' },
    // { value: 'DKK', label: 'DKK 🇩🇰' },
    // { value: 'DOP', label: 'DOP 🇩🇴' },
    // { value: 'DZD', label: 'DZD 🇩🇿' },
    // { value: 'EGP', label: 'EGP 🇪🇬' },
    // { value: 'ERN', label: 'ERN 🇪🇷' },
    // { value: 'ETB', label: 'ETB 🇪🇹' },
    // { value: 'EUR', label: 'EUR', icon: eurFlag },
    // { value: 'FJD', label: 'FJD 🇫🇯' },
    // { value: 'FKP', label: 'FKP 🇫🇰' },
    // { value: 'GBP', label: 'GBP', icon: gbpFlag },
    // { value: 'GEL', label: 'GEL 🇬🇪' },
    // { value: 'GHS', label: 'GHS 🇬🇭' },
    // { value: 'GIP', label: 'GIP 🇬🇮' },
    // { value: 'GMD', label: 'GMD 🇬🇲' },
    // { value: 'GNF', label: 'GNF 🇬🇳' },
    // { value: 'GQE', label: 'GQE 🇬🇶' },
    // { value: 'GTQ', label: 'GTQ 🇬🇹' },
    // { value: 'GWP', label: 'GWP 🇬🇼' },
    // { value: 'GYD', label: 'GYD 🇬🇾' },
    // { value: 'HKD', label: 'HKD 🇭🇰' },
    // { value: 'HNL', label: 'HNL 🇭🇳' },
    // { value: 'HRK', label: 'HRK 🇭🇷' },
    // { value: 'HTG', label: 'HTG 🇭🇹' },
    // { value: 'HUF', label: 'HUF 🇭🇺' },
    // { value: 'IDR', label: 'IDR 🇮🇩' },
    // { value: 'ILS', label: 'ILS 🇮🇱' },
    // { value: 'INR', label: 'INR 🇮🇳' },
    // { value: 'IQD', label: 'IQD 🇮🇶' },
    // { value: 'IRR', label: 'IRR 🇮🇷' },
    // { value: 'JMD', label: 'JMD 🇯🇲' },
    // { value: 'JOD', label: 'JOD 🇯🇴' },
    // { value: 'JPY', label: 'JPY 🇯🇵' },
    // { value: 'KES', label: 'KES 🇰🇪' },
    // { value: 'KGS', label: 'KGS 🇰🇬' },
    // { value: 'KHR', label: 'KHR 🇰🇭' },
    // { value: 'KMF', label: 'KMF 🇰🇲' },
    // { value: 'KPW', label: 'KPW 🇰🇵' },
    // { value: 'KRW', label: 'KRW 🇰🇷' },
    // { value: 'KWD', label: 'KWD 🇰🇼' },
    // { value: 'KYD', label: 'KYD 🇰🇾' },
    // { value: 'KZT', label: 'KZT 🇰🇿' },
    // { value: 'LAK', label: 'LAK 🇱🇦' },
    // { value: 'LBP', label: 'LBP 🇱🇧' },
    // { value: 'LKR', label: 'LKR 🇱🇰' },
    // { value: 'LRD', label: 'LRD 🇱🇷' },
    // { value: 'LSL', label: 'LSL 🇱🇸' },
    // { value: 'LYD', label: 'LYD 🇱🇾' },
    // { value: 'MAD', label: 'MAD 🇲🇦' },
    // { value: 'MDL', label: 'MDL 🇲🇩' },
    // { value: 'MGA', label: 'MGA 🇲🇬' },
    // { value: 'MKD', label: 'MKD 🇲🇰' },
    // { value: 'MMK', label: 'MMK 🇲🇲' },
    // { value: 'MNT', label: 'MNT 🇲🇳' },
    // { value: 'MOP', label: 'MOP 🇲🇴' },
    // { value: 'MRU', label: 'MRU 🇲🇷' },
    // { value: 'MUR', label: 'MUR 🇲🇺' },
    // { value: 'MVR', label: 'MVR 🇲🇻' },
    // { value: 'MWK', label: 'MWK 🇲🇼' },
    // { value: 'MXN', label: 'MXN', icon: mxnFlag },
    // { value: 'MYR', label: 'MYR 🇲🇾' },
    // { value: 'MZN', label: 'MZN 🇲🇿' },
    // { value: 'NAD', label: 'NAD 🇳🇦' },
    // { value: 'NGN', label: 'NGN 🇳🇬' },
    // { value: 'NIO', label: 'NIO 🇳🇮' },
    // { value: 'NOK', label: 'NOK 🇳🇴' },
    // { value: 'NPR', label: 'NPR 🇳🇵' },
    // { value: 'NZD', label: 'NZD 🇳🇿' },
    // { value: 'OMR', label: 'OMR 🇴🇲' },
    // { value: 'PAB', label: 'PAB 🇵🇦' },
    // { value: 'PEN', label: 'PEN 🇵🇪' },
    // { value: 'PGK', label: 'PGK 🇵🇬' },
    // { value: 'PHP', label: 'PHP 🇵🇭' },
    // { value: 'PKR', label: 'PKR 🇵🇰' },
    // { value: 'PLN', label: 'PLN 🇵🇱' },
    // { value: 'PYG', label: 'PYG 🇵🇾' },
    // { value: 'QAR', label: 'QAR 🇶🇦' },
    // { value: 'RON', label: 'RON 🇷🇴' },
    // { value: 'RSD', label: 'RSD 🇷🇸' },
    // { value: 'RUB', label: 'RUB 🇷🇺' },
    // { value: 'RWF', label: 'RWF 🇷🇼' },
    // { value: 'SAR', label: 'SAR 🇸🇦' },
    // { value: 'SBD', label: 'SBD 🇸🇧' },
    // { value: 'SCR', label: 'SCR 🇸🇨' },
    // { value: 'SDG', label: 'SDG 🇸🇩' },
    // { value: 'SEK', label: 'SEK 🇸🇪' },
    // { value: 'SGD', label: 'SGD 🇸🇬' },
    // { value: 'SHP', label: 'SHP 🇸🇭' },
    // { value: 'SLL', label: 'SLL 🇸🇱' },
    // { value: 'SOS', label: 'SOS 🇸🇴' },
    // { value: 'SRD', label: 'SRD 🇸🇷' },
    // { value: 'SSP', label: 'SSP 🇸🇸' },
    // { value: 'STN', label: 'STN 🇸🇹' },
    // { value: 'SVC', label: 'SVC 🇸🇻' },
    // { value: 'SYP', label: 'SYP 🇸🇾' },
    // { value: 'SZL', label: 'SZL 🇸🇿' },
    // { value: 'THB', label: 'THB 🇹🇭' },
    // { value: 'TJS', label: 'TJS 🇹🇯' },
    // { value: 'TMT', label: 'TMT 🇹🇲' },
    // { value: 'TND', label: 'TND 🇹🇳' },
    // { value: 'TOP', label: 'TOP 🇹🇴' },
    // { value: 'TRY', label: 'TRY 🇹🇷' },
    // { value: 'TTD', label: 'TTD 🇹🇹' },
    // { value: 'TWD', label: 'TWD 🇹🇼' },
    // { value: 'TZS', label: 'TZS 🇹🇿' },
    // { value: 'UAH', label: 'UAH 🇺🇦' },
    // { value: 'UGX', label: 'UGX 🇺🇬' },
    // { value: 'UYU', label: 'UYU 🇺🇾' },
    // { value: 'UZS', label: 'UZS 🇺🇿' },
    // { value: 'VES', label: 'VES 🇻🇪' },
    // { value: 'VND', label: 'VND 🇻🇳' },
    // { value: 'VUV', label: 'VUV 🇻🇺' },
    // { value: 'WST', label: 'WST 🇼🇸' },
    // { value: 'XAF', label: 'XAF' },
    // { value: 'XCD', label: 'XCD 🇦🇬🇩🇲🇰🇳🇱🇨🇻🇨🇬🇩' },
    // { value: 'XOF', label: 'XOF' },
    // { value: 'XPF', label: 'XPF 🇳🇨🇵🇫🇼🇫' },
    // { value: 'YER', label: 'YER 🇾🇪' },
    // { value: 'ZAR', label: 'ZAR 🇿🇦' },
    // { value: 'ZMW', label: 'ZMW 🇿🇲' },
    // { value: 'ZWL', label: 'ZWL 🇿🇼' }
];


export const currencyFlags = {
    // 'AED': '🇦🇪',  // United Arab Emirates Dirham
    // 'AFN': '🇦🇫',  // Afghan Afghani
    // 'ALL': '🇦🇱',  // Albanian Lek
    // 'AMD': '🇦🇲',  // Armenian Dram
    // 'ANG': '🇦🇼',  // Netherlands Antillean Guilder
    // 'AOA': '🇦🇴',  // Angolan Kwanza
    // 'ARS': '🇦🇷',  // Argentine Peso
    'AUD': '🇦🇺',  // Australian Dollar
    // 'AWG': '🇦🇼',  // Aruban Florin
    // 'AZM': '🇦🇿',  // Azerbaijani Manat (old)
    // 'AZN': '🇦🇿',  // Azerbaijani Manat
    // 'BAM': '🇧🇦',  // Bosnia-Herzegovina Convertible Mark
    // 'BBD': '🇧🇧',  // Barbadian Dollar
    // 'BDT': '🇧🇩',  // Bangladeshi Taka
    // 'BGN': '🇧🇬',  // Bulgarian Lev
    // 'BHD': '🇧🇭',  // Bahraini Dinar
    // 'BIF': '🇧🇮',  // Burundian Franc
    // 'BMD': '🇧🇲',  // Bermudian Dollar
    // 'BND': '🇧🇳',  // Brunei Dollar
    // 'BOB': '🇧🇴',  // Bolivian Boliviano
    // 'BRL': '🇧🇷',  // Brazilian Real
    // 'BSD': '🇧🇸',  // Bahamian Dollar
    // 'BTN': '🇧🇹',  // Bhutanese Ngultrum
    // 'BWP': '🇧🇼',  // Botswanan Pula
    // 'BYN': '🇧🇾',  // Belarusian Ruble
    // 'BZD': '🇧🇿',  // Belize Dollar
    'CAD': '🇨🇦',  // Canadian Dollar
    // 'CDF': '🇨🇩',  // Congolese Franc
    // 'CHF': '🇨🇭',  // Swiss Franc
    // 'CLP': '🇨🇱',  // Chilean Peso
    'CNY': '🇨🇳',  // Chinese Yuan
    // 'COP': '🇨🇴',  // Colombian Peso
    // 'CRC': '🇨🇷',  // Costa Rican Colón
    // 'CUP': '🇨🇺',  // Cuban Peso
    // 'CVE': '🇨🇻',  // Cape Verdean Escudo
    // 'CZK': '🇨🇿',  // Czech Koruna
    // 'DJF': '🇩🇯',  // Djiboutian Franc
    // 'DKK': '🇩🇰',  // Danish Krone
    // 'DOP': '🇩🇴',  // Dominican Peso
    // 'DZD': '🇩🇿',  // Algerian Dinar
    // 'EGP': '🇪🇬',  // Egyptian Pound
    // 'ERN': '🇪🇷',  // Eritrean Nakfa
    // 'ETB': '🇪🇹',  // Ethiopian Birr
    'EUR': '🇪🇺',  // Euro
    // 'FJD': '🇫🇯',  // Fijian Dollar
    // 'FKP': '🇫🇰',  // Falkland Islands Pound
    'GBP': '🇬🇧',  // British Pound Sterling
    // 'GEL': '🇬🇪',  // Georgian Lari
    // 'GHS': '🇬🇭',  // Ghanaian Cedi
    // 'GIP': '🇬🇮',  // Gibraltar Pound
    // 'GMD': '🇬🇲',  // Gambian Dalasi
    // 'GNF': '🇬🇳',  // Guinean Franc
    // 'GQE': '🇬🇶',  // Equatorial Guinean Ekwele
    // 'GTQ': '🇬🇹',  // Guatemalan Quetzal
    // 'GWP': '🇬🇼',  // Guinea-Bissau Peso (obsolete)
    // 'GYD': '🇬🇾',  // Guyanaese Dollar
    // 'HKD': '🇭🇰',  // Hong Kong Dollar
    // 'HNL': '🇭🇳',  // Honduran Lempira
    // 'HRK': '🇭🇷',  // Croatian Kuna
    // 'HTG': '🇭🇹',  // Haitian Gourde
    // 'HUF': '🇭🇺',  // Hungarian Forint
    // 'IDR': '🇮🇩',  // Indonesian Rupiah
    // 'ILS': '🇮🇱',  // Israeli New Shekel
    // 'INR': '🇮🇳',  // Indian Rupee
    // 'IQD': '🇮🇶',  // Iraqi Dinar
    // 'IRR': '🇮🇷',  // Iranian Rial
    // 'JMD': '🇯🇲',  // Jamaican Dollar
    // 'JOD': '🇯🇴',  // Jordanian Dinar
    // 'JPY': '🇯🇵',  // Japanese Yen
    // 'KES': '🇰🇪',  // Kenyan Shilling
    // 'KGS': '🇰🇬',  // Kyrgystani Som
    // 'KHR': '🇰🇭',  // Cambodian Riel
    // 'KMF': '🇰🇲',  // Comorian Franc
    // 'KPW': '🇰🇵',  // North Korean Won
    // 'KRW': '🇰🇷',  // South Korean Won
    // 'KWD': '🇰🇼',  // Kuwaiti Dinar
    // 'KYD': '🇰🇾',  // Cayman Islands Dollar
    // 'KZT': '🇰🇿',  // Kazakhstani Tenge
    // 'LAK': '🇱🇦',  // Laotian Kip
    // 'LBP': '🇱🇧',  // Lebanese Pound
    // 'LKR': '🇱🇰',  // Sri Lankan Rupee
    // 'LRD': '🇱🇷',  // Liberian Dollar
    // 'LSL': '🇱🇸',  // Lesotho Loti
    // 'LYD': '🇱🇾',  // Libyan Dinar
    // 'MAD': '🇲🇦',  // Moroccan Dirham
    // 'MDL': '🇲🇩',  // Moldovan Leu
    // 'MGA': '🇲🇬',  // Malagasy Ariary
    // 'MKD': '🇲🇰',  // Macedonian Denar
    // 'MMK': '🇲🇲',  // Myanma Kyat
    // 'MNT': '🇲🇳',  // Mongolian Tugrik
    // 'MOP': '🇲🇴',  // Macanese Pataca
    // 'MRU': '🇲🇷',  // Mauritanian Ouguiya
    // 'MUR': '🇲🇺',  // Mauritian Rupee
    // 'MVR': '🇲🇻',  // Maldivian Rufiyaa
    // 'MWK': '🇲🇼',  // Malawian Kwacha
    // 'MXN': '🇲🇽',  // Mexican Peso
    // 'MYR': '🇲🇾',  // Malaysian Ringgit
    // 'MZN': '🇲🇿',  // Mozambican Metical
    // 'NAD': '🇳🇦',  // Namibian Dollar
    // 'NGN': '🇳🇬',  // Nigerian Naira
    // 'NIO': '🇳🇮',  // Nicaraguan Córdoba
    // 'NOK': '🇳🇴',  // Norwegian Krone
    // 'NPR': '🇳🇵',  // Nepalese Rupee
    // 'NZD': '🇳🇿',  // New Zealand Dollar
    // 'OMR': '🇴🇲',  // Omani Rial
    // 'PAB': '🇵🇦',  // Panamanian Balboa
    // 'PEN': '🇵🇪',  // Peruvian Nuevo Sol
    // 'PGK': '🇵🇬',  // Papua New Guinean Kina
    // 'PHP': '🇵🇭',  // Philippine Peso
    // 'PKR': '🇵🇰',  // Pakistani Rupee
    // 'PLN': '🇵🇱',  // Polish Zloty
    // 'PYG': '🇵🇾',  // Paraguayan Guarani
    // 'QAR': '🇶🇦',  // Qatari Rial
    // 'RON': '🇷🇴',  // Romanian Leu
    // 'RSD': '🇷🇸',  // Serbian Dinar
    // 'RUB': '🇷🇺',  // Russian Ruble
    // 'RWF': '🇷🇼',  // Rwandan Franc
    // 'SAR': '🇸🇦',  // Saudi Riyal
    // 'SBD': '🇸🇧',  // Solomon Islands Dollar
    // 'SCR': '🇸🇨',  // Seychellois Rupee
    // 'SDG': '🇸🇩',  // Sudanese Pound
    // 'SEK': '🇸🇪',  // Swedish Krona
    // 'SGD': '🇸🇬',  // Singapore Dollar
    // 'SHP': '🇸🇭',  // Saint Helena Pound
    // 'SLL': '🇸🇱',  // Sierra Leonean Leone
    // 'SOS': '🇸🇴',  // Somali Shilling
    // 'SRD': '🇸🇷',  // Surinamese Dollar
    // 'SSP': '🇸🇸',  // South Sudanese Pound
    // 'STN': '🇸🇹',  // São Tomé and Príncipe Dobra
    // 'SYP': '🇸🇾',  // Syrian Pound
    // 'SZL': '🇸🇿',  // Swazi Lilangeni
    // 'THB': '🇹🇭',  // Thai Baht
    // 'TJS': '🇹🇯',  // Tajikistani Somoni
    // 'TMT': '🇹🇲',  // Turkmenistani Manat
    // 'TND': '🇹🇳',  // Tunisian Dinar
    // 'TOP': '🇹🇴',  // Tongan Pa'anga
    // 'TRY': '🇹🇷',  // Turkish Lira
    // 'TTD': '🇹🇹',  // Trinidad and Tobago Dollar
    // 'TWD': '🇹🇼',  // New Taiwan Dollar
    // 'TZS': '🇹🇿',  // Tanzanian Shilling
    // 'UAH': '🇺🇦',  // Ukrainian Hryvnia
    // 'UGX': '🇺🇬',  // Ugandan Shilling
    'USD': '🇺🇸',  // United States Dollar
    // 'UYU': '🇺🇾',  // Uruguayan Peso
    // 'UZS': '🇺🇿',  // Uzbekistani Som
    // 'VES': '🇻🇪',  // Venezuelan Bolívar
    // 'VND': '🇻🇳',  // Vietnamese Dong
    // 'VUV': '🇻🇺',  // Vanuatu Vatu
    // 'WST': '🇼🇸',  // Samoan Tala
    // 'XAF': '🇨🇲',  // CFA Franc BEAC (Cameroon flag as representative)
    // 'XCD': '🇦🇬',  // East Caribbean Dollar (Antigua and Barbuda flag as representative)
    // 'XOF': '🇸🇳',  // CFA Franc BCEAO (Senegal flag as representative)
    // 'XPF': '🇵🇫',  // CFP Franc (French Polynesia flag as representative)
    // 'YER': '🇾🇪',  // Yemeni Rial
    // 'ZAR': '🇿🇦',  // South African Rand
    // 'ZMW': '🇿🇲',  // Zambian Kwacha
    // 'ZWL': '🇿🇼'   // Zimbabwean Dollar
};