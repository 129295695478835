import React, {useContext, useEffect, useState} from 'react';
// 3rd Party imports
import {useFormik} from 'formik';
import PropTypes from 'prop-types';

// materialUI imports
import {ExpandMore, Refresh} from "@mui/icons-material";
import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';

// project imports
import ConfigContext from '../../store/context/ConfigContext';
import {useAuthHeaders} from '../../auth/AuthHeaders';
import useFetchRate from "../../store/hooks/useFetchRate";
import {NumericFormat} from "react-number-format";
import CircularSpinner from "../CircularSpinner";
import {RolesContext} from "../../store/context/RolesContext";
import usdFlag from "../../assets/images/usd_flag.png";
import {getCurrencies} from "../../store/hooks/useConfiguredCurrencies";
import {currencyOptions} from "../../store/CurrencyOptions";
import {getDecimalPlaces, shouldFixDecimalScale} from "../../store/currencyDecimalFormater";

// ============================|| CURRENCY CONVERTER STYLES ||============================ //
const selectCurrencyStyle = {
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '1.2rem',
    '& .MuiSelect-select': {
        backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiSelect-icon': {
        color: 'rgba(0, 0, 0, 0.54)',
    },
};

const textFieldStyle = {
    mb: 2,
    '& .MuiInputBase-input': {
        fontSize: '1.2rem',
        padding: '20px 18px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: '3px',
    },
};

// ============================|| CURRENCY CONVERTER ||============================ //
const CurrencyConverter = ({
                               handleBack,
                               handleNext,
                               EntityId,
                               onApiResponse,
                               entityName,
                               whichInputTouched,
                               setWhichInputTouched,
                               ledgersData,
                               currency
                           }) => {
    const config = useContext(ConfigContext);
    const authHeaders = useAuthHeaders();
    const clientId = sessionStorage.getItem('clientId');
    const [rateCalculated, setRateCalculated] = useState(false);
    const [showInverseRate, setShowInverseRate] = useState(false);
    const {roles} = useContext(RolesContext);
    const userHasGetQuoteRole = roles.includes('quote');
    const [selectedLedger, setSelectedLedger] = useState({});
    const [loading, setLoading] = useState(true);
    const [sendCurrencyDisabled, setSendCurrencyDisabled] = useState(false);

    const currencyOptionsFromGetCurrencies = getCurrencies();
    const rateDisclaimer = sessionStorage.getItem("rateDisclaimer");

    // Ledgers Use Effect - Find Ledger info and hide if only 1
    useEffect(() => {
        if (ledgersData.length > 0) {
            const usdLedger = ledgersData.find(ledger => ledger.currency === 'USD') || ledgersData[0];
            setSelectedLedger(usdLedger);
            setLoading(false);
            formik.setFieldValue('sendCurrency', usdLedger.currency);
            formik.setFieldValue('ledgerId', usdLedger.ledgerId);
            // Check if only one ledger exists
            if (ledgersData.length === 1) {
                setSendCurrencyDisabled(true); // Disable sendCurrency selection
            }
        }
    }, [ledgersData]);

    const availableCurrencies = ledgersData.map(ledger => ({
        currency: ledger.currency,
        ledgerId: ledger.ledgerId
    }));

    const sendCurrencyOptions = availableCurrencies.map(ledgerCurrency => {
        const currencyOption = currencyOptions.find(option => option.value === ledgerCurrency.currency);
        if (ledgerCurrency.currency === 'JPY') {
        }
        return {
            ...ledgerCurrency,
            ...currencyOption
        };
    });

    useEffect(() => {
        setWhichInputTouched("sellAmount");
    }, []);


    const {
        rate,
        isLoading,
        fetchRate,
        submitCurrencyConversion,
        apiResponse,
        error
    } = useFetchRate(config, authHeaders, clientId, EntityId, selectedLedger);

    const formik = useFormik({
        initialValues: {
            sellAmount: '10000',
            buyAmount: '',
            sendCurrency: selectedLedger.currency || 'USD',
            currency: userHasGetQuoteRole ? (currency || currencyOptionsFromGetCurrencies[0]?.value) : 'USD',
            ledgerId: selectedLedger.ledgerId,
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const selectedSendCurrency = sendCurrencyOptions.find(option => option.value === values.sendCurrency);
            const fromLedgerId = selectedSendCurrency ? selectedSendCurrency.ledgerId : '';
            const enhancedApiResponse = (apiResponse) => {
                onApiResponse({
                    ...apiResponse,
                    sendCurrency: values.sendCurrency
                });
            };
            submitCurrencyConversion(values, handleNext, enhancedApiResponse, fromLedgerId,whichInputTouched);
        },
    });

    const calculateOtherAmount = (amount, isSellAmount) => {
        if (!rate || !amount) return '';

        return isSellAmount
            ? (parseFloat(amount) * rate).toFixed(2)
            : (parseFloat(amount) / rate).toFixed(2);
    };

    const handleAmountChange = (fieldValues) => {
        const {name, value} = fieldValues;
        formik.setFieldValue(name, value);

        if (rate) {
            const otherFieldName = name === 'sellAmount' ? 'buyAmount' : 'sellAmount';
            const otherFieldValue = calculateOtherAmount(value, name === 'sellAmount');
            formik.setFieldValue(otherFieldName, otherFieldValue);
        }
    };

    const handleCurrencyChange = (e) => {
        const {name, value} = e.target;
        formik.setFieldValue(name, value);
        setRateCalculated(false);
    };

    useEffect(() => {
        if (rate && formik.values.sellAmount) {
            const recalculatedBuyAmount = calculateOtherAmount(formik.values.sellAmount, true);
            formik.setFieldValue('buyAmount', recalculatedBuyAmount);
        }
    }, [rate]);


    useEffect(() => {
        if (!rateCalculated) {
            const values = formik.values;
            const amountField = values.sellAmount ? 'sellAmount' : 'buyAmount';
            const amount = values[amountField];

            if (amount && values.currency) {
                const selectedSendCurrency = sendCurrencyOptions.find(option => option.value === values.sendCurrency);
                const fromLedgerId = selectedSendCurrency ? selectedSendCurrency.ledgerId : '';
                fetchRate(values.currency, amountField, amount, fromLedgerId);
                setRateCalculated(true);
            }
        }
    }, [formik.values.currency, formik.values.sellAmount, formik.values.buyAmount, rateCalculated, formik.values.sendCurrency]);

    const refetchRate = () => {
        const values = formik.values;
        const amountField = values.sellAmount ? 'sellAmount' : 'buyAmount';
        const amount = values[amountField];
        if (amount && values.currency) {
            const selectedSendCurrency = sendCurrencyOptions.find(option => option.value === values.sendCurrency);
            const fromLedgerId = selectedSendCurrency ? selectedSendCurrency.ledgerId : '';
            fetchRate(values.currency, amountField, amount, fromLedgerId);
        }
    };

    if (loading) {
        return <CircularSpinner/>;
    }

    return (
        <Container maxWidth="sm" sx={{my: 5}}>
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{p: 1, bgcolor: 'background.paper'}}>
                    <Typography variant="body2" sx={{color: 'text.secondary', mb: 1}}>
                        You send exactly
                    </Typography>
                    <NumericFormat
                        fullWidth
                        variant="outlined"
                        name="sellAmount"
                        value={formik.values.sellAmount}
                        onValueChange={(values) => {
                            handleAmountChange({name: "sellAmount", value: values.floatValue})
                        }}
                        thousandSeparator
                        decimalScale={getDecimalPlaces(formik.values.sendCurrency)}
                        fixedDecimalScale={shouldFixDecimalScale(formik.values.sendCurrency)}
                        customInput={TextField}
                        onFocus={() => setWhichInputTouched("sellAmount")}
                        sx={{
                            ...textFieldStyle,
                            '& .MuiSelect-icon': {
                                display: 'none',
                            },
                        }}
                        disabled={isLoading}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {/*<Tooltip*/}
                                    {/*    title="Your account is configured to send USD. Below, you can select the currency your beneficiary will receive.">*/}
                                    <Select
                                        name="sendCurrency"
                                        value={formik.values.sendCurrency}
                                        onChange={handleCurrencyChange}
                                        displayEmpty
                                        sx={selectCurrencyStyle}
                                        disabled={sendCurrencyDisabled}
                                    >
                                        {sendCurrencyOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                <Box display="flex" alignItems="center">
                                                    <img
                                                        src={option.icon}
                                                        alt={option.label}
                                                        style={{marginRight: '10px', height: '30px'}}
                                                    />
                                                    {option.label}
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {/*</Tooltip>*/}
                                </InputAdornment>
                            ),
                        }}
                    />

                    {isLoading && (
                        <Box sx={{display: 'flex', justifyContent: 'center', my: 2, height: "15px"}}>
                            <CircularSpinner/>
                        </Box>
                    )}

                    {error &&
                        <Box sx={{p: 1, bgcolor: "background.paper", display: "flex", alignItems: "center"}}>
                            <Typography variant="h4" sx={{flexGrow: 1, textAlign: "center"}}>
                                Something went wrong, please try again
                            </Typography>
                            <IconButton
                                onClick={refetchRate}
                                size="small"
                                aria-label="refresh"
                                sx={{ml: 2}}
                                color="secondary"
                            >
                                <Refresh/>
                            </IconButton>
                        </Box>
                    }
                    {apiResponse?.response && !error && (
                        <Box sx={{p: 1, bgcolor: "background.paper", display: "flex", justifyContent: "flex-end"}}>
                            <Grid container alignItems="center" spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Typography variant="h3" sx={{mr: 5}}>
                                        Rate: {apiResponse.response.rate}
                                    </Typography>
                                    {showInverseRate && (
                                        <Typography variant="body1" sx={{mr: 5, mt: 1}}>Inverse
                                            Rate: {apiResponse.response.inverse}</Typography>
                                    )}
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        onClick={() => setShowInverseRate(!showInverseRate)}
                                        size="small"
                                        aria-label="show inverse rate"
                                    >
                                        <ExpandMore/>
                                    </IconButton>
                                </Grid>

                            </Grid>
                        </Box>
                    )}
                    <Typography variant="body2" sx={{color: 'text.secondary', mb: 1}}>
                        {entityName} gets
                    </Typography>
                    <NumericFormat
                        fullWidth
                        variant="outlined"
                        name="buyAmount"
                        value={formik.values.buyAmount}
                        onValueChange={(values) => {
                            handleAmountChange({name: "buyAmount", value: values.floatValue})
                        }}
                        onFocus={() => setWhichInputTouched("buyAmount")}
                        thousandSeparator
                        decimalScale={getDecimalPlaces(formik.values.currency)}
                        fixedDecimalScale={shouldFixDecimalScale(formik.values.currency)}
                        customInput={TextField}
                        sx={textFieldStyle}
                        disabled={isLoading}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Select
                                        name="currency"
                                        value={formik.values.currency}
                                        onChange={handleCurrencyChange}
                                        displayEmpty
                                        sx={selectCurrencyStyle}
                                        disabled={!userHasGetQuoteRole || isLoading}
                                    >
                                        {userHasGetQuoteRole ? (
                                            currencyOptionsFromGetCurrencies.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    <Box display="flex" alignItems="center">
                                                        <img
                                                            src={option.icon}
                                                            alt={option.label}
                                                            style={{marginRight: '10px', height: '30px'}}
                                                        />
                                                        {option.label}
                                                    </Box>
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem value="USD">
                                                <Box display="flex" alignItems="center">
                                                    <img
                                                        src={usdFlag}
                                                        alt="USD"
                                                        style={{marginRight: '10px', height: '30px'}}
                                                    />
                                                    USD
                                                </Box>
                                            </MenuItem>
                                        )}
                                    </Select>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {rateDisclaimer && (
                        <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '0.875rem' }}>
                            {rateDisclaimer}
                        </Typography>
                    )}
                    <Button type="submit" fullWidth variant="contained" sx={{mt: 2}} disabled={!!error}>
                        Next
                    </Button>
                    <Button onClick={handleBack} fullWidth variant="contained" sx={{mt: 2}} color={'secondary'}>
                        Back
                    </Button>
                </Box>
            </form>
        </Container>
    )
        ;
};

CurrencyConverter.propTypes = {
    EntityId: PropTypes.string.isRequired,
    handleBack: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    onApiResponse: PropTypes.func.isRequired,
    entityName: PropTypes.string.isRequired,
    setWhichInputTouched: PropTypes.func.isRequired
};

export default CurrencyConverter;