import {useCountryRegions} from "../../store/hooks/useCountryRegions";
import {Grid} from "@mui/material";
import CustomFormField from "../form-fields/CustomFormField";
import CustomDropdown from "../form-fields/CustomDropdown";
import React from "react";

// ============================|| Interledger - Form ||============================ //
export const InterLedgerForm = ({values, handleChange, handleBlur, touched, errors}) => {
    const {countries, regions, handleCountryChange} = useCountryRegions(handleChange);


    return (<Grid container spacing={1}>
        <Grid item xs={12} md={5}>
            <CustomFormField
                name="paymentPointer"
                label="Payment Pointer"
                value={values.paymentPointer}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
            />
        </Grid>

        <Grid item xs={12}>
            <CustomFormField
                name="street1"
                label="Street Address"
                value={values.street1}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
            />
        </Grid>

        <Grid item xs={12} md={6}>
            <CustomDropdown
                name="country"
                label="Country"
                value={values.country}
                handleChange={handleCountryChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
                options={countries}
                maxHeight={"50vh"}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            {values.country && (
                <CustomDropdown
                    name="state"
                    label="Region/State"
                    value={values.state}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    options={regions}
                />
            )}
        </Grid>
        <Grid item xs={12} md={6}>
            <CustomFormField
                name="city"
                label="City"
                value={values.city}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <CustomFormField
                name="postalCode"
                label="Zip Code / Postal Code"
                value={values.postalCode}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
            />
        </Grid>
    </Grid>);
};