import React, {useContext, useEffect, useState} from 'react';
import AuthWrapper from "../ui-component/auth/AuthWrapper";
import {Alert, Divider, Grid, Snackbar} from "@mui/material";
import AuthCardWrapper from "../ui-component/auth/AuthCardWrapper";
import {Link} from "react-router-dom";
import Logo from "../ui-component/Logo";
import PasswordLessLogin from "../auth/PasswordLessLogin";
import {AuthContext} from "../auth/AuthContext";
import {getRandomImage} from "../store/getRandomImage";

const LoginScreenWithMessage = ({ snackbarType }) => {
    const [openSnackbar, setOpenSnackbar] = useState(true);
    const {removeAuthToken} = useContext(AuthContext);
    const [backgroundImage, setBackgroundImage] = useState('');

    useEffect(() => {
        removeAuthToken();
    }, []);

    useEffect(() => {
        setBackgroundImage(getRandomImage());
    }, []);
    return (
        <AuthWrapper>
            <Grid container justifyContent="space-between" alignItems="center" sx={{ minHeight: '100vh' }}>
                <Grid item container justifyContent="center" md={6} lg={7}>
                    <AuthCardWrapper>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Link to="#" aria-label="theme-logo">
                                    <Logo />
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                <PasswordLessLogin />
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 4 }}>
                                <Divider />
                            </Grid>
                        </Grid>
                    </AuthCardWrapper>
                </Grid>
                <Grid item md={6} lg={5}
                      sx={{
                          height: "100vh",
                          maxHeight: "100vh",
                          display: { xs: 'none', md: 'block' },
                          backgroundImage: `url(${backgroundImage})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                      }}>
                </Grid>
            </Grid>
            <Snackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                {snackbarType === 'success' ? (
                    <Alert severity="success" variant="filled">
                        You've successfully registered. Please login to continue.
                    </Alert>
                ) : snackbarType === 'permissionDenied' ? (
                    <Alert severity="error" variant="filled">
                        Uh oh! Looks like you no longer have access to this resource. Please sign in to continue.
                    </Alert>
                ) : snackbarType === 'rolesChanged' ? (
                    <Alert severity="info" variant="filled">
                        Your user roles have been updated. Please log in again.
                    </Alert>
                ) : snackbarType === 'logout' ? (
                        <Alert severity="info" variant="filled">
                            You've successfully logged out
                        </Alert>
                    )
                    : (
                    <Alert severity="warning" variant="filled">
                        Session login expired. Please sign in to continue.
                    </Alert>
                )}
            </Snackbar>
        </AuthWrapper>
    );
}

export default LoginScreenWithMessage;