import {currencyOptions} from "store/CurrencyOptions";

// ==============================|| GET CURRENCY ICON ||============================== //

export const getCurrencyFlag = (currencyCode) => {
    const option = currencyOptions.find(option => option.value === currencyCode)
    return option.icon || ""
};


// ==============================|| RENDER CURRENCY ICON ||============================== //

const currencyFlagHelper = (currencyOption) => {
    
    const flagPath = getCurrencyFlag(currencyOption)
    
    return (
            <img 
                src={`${flagPath}`}
                alt={`Flag Icon`}
                style={{
                    maxHeight: '30px',
                    width: 'auto',
                    objectFit: 'contain',
                    verticalAlign: 'center',
                }}
            />      
        )
}

export default currencyFlagHelper;
