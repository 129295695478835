export const availableRoles = [
    { value: 'quote', label: 'Quote' },
    { value: 'payment_create', label: 'Create Payment' },
    { value: 'beneficiary_create', label: 'Create Beneficiary' },
    { value: 'invite', label: 'Invite' },
    { value: 'grant', label: 'Grant' },
    { value: 'revoke', label: 'Revoke' },
    { value: 'approve_payment', label: 'Approvals' },
    { value: 'payment_cancel', label: 'Cancel Payment' },
    { value: 'beneficiary_view', label: 'View Beneficiary' },
    { value: 'payment_view', label: 'View Payment' },
    { value: 'users_view', label: 'View Users' },
    { value: 'beneficiary_disable', label: 'Archive Beneficiary' }
];


export const roleMapping = {
    'approve_payment': 'approve::payment',
    'payment_cancel': 'payment::cancel',
    'payment_view': 'payment::view',
    'beneficiary_view': 'beneficiaries::view',
    'users_view': 'users::view',
};