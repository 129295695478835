import React, {useContext, useEffect, useState} from 'react';
import {Button, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import CustomFormField from "../../../ui-component/form-fields/CustomFormField";
import CustomDropdown from "../../../ui-component/form-fields/CustomDropdown";
import CustomCurrencyValueInput from "../../../ui-component/form-fields/CustomCurrencyValueInput";
import {useFormikContext} from "formik";
import RefreshIcon from "@mui/icons-material/Refresh";
import CustomStyledAutocomplete from "../../../ui-component/form-fields/CustomAutoComplete";
import axios from "axios";
import {useAuthHeaders} from "../../../auth/AuthHeaders";
import ConfigContext from "../../../store/context/ConfigContext";
import useEntityDetails from "../../../store/hooks/useEntityDetails";
import CircularSpinner from "../../../ui-component/CircularSpinner";
import {BeneficiaryDetailsModal} from "../../../ui-component/extended/modal/BeneficiaryDetailsModal";
import {PermIdentity} from "@mui/icons-material";

const FormFields = ({
                        clientData,
                        handleAddClient,
                        handleAddBeneficiary,
                        transactionType,
                        displayedCurrency,
                        setDisplayedCurrency,
                        rate,
                        currencyOptions,
                        handleFetchRate,
                        rateError,
                        showCreateBeneficiary,
                        rateLoading,
                    }) => {
    const {values, handleChange, handleBlur, touched, errors, setFieldValue} = useFormikContext();
    const [fieldsDisabled, setFieldsDisabled] = useState(false);
    const [beneficiaryOptions, setBeneficiaryOptions] = useState([]);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [showRate, setShowRate] = useState(false);
    const [showLedgerDropdown, setShowLedgerDropdown] = useState(true);
    const [paymentDescriptions, setPaymentDescriptions] = useState(null);
    const [showFreeText, setShowFreeText] = useState(false);
    const [showAddenda, setShowAddenda] = useState(false);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);

    const rateDisclaimer = sessionStorage.getItem("rateDisclaimer");
    const authHeaders = useAuthHeaders();
    const config = useContext(ConfigContext);

    useEffect(() => {
        // Fetch paymentDescriptions from sessionStorage
        try {
            const storedDescriptions = sessionStorage.getItem('paymentDescriptions');
            if (storedDescriptions) {
                const descriptions = storedDescriptions.split(',');
                if (Array.isArray(descriptions)) {
                    setPaymentDescriptions([...descriptions, 'Other']);
                }
            }
        } catch (error) {
            console.error('Error parsing paymentDescriptions from sessionStorage:', error);
        }
    }, []);

    useEffect(() => {
        // Fetch beneficiary data whenever the sendingClient value changes
        const getBeneficiaryData = async () => {
            try {
                const requestData = {
                    clientId: values.sendingClient?.value
                };
                const axiosConfig = {
                    url: `${config.API_URL}/figaro/v1/beneficiaries`,
                    method: 'POST',
                    data: requestData,
                    headers: authHeaders,
                };

                const response = await axios(axiosConfig);

                // Handle cases where entities are null or empty
                const newBeneficiaryOptions = response.data.entities ? response.data.entities.map(entity => ({
                    label: entity.name,
                    value: entity.reference
                })).sort((a, b) => a.label.localeCompare(b.label)) : [];

                setFieldValue('entityId', null);
                setBeneficiaryOptions(newBeneficiaryOptions);
            } catch (error) {
                console.error('Error fetching data:', error);
                if (error.response && error.response.status === 401) {
                    sessionStorage.clear();
                    window.location.href = '/token-expiry';
                }
            }
        };

        if (values.sendingClient?.value) {
            getBeneficiaryData();
        } else {
            setBeneficiaryOptions([]); // Reset beneficiary options if no sendingClient is selected
        }
    }, [values.sendingClient, showCreateBeneficiary]);

    // todo: refactor this so it only happens once here.
    //  Fetch entity details based on selected beneficiary and sending client

    const {entityDetails} = useEntityDetails(selectedBeneficiary, values.sendingClient?.value);


    const handleOpenDetailsModal = () => setDetailsModalOpen(true);
    const handleCloseDetailsModal = () => setDetailsModalOpen(false);

    useEffect(() => {
        // Set the default currency for the bene when beneficiary is selected
        if (entityDetails?.details?.currency) {
            setDisplayedCurrency(entityDetails.details.currency);
            setFieldValue('currency', entityDetails.details.currency);
        }
    }, [entityDetails]);

    const handleBeneficiaryChange = (newValue) => {
        if (newValue) {
            const beneficiaryName = newValue.label;
            const currentDate = new Date().toISOString().replace('T', ' ').split('.')[0];
            const newReference = `${beneficiaryName}-${currentDate}`;
            setFieldValue('reference', newReference);
            setFieldValue('entityId', newValue);
            setSelectedBeneficiary(newValue.value);
        } else {
            setFieldValue('entityId', null);
            setSelectedBeneficiary(null);
        }
    };

    const handleCurrencyChange = (e) => {
        setDisplayedCurrency(e.target.value);
        handleChange(e);
    };

    const handleGetRate = () => {
        handleFetchRate();
        setFieldsDisabled(true);
        setShowRate(true);
    };

    const handleResetRate = () => {
        setFieldsDisabled(false);
        setShowRate(false);
        setFieldValue('fxQuoteId', null)
    };

    const isValidCurrencyCode = (code) => {
        try {
            new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: code,
            }).format(0);
            return true;
        } catch (e) {
            return false;
        }
    };

    const formatCurrency = (amount, currency) => {
        if (!isValidCurrencyCode(currency)) {
            return `Invalid currency code: ${currency}`;
        }
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        }).format(amount);
    };

    const calculateTotalAmount = (amount, rate) => {
        return amount * rate;
    };

// Check if response.sellAmount exists and use it, otherwise calculate the total amount
    const totalAmount = rate?.response.sellAmount !== undefined
        ? rate.response.sellAmount
        : (rate ? calculateTotalAmount(values.amount, rate?.response.inverse) : 0);

// Format the total amount based on the displayed currency
    const formattedTotalAmount = displayedCurrency === "USD"
        ? formatCurrency(values.amount, displayedCurrency)
        : formatCurrency(totalAmount, 'USD');

    useEffect(() => {
        // Check if the selected client has only one ledger and set it by default, also hide dropdown
        if (values.sendingClient) {
            const selectedClient = clientData.find(client => client.reference === values.sendingClient.value);
            if (selectedClient && selectedClient.ledgers.length === 1) {
                const defaultLedger = selectedClient.ledgers[0];
                setFieldValue('ledgerId', defaultLedger.reference);
                setShowLedgerDropdown(false);
            } else {
                setShowLedgerDropdown(true);
            }
        } else {
            setShowLedgerDropdown(true);
        }
    }, [values.sendingClient, clientData, setFieldValue]);

    const handleDescriptionChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue === 'Other') {
            setShowFreeText(true);
            setFieldValue('descriptionOther', ''); // Use a different field for free text input
            setFieldValue('description', selectedValue); // Keep the value of the dropdown
        } else {
            setShowFreeText(false);
            setFieldValue('description', selectedValue);
            setFieldValue('descriptionOther', '');
        }
    };

    const handleAddendaToggle = () => {
        setShowAddenda(prevShowAddenda => !prevShowAddenda);
    };

    const sortedClientData = Array.isArray(clientData) && clientData.length > 0
        ? clientData.sort((a, b) => a.name.localeCompare(b.name)).map(client => ({
            label: ` ${client.name} - Account: ${client.ledgers.map(ledger => ledger.accounts.join(', ')).join(', ')}`,
            value: client.reference
        }))
        : [];

    return (
        <Grid container spacing={1}>
            <Grid item xs={8} mt={2}>
                <CustomStyledAutocomplete
                    name="sendingClient"
                    label="Sending Client"
                    options={sortedClientData}
                    disabled={fieldsDisabled}
                />
            </Grid>
            <Grid item xs={2} container justifyContent="flex-start" alignItems="center">
                <Tooltip title={"Create a new client"}>
                    <AddIcon onClick={handleAddClient} style={{cursor: 'pointer'}}/>
                </Tooltip>
            </Grid>
            {values.sendingClient && (
                <>
                    <Grid item xs={8}>
                        <CustomStyledAutocomplete
                            name="entityId"
                            label="Beneficiary Name"
                            options={beneficiaryOptions}
                            onValueChange={handleBeneficiaryChange}
                            disabled={fieldsDisabled}
                        />
                    </Grid>
                    <Grid item xs={2} container justifyContent="flex-start" alignItems="center">
                        <Tooltip title={"Create a new beneficiary"}>
                            <AddIcon onClick={handleAddBeneficiary} style={{cursor: 'pointer'}}/>
                        </Tooltip>
                        {values.entityId &&
                            <Tooltip title={"View beneficiary details"}>
                                <PermIdentity color='primary' onClick={handleOpenDetailsModal}
                                              style={{cursor: 'pointer', marginLeft: 8}}/>
                            </Tooltip>
                        }
                    </Grid>
                </>
            )}

            {values.sendingClient && showLedgerDropdown && (
                <Grid item xs={10}>
                    <CustomDropdown
                        name="ledgerId"
                        label="Ledger"
                        value={values.ledgerId}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        options={clientData.find(client => client.reference === values.sendingClient.value)?.ledgers.map(ledger => ({
                            label: `${ledger.currency} - ${ledger.accounts.length > 0 ? ledger.accounts.join(', ') : 'No accounts'}`,
                            value: ledger.reference
                        }))}
                        disabled={fieldsDisabled}
                    />
                </Grid>
            )}
            <Grid item xs={12} sm={4}>
                <CustomCurrencyValueInput
                    name="amount"
                    label="Amount"
                    value={values.amount}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    disabled={fieldsDisabled}
                    currency={displayedCurrency}
                />
            </Grid>
            <Grid item xs={8} sm={4}>
                <CustomDropdown
                    name="currency"
                    label="Currency"
                    value={displayedCurrency}
                    handleChange={handleCurrencyChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    options={currencyOptions}
                    disabled={fieldsDisabled}
                />
            </Grid>
            <Grid item xs={4} container alignItems="center">
                {!fieldsDisabled ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGetRate}
                        disabled={!(transactionType === 'fxCredit' && values.currency && values.amount && values.entityId)}
                    >
                        Get Rate
                    </Button>
                ) : (
                    <IconButton
                        onClick={handleResetRate}
                        color="primary"
                        size="small"
                        sx={{
                            backgroundColor: '#00B5E2',
                            color: '#FFF',
                            '&:hover': {backgroundColor: '#00A1D6'},
                        }}
                    >
                        <RefreshIcon/>
                    </IconButton>
                )}
            </Grid>

            {rateError && (
                <Grid item xs={12} sx={{color: 'error', mt: 1, ml: 2}}>
                    <Typography variant="h4" color={"error"}>
                        Hmm, something seems to have gone wrong. Please try again.
                    </Typography>
                </Grid>
            )}

            {rateLoading ? (
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <CircularSpinner/>
                </Grid>
            ) : (
                <>
                    {transactionType === 'fxCredit' && values.amount > 0.01 && rate && showRate && (
                        <Grid item xs={12} sm={8}>
                            <Typography
                                variant="body1"
                                component="div"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '8px',
                                    backgroundColor: '#f4f4f4',
                                    borderRadius: '8px',
                                    padding: '18px',
                                    color: '#012169',
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                }}
                            >
                                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                                    <span>Total in USD:</span>
                                    <span style={{fontWeight: 'bold', color: '#1E88E5'}}>
                                        {formattedTotalAmount}
                                    </span>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                                    <span>Rate:</span>
                                    <span style={{fontWeight: 'bold', color: '#1E88E5'}}>
                                        {rate.response.rate}
                                    </span>
                                </div>
                            </Typography>
                            {rateDisclaimer && (
                                <Typography variant="body2" color="textSecondary"
                                            sx={{fontStyle: 'italic', fontWeight: 'bold', fontSize: '0.875rem'}} my={1}
                                            ml={1}>
                                    {rateDisclaimer}
                                </Typography>
                            )}
                        </Grid>
                    )}
                </>
            )}

            <Grid item xs={8} container alignItems="center">
                <CustomFormField
                    name="reference"
                    label="Internal Tracking Reference"
                    value={values.reference}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                />
            </Grid>
            <Grid item xs={10} container alignItems="center" spacing={1}>
                {paymentDescriptions ? (
                    <>
                        <Grid item xs={11}>
                            <CustomDropdown
                                name="description"
                                label="Purpose"
                                value={values.description}
                                handleChange={handleDescriptionChange}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                                options={paymentDescriptions.map(desc => ({label: desc, value: desc}))}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title="Add optional Addenda">
                                <IconButton onClick={handleAddendaToggle} style={{cursor: 'pointer'}}>
                                    <AddIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        {showFreeText && (
                            <Grid item xs={12}>
                                <CustomFormField
                                    name="descriptionOther"
                                    label="Please specify"
                                    value={values.descriptionOther}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>
                        )}
                    </>
                ) : (
                    <>
                        <Grid item xs={11}>
                            <CustomFormField
                                name="description"
                                label="Purpose"
                                value={values.description}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                                maxLength={35}
                                validateChars={true}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title="Add optional Addenda">
                                <IconButton onClick={handleAddendaToggle} style={{cursor: 'pointer'}}>
                                    <AddIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </>
                )}
            </Grid>
            {showAddenda && (
                <Grid item xs={8}>
                    <CustomFormField
                        name="addenda"
                        label="Addenda"
                        value={values.addenda}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        maxLength={1024}
                    />
                </Grid>
            )}
            <Grid item xs={8}>
                <CustomFormField
                    name="transactionNote"
                    label="Transaction Note - Optional"
                    value={values.transactionNote}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    maxLength={500}
                />
            </Grid>
            {detailsModalOpen &&
                <BeneficiaryDetailsModal
                    open={detailsModalOpen}
                    handleClose={handleCloseDetailsModal}
                    entityDetails={entityDetails}
                    loading={!entityDetails}
                    error={null}
                />
            }
        </Grid>
    );
};

FormFields.propTypes = {
    handleAddClient: PropTypes.func.isRequired,
    handleAddBeneficiary: PropTypes.func.isRequired,
    transactionType: PropTypes.string.isRequired,
    displayedCurrency: PropTypes.string.isRequired,
    setDisplayedCurrency: PropTypes.func.isRequired,
    rate: PropTypes.object,
    currencyOptions: PropTypes.array.isRequired,
    handleFetchRate: PropTypes.func.isRequired
};

export default FormFields;