import PropTypes from 'prop-types';
// material-ui
import {useTheme} from '@mui/material/styles';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    Collapse,
    Divider,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import NotInterestedTwoToneIcon from '@mui/icons-material/NotInterestedTwoTone';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// project imports
import theme from "../../themes";
import {formatDateToMonthDayYearWithTime} from "../../store/getDate";
import OtpInput from "react18-input-otp";
import {getFormattedCurrencyAmountWithName} from "../../store/getFormattedCurrencyAmount";
import DataGridStatusChip from "../datagrid/DataGridStatusChip";
import {useState} from "react";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {keyframes} from '@mui/system';

const pulse = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
`;

// ==============================|| USER ApprovalView CARD ||============================== //
const sxDivider = {
    borderColor: theme.secondary,
};

const ApprovalDetailsCard = ({
                                 data,
                                 acceptOnClick,
                                 rejectOnClick,
                                 handleTotpChange,
                                 loading,
                                 totpCode,
                                 mainError,
                                 totpError
                             }) => {
    const theme = useTheme();
    const [showAll, setShowAll] = useState(false);

    // Function to toggle the visibility of the Pending Users
    const handleToggle = () => {
        setShowAll(!showAll);
    };

    if (!data || !data.details || !data.details.tx || !data.details.originator) {
        return (
            <div style={{position: 'fixed', bottom: 10, right: 10, zIndex: 1000}}>
                <Alert severity="error" variant="filled">
                    <AlertTitle>Error</AlertTitle>
                    An error has occurred — <strong>Please try again shortly or contact support</strong>
                </Alert>
            </div>
        );
    }

    const renderOtherApprovers = () => {
        const uniqueUserStates = data.userStates.filter(
            (value, index, self) => index === self.findIndex((t) => (
                t.user === value.user && t.email === value.email && t.state === value.state
            ))
        );

        if (data.overallState === 'pending' || data.overallState === 'abandoned') {
            return (
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Typography variant="h3" fontWeight={900} sx={{ mb: 1 }}>Other Approvers</Typography>
                        {uniqueUserStates.length > 3 && (
                            <Tooltip title={showAll ? "See less of the approver list" : "See all the approvers"}>
                                <IconButton
                                    onClick={handleToggle}
                                    sx={{
                                        animation: `${pulse} 5s infinite`
                                    }}
                                >
                                    {showAll ? <ExpandLess color="primary" fontSize="large" /> :
                                        <ExpandMore color="primary" fontSize="large" />}
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>
                    {uniqueUserStates.slice(0, 3).map((userState, index) => (
                        <div key={index}>
                            <Typography variant="h4" fontWeight={600} sx={{ color: theme.palette.grey[700], mb: -2 }}>
                                {userState.user ? userState.user : userState.email}: {userState.state}
                            </Typography>
                            {index < uniqueUserStates.length - 1 && <br />}
                        </div>
                    ))}

                    {/* Collapse component to show/hide additional approvers */}
                    <Collapse in={showAll}>
                        {uniqueUserStates.slice(3).map((userState, index) => (
                            <div key={index + 3}>
                                <Typography variant="h4" fontWeight={600} sx={{ color: theme.palette.grey[700], mb: -2 }}>
                                    {userState.user ? userState.user : userState.email}: {userState.state}
                                </Typography>
                                {index < uniqueUserStates.length - 4 && <br />}
                            </div>
                        ))}
                    </Collapse>
                </Grid>
            );
        } else if (data.overallState === 'accepted' || data.overallState === 'rejected') {
            const approvers = uniqueUserStates.filter(userState => userState.state === 'accepted' || userState.state === 'rejected');

            return (
                <Grid item xs={12}>
                    <Typography variant="h3" fontWeight={900} sx={{ mb: 1 }}>
                        {data.overallState.charAt(0).toUpperCase() + data.overallState.slice(1)} By
                    </Typography>
                    {approvers.map((approver, index) => (
                        <div key={index}>
                            <Typography variant="h4" fontWeight={600} sx={{ color: theme.palette.grey[700], mb: -2 }}>
                                {approver.user ? approver.user : approver.email}: {approver.state}
                            </Typography>
                            {index < approvers.length - 1 && <br />}
                        </div>
                    ))}
                </Grid>
            );
        }
    };

    const formattedExpiryDate = data?.expiry && formatDateToMonthDayYearWithTime(data?.expiry);
    const formattedCreatedDate = data?.details.tx.CreatedOn && formatDateToMonthDayYearWithTime(data?.details.tx.CreatedOn);
    const canApprove = data.canApprove || (data.details && data.details.canApprove);

    if (!loading) {
        return (
            <Card sx={{
                p: 2,
                background: theme.palette.background.paper,
                border: `1px solid ${theme.palette.grey[300]}`,
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
                '&:hover': {
                    borderColor: theme.palette.grey[500], boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)'
                }
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                        <Typography variant="h3" fontWeight={900} sx={{mb: 1}}>Approval State</Typography>
                        <Typography variant="h4" fontWeight={600} sx={{color: theme.palette.grey[700]}}>
                            <DataGridStatusChip state={data?.overallState}/> </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Typography variant="h3" fontWeight={900} sx={{mb: 1}}>
                            {data?.overallState === 'pending' || data?.overallState === 'expired'
                                ? 'Approval Expiry'
                                : 'Transaction Date'
                            }
                        </Typography>
                        <Typography variant="h4" fontWeight={600} sx={{color: theme.palette.grey[700]}}>
                            {data?.overallState === 'pending' || data?.overallState === 'expired'
                                ? formattedExpiryDate
                                : formattedCreatedDate
                            }
                        </Typography>
                    </Grid>
                    {data?.userStates && data.userStates.length > 0 && (
                        <Grid item xs={4}>
                            {renderOtherApprovers()}
                        </Grid>
                    )}


                    <Grid item xs={12}>
                        <Divider sx={{...sxDivider, mt: 1, mb: 1}}/>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Stack spacing={1}>
                            <Typography variant="h3" fontWeight={900} sx={{mb: 1}}>Payment Details</Typography>
                            <Stack spacing={1}>
                                <Box display="flex" alignItems="flex-end">
                                    <Typography variant="h4" fontWeight={600} sx={{mr: 1}}>
                                        Initiator:
                                    </Typography>
                                    <Typography variant="h5" fontWeight={900} sx={{color: theme.palette.grey[700]}}>
                                        {data.initiator?.name || data.initiator?.email || data.initiator?.reference}                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="flex-end">
                                    <Typography variant="h4" fontWeight={600} sx={{mr: 1}}>
                                        {data?.details.originator.name} sends:
                                    </Typography>
                                    <Typography variant="h5" fontWeight={900} sx={{color: theme.palette.grey[700]}}>
                                        {getFormattedCurrencyAmountWithName(data?.details.tx.Amount, data?.details.tx.Currency)}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="flex-end">
                                    <Typography variant="h4" fontWeight={600} sx={{mr: 1}}>
                                        {data?.details.entityName} receives:
                                    </Typography>
                                    <Typography variant="h5" fontWeight={900} sx={{color: theme.palette.grey[700]}}>
                                        {(data?.details.trade?.BuyAmount && data?.details.trade?.BuyCurrency) ?
                                            getFormattedCurrencyAmountWithName(data.details.trade.BuyAmount, data.details.trade.BuyCurrency) :
                                            getFormattedCurrencyAmountWithName(data?.details.tx.Amount, data?.details.tx.Currency)}
                                    </Typography>
                                </Box>
                                {data?.details && data.details.rateInfo && data.details.rateInfo.clientRate !== null && (
                                    <Box display="flex" alignItems="flex-end">
                                        <Typography variant="h4" fontWeight={600} sx={{mr: 1}}>
                                            FX Rate:
                                        </Typography>
                                        <Typography variant="h5" fontWeight={900} sx={{color: theme.palette.grey[700]}}>
                                            {data.details.rateInfo.clientRate}
                                        </Typography>
                                    </Box>
                                )}
                                <Box display="flex" alignItems="flex-end">
                                    <Typography variant="h4" fontWeight={600} sx={{mr: 1}}>
                                        Reference:
                                    </Typography>
                                    <Typography variant="h5" fontWeight={900} sx={{color: theme.palette.grey[700]}}>
                                        {data?.details.tx.ClientReference}
                                    </Typography>
                                </Box>
                                {data?.details.tx.Meta && data?.details.tx.Meta.description && (
                                    <Box display="flex" alignItems="flex-end">
                                        <Typography variant="h4" fontWeight={600} sx={{mr: 1}}>
                                            Purpose:
                                        </Typography>
                                        <Typography variant="h5" fontWeight={900} sx={{color: theme.palette.grey[700]}}>
                                            {data?.details?.tx?.Meta?.description}
                                        </Typography>
                                    </Box>
                                )}
                                {data?.details.tx.Meta && data?.details.tx.Meta.addenda && (
                                    <Box display="flex" alignItems="flex-end">
                                        <Typography variant="h4" fontWeight={600} sx={{mr: 1}}>
                                            Addenda:
                                        </Typography>
                                        <Typography variant="h5" fontWeight={900} sx={{color: theme.palette.grey[700]}}>
                                            {data?.details?.tx?.Meta?.addenda}
                                        </Typography>
                                    </Box>
                                )}
                                {data?.details.tx.notes && data?.details.tx.notes.length > 0 && (
                                    <Box display="flex" alignItems="flex-end">
                                        <Grid item><Typography variant="h4" fontWeight={600}
                                                               sx={{mr: 1}}>Notes:</Typography></Grid>
                                        <Grid item>
                                            {data?.details.tx.notes.map(({Note}) =>
                                                <Typography variant="h5" fontWeight={900}
                                                            sx={{color: theme.palette.grey[700]}}>{Note}</Typography>)}
                                        </Grid>
                                    </Box>
                                )}
                            </Stack>
                        </Stack>
                    </Grid>
                    {data && data.details.screening && (
                        <Grid item xs={12} md={3}>
                            <Box display="flex" justifyContent="flex-end" alignItems="center" height="100%">
                                <Button variant="contained"
                                        color="secondary"
                                        onClick={() => window.open(data.details.screening.url, '_blank')}
                                >
                                    Transaction Screening
                                </Button>
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Divider sx={{...sxDivider, mt: 3, mb: 1}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <Stack>
                                    <Typography variant="h4" fontWeight={900}>Recipient Details</Typography>
                                    <Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle1">Name:</Typography>
                                            <Typography variant="body2">{data?.details.entityName}</Typography>
                                        </Stack>
                                        {data?.details.entityInfo.account && (
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography variant="subtitle1">Account:</Typography>
                                                <Typography
                                                    variant="body2">{data?.details.entityInfo.account}</Typography>
                                            </Stack>
                                        )}
                                        {data?.details.entityInfo.iban && (
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography variant="subtitle1">IBAN:</Typography>
                                                <Typography variant="body2">{data?.details.entityInfo.iban}</Typography>
                                            </Stack>
                                        )}
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle1">Address:</Typography>
                                            <Typography
                                                variant="body2">{data?.details.entityInfo.address.street1}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle1">City:</Typography>
                                            <Typography
                                                variant="body2">{data?.details.entityInfo.address.city}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle1">Country:</Typography>
                                            <Typography
                                                variant="body2">{data?.details.entityInfo.address.country}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle1">State:</Typography>
                                            <Typography
                                                variant="body2">{data?.details.entityInfo.address.regionCode}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle1">Zip code:</Typography>
                                            <Typography
                                                variant="body2">{data?.details.entityInfo.address.postalCode}</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack> </Grid>
                            <Grid item sm={6}>
                                <Stack>
                                    <Typography variant="h4" fontWeight={900}>Originator Details</Typography>
                                    <Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle1">Name:</Typography>
                                            <Typography variant="body2">{data?.details.originator.name}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle1">Account:</Typography>
                                            <Typography variant="body2">{data?.details.originator.account}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle1">Address:</Typography>
                                            <Typography
                                                variant="body2">{data?.details.originator.address.street1}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle1">City:</Typography>
                                            <Typography
                                                variant="body2">{data?.details.originator.address.city}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle1">Country:</Typography>
                                            <Typography
                                                variant="body2">{data?.details.originator.address.country}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle1">State:</Typography>
                                            <Typography
                                                variant="body2">{data?.details.originator.address.regionCode}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="subtitle1">Zip code:</Typography>
                                            <Typography
                                                variant="body2">{data?.details.originator.address.postalCode}</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Divider sx={{...sxDivider, mt: 3, mb: 1}}/>
                    </Grid>
                    <Grid item xs={12}>
                        {canApprove && data.attemptState && data.overallState && data.attemptState.toLowerCase() === "pending" && data.overallState.toLowerCase() === "pending" && (
                            <>
                                {(mainError || totpError) &&
                                    <Grid item xs={12} sx={{mb: 2}}>
                                        <Typography color={"error"} variant={"h5"}
                                                    sx={{pl: 1}}> {mainError} </Typography>
                                        <Typography color={"error"} variant={"h5"}
                                                    sx={{pl: 1}}> {totpError} </Typography>
                                    </Grid>
                                }

                                {data.totpRequired && (
                                    <Grid item xs={12} sx={{mb: 2}}>
                                        <Typography variant={"h4"} sx={{ml: 1}}>
                                            Enter 6-digit authentication code:
                                        </Typography>
                                        <OtpInput
                                            value={totpCode}
                                            onChange={handleTotpChange}
                                            numInputs={6}
                                            containerStyle={{justifyContent: 'space-between'}}
                                            inputStyle={{
                                                width: '100%',
                                                margin: '8px',
                                                padding: '10px',
                                                border: `1px solid `,
                                                borderRadius: 4,
                                                ':hover': {
                                                    borderColor: "#000"
                                                }
                                            }}
                                            focusStyle={{
                                                outline: 'none',
                                                border: `2px solid #000`
                                            }}
                                            shouldAutoFocus={true}
                                        />
                                    </Grid>
                                )}
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            fullWidth size="large"
                                            sx={{
                                                py: 2, borderRadius: '15px',
                                            }}
                                            startIcon={<CheckCircleOutlineIcon/>}
                                            onClick={acceptOnClick}
                                            disabled={data.totpRequired ? totpCode.length !== 6 : false}
                                        >
                                            Approve
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            fullWidth size="large"
                                            sx={{
                                                py: 2, borderRadius: '15px',
                                            }}
                                            startIcon={<NotInterestedTwoToneIcon/>}
                                            onClick={rejectOnClick}
                                            disabled={data.totpRequired ? totpCode.length !== 6 : false}
                                        >
                                            Decline
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Card>
        )
    }
};

ApprovalDetailsCard.propTypes = {
    data: PropTypes.shape({
        expiry: PropTypes.string,
        details: PropTypes.shape({
            tx: PropTypes.shape({
                Id: PropTypes.number,
                Type: PropTypes.string,
                When: PropTypes.string,
                State: PropTypes.string,
                notes: PropTypes.arrayOf(PropTypes.shape({
                    Id: PropTypes.number,
                    Note: PropTypes.string,
                    CreatedOn: PropTypes.string,
                    Reference: PropTypes.string,
                    UpdatedOn: PropTypes.any,
                    TransactionId: PropTypes.number,
                })),
                Amount: PropTypes.number,
                Account: PropTypes.number,
                Addenda: PropTypes.any,
                ClientId: PropTypes.number,
                Currency: PropTypes.string,
                EntityId: PropTypes.number,
                LedgerId: PropTypes.number,
                ClearedOn: PropTypes.any,
                CreatedOn: PropTypes.string,
                Reference: PropTypes.string,
                FinishedOn: PropTypes.any,
                Identifier: PropTypes.any,
                Description: PropTypes.string,
                ProcessedOn: PropTypes.any,
                ContraLedgerId: PropTypes.any,
                ClientReference: PropTypes.string,
                LastNotificationId: PropTypes.number
            }),
            rateInfo: PropTypes.shape({
                expiry: PropTypes.string,
                rateId: PropTypes.string,
                baseRate: PropTypes.number,
                currency: PropTypes.string,
                clientRate: PropTypes.number,
                inverseClientRate: PropTypes.number
            }),
            entityInfo: PropTypes.shape({
                bic: PropTypes.string,
                account: PropTypes.string,
                address: PropTypes.shape({
                    city: PropTypes.string,
                    country: PropTypes.string,
                    street1: PropTypes.string,
                    postalCode: PropTypes.string,
                    regionCode: PropTypes.string
                })
            }),
            trade: PropTypes.shape({
                BuyAmount: PropTypes.number,
                BuyCurrency: PropTypes.string,
            }),
            initiator: PropTypes.shape({
                name: PropTypes.string,
                email: PropTypes.string,
                reference: PropTypes.string,
            }),

            entityName: PropTypes.string,
            instructedAmount: PropTypes.number,
            instructedCurrency: PropTypes.string,
            originator: PropTypes.shape({
                bid: PropTypes.string,
                name: PropTypes.string,
                account: PropTypes.number || PropTypes.string,
                iban: PropTypes.string,
                address: PropTypes.shape({
                    city: PropTypes.string,
                    country: PropTypes.string,
                    street1: PropTypes.string,
                    postalCode: PropTypes.string,
                    regionCode: PropTypes.string
                })
            }),
        }),
        userStates: PropTypes.array,
        attemptState: PropTypes.string,
        overallState: PropTypes.string,
        totpRequired: PropTypes.bool,
        clientReference: PropTypes.string,
    }),
    acceptOnClick: PropTypes.func,
    rejectOnClick: PropTypes.func,
    mainError: PropTypes.string,
    totpError: PropTypes.string
};


export default ApprovalDetailsCard;