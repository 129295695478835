import React, {useCallback, useContext, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Form, useFormikContext} from "formik";
import {Button, Card, CardContent, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {useAuthHeaders} from "../../../auth/AuthHeaders";
import ConfigContext from "../../../store/context/ConfigContext";
import useFetchRate from "../../../store/hooks/useFetchRate";
import {getCurrencies} from "../../../store/hooks/useConfiguredCurrencies";
import BankConfirmationModal from "../../../ui-component/extended/modal/BankConfirmationModal";
import FormFields from "./BankTransactionFormFields";

//-------------------------------------|| BankTransactionForm || --------------------------------------//
const BankTransactionForm = ({
                                 clientData,
                                 handleAddClient,
                                 handleAddBeneficiary,
                                 transactionType,
                                 setTransactionType,
                                 showCreateBeneficiary,
                             }) => {
    const {values, setFieldValue, isValid, isSubmitting, submitForm, validateForm} = useFormikContext();
    const [displayedCurrency, setDisplayedCurrency] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const authHeaders = useAuthHeaders();
    const config = useContext(ConfigContext);
    const {
        fetchRate,
        apiResponse,
        error: rateError,
        isLoading: rateLoading
    } = useFetchRate(config, authHeaders, values.sendingClient?.value, values.entityId?.value || '');

    const currencyOptions = getCurrencies();

    const handleFetchRate = useCallback(() => {
        if (transactionType === 'fxCredit' && values.currency && values.amount && values.entityId) {
            fetchRate(values.currency, 'buyAmount', values.amount, values.ledgerId);
        }
    }, [transactionType, values.currency, values.amount, values.entityId, values.ledgerId, fetchRate]);

    useEffect(() => {
        if (apiResponse) {
            setFieldValue('fxQuoteId', apiResponse.response.id);
            setShowConfirmationModal(false)
        }
    }, [apiResponse, setFieldValue]);

    // Validate form on mount
    useEffect(() => {
        validateForm();
    }, [validateForm]);

    const transaction = {
        sellAmount: apiResponse?.response?.sellAmount,
        buyAmount: apiResponse?.response?.buyAmount,
        currency: values.currency,
        rate: apiResponse?.response?.rate || '',
        inverseRate: apiResponse?.response?.inverse || '',
        receiverDetails: values.entityId || '',
        fxQuoteId: apiResponse?.response.id || '',
        sendingClient: values.sendingClient || '',
    };

    const handleModalConfirm = useCallback(() => {
        submitForm();
    }, [submitForm]);

    return (
        <Card elevation={3}>
            <CardContent>
                <Form style={{width: '100%', maxWidth: '800px'}}>
                    <Typography variant="h3">
                        Bank Transaction
                    </Typography>
                    <ToggleButtonGroup
                        value={transactionType}
                        exclusive
                        onChange={(event, newTransactionType) => {
                            if (newTransactionType !== null) {
                                setTransactionType(newTransactionType);
                            }
                        }}
                    >
                        {/*    <ToggleButton value="domestic">ACH Transaction</ToggleButton>*/}
                        <ToggleButton value="fxCredit">Cross-border Transaction</ToggleButton>
                    </ToggleButtonGroup>
                    <FormFields
                        clientData={clientData}
                        handleAddClient={handleAddClient}
                        handleAddBeneficiary={handleAddBeneficiary}
                        transactionType={transactionType}
                        displayedCurrency={displayedCurrency}
                        setDisplayedCurrency={setDisplayedCurrency}
                        rate={apiResponse}
                        currencyOptions={currencyOptions}
                        handleFetchRate={handleFetchRate}
                        rateError={rateError}
                        showCreateBeneficiary={showCreateBeneficiary}
                        rateLoading={rateLoading}
                    />

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setShowConfirmationModal(true)}
                        disabled={isSubmitting || !isValid || !apiResponse}
                    >
                        Submit
                    </Button>

                    {apiResponse && showConfirmationModal &&
                        <BankConfirmationModal
                            open={showConfirmationModal}
                            onConfirm={handleModalConfirm}
                            onCancel={() => setShowConfirmationModal(false)}
                            transaction={transaction}
                            displayedCurrency={displayedCurrency}
                            onTOTPChange={(value) => setFieldValue('totp', value)}
                        />
                    }
                </Form>
            </CardContent>
        </Card>
    );
};

BankTransactionForm.propTypes = {
    handleAddClient: PropTypes.func.isRequired,
    handleAddBeneficiary: PropTypes.func.isRequired,
    transactionType: PropTypes.string.isRequired,
    setTransactionType: PropTypes.func.isRequired,
};

export default BankTransactionForm;
