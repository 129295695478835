import React, {useContext} from 'react';
import MainCard from "../ui-component/cards/MainCard";
import InviteForm from "../requests/InviteForm";
import {Grid, Typography} from "@mui/material";
import {RolesContext} from "../store/context/RolesContext";
import {useNavigate} from "react-router-dom";

// ============================|| Invite Component ||============================ //
const Invite = () => {
    const clientAccount = sessionStorage.getItem('clientName')
    const {roles} = useContext(RolesContext)
    const navigate = useNavigate();

    if( !roles.includes("invite")){
        navigate("/");
    }

    return (
        <MainCard
            title={
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h3">Invite a user to {clientAccount}</Typography>
                    </Grid>
                </Grid>
            }
            content={true}
        >
            <InviteForm/>
        </MainCard>
    );
};

export default Invite;