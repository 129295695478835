import { updateVisaB2BSchema, getVisaB2BSchema } from "./Visab2bValidationSchema";
import { getFormFields, getPostalCodeField } from "./getVisab2bFormFields";

const euCountriesUsingEuro = ['AT', 'BE', 'HR', 'CY', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SI', 'ES'];

export const handleCountryChangeEvent = ({
                                             values,
                                             setFieldValue,
                                             localeData,
                                             setPostalCodeField,
                                             event,
                                             handleCountryChange,
                                             ibanSupportedCountries,
                                             onSchemaUpdate
                                         }) => {
    if (typeof handleCountryChange === 'function') {
        handleCountryChange(event);
    }

    const countryCode = event.target.value;
    const countryData = localeData[countryCode];

    if (countryCode && countryData) {
        const postalCodeField = getPostalCodeField(countryData);
        setPostalCodeField(postalCodeField);
        setFieldValue('postalCode', '');
    }

    const bicCountryCode = values.bic ? values.bic.substring(4, 6).toUpperCase() : null;
    updateVisaB2BSchema(countryCode, bicCountryCode, [...ibanSupportedCountries], localeData);
    onSchemaUpdate(getVisaB2BSchema());
};

export const handleBicChangeEvent = ({
                                         values,
                                         setFieldValue,
                                         localeData,
                                         ibanSupportedCountries,
                                         currencyOptions,
                                         setFormFields,
                                         setSupportsIban,
                                         setSupportsAccount,
                                         onSchemaUpdate,
                                         handleCountryChange,
                                         setPostalCodeField
                                     }) => {
    const bic = values.bic;
    if (bic && bic.length >= 6) {
        const countryCode = bic.substring(4, 6).toUpperCase();
        const countryData = localeData[countryCode];
        if (countryCode && countryData) {
            setFormFields(getFormFields(countryData));

            const supportsIban = [...ibanSupportedCountries].some(item => item.country === countryCode);
            const supportsAccount = !supportsIban;

            setSupportsIban(supportsIban);
            setSupportsAccount(supportsAccount);

            if (supportsIban) {
                setFieldValue('paymentType', 'iban');
            } else {
                setFieldValue('paymentType', 'account');
            }

            const filteredCurrencyOptions = currencyOptions.filter(option => option.country === countryCode);
            const preferredCurrency = filteredCurrencyOptions.length > 0
                ? filteredCurrencyOptions[0].value
                : euCountriesUsingEuro.includes(countryCode)
                    ? 'EUR'
                    : 'None';

            setFieldValue('currency', preferredCurrency);
            setFieldValue('country', countryCode);

            const event = {
                target: {
                    value: countryCode
                }
            };

            handleCountryChangeEvent({
                values,
                setFieldValue,
                localeData,
                setPostalCodeField,
                event,
                handleCountryChange,
                ibanSupportedCountries,
                onSchemaUpdate
            });
        }
    }

    const currentCountryCode = values.country;
    const bicCountryCode = bic ? bic.substring(4, 6).toUpperCase() : null;
    updateVisaB2BSchema(currentCountryCode, bicCountryCode, [...ibanSupportedCountries], localeData);
    onSchemaUpdate(getVisaB2BSchema());
};