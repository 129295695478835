import React, {useContext, useState} from 'react';
import {Box, Button, IconButton, Modal, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {ResponseDisplay} from "../../../../requests/response/ResponseDisplay";
import {Form, Formik} from "formik";
import axios from "axios";
import * as Yup from "yup";
import {useAuthHeaders} from "../../../../auth/AuthHeaders";
import CreateClientFields from "./CreateClientFields";
import ConfigContext from "../../../../store/context/ConfigContext";
import {toast} from "react-toastify";

//-------------------------------------|| Create Client Modal || --------------------------------------//
const CreateClientModal = ({open, handleClose}) => {
    const [response, setResponse] = useState(null);
    const authHeaders = useAuthHeaders();
    const clientId = sessionStorage.getItem('clientId');
    const config = useContext(ConfigContext);
    const apiUrl = config.API_URL;

    const handleCloseModal = () => {
        setResponse(null);
        handleClose();
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        street1: Yup.string().required('Street 1 is required'),
        city: Yup.string().required('City is required'),
        country: Yup.string().required('Country is required'),
        stateProvince: Yup.string().required('State/Province is required'),
        postalZipCode: Yup.string().required('Postal/Zip Code is required'),
        accountNumber: Yup.string().required('Account Number is required'),
    });

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            const apiResponse = await axios.post(
                `${apiUrl}/bank/v1/client`,
                {
                    bankId: clientId,
                    totp: values.totp,
                    account: values.accountNumber,
                    currency: "USD",
                    name: values.name,
                    address: {
                        street1: values.street1,
                        city: values.city,
                        country: values.country,
                        regionCode: values.stateProvince,
                        postalCode: values.postalZipCode,
                    },
                },
                {
                    headers: authHeaders,
                }
            );

            if (apiResponse.data && !apiResponse.data.error) {
                setResponse(apiResponse.data);
                toast.success("Success! Client was added.", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    theme: "colored",
                });
                handleCloseModal();
            } else {
                setErrors({ api: apiResponse.data.error.message || 'An error occurred' });
                setResponse(apiResponse.data);
            }

        } catch (error) {
            console.error('Failed to create client:', error);
            if (error.response && error.response.data) {
                setResponse(error.response.data);
                setErrors({ api: error.response.data.error.message || 'An error occurred' });
            } else {
                setResponse({ error: { message: 'An unknown error occurred' } });
                setErrors({ api: 'An unknown error occurred' });
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Modal open={open} onClose={handleCloseModal}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '95vw', md: '85vw', lg: '65vw' },
                    maxWidth: '95vw',
                    maxHeight: '90vh',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    borderRadius: 8,
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                }}
            >
                <IconButton
                    onClick={handleCloseModal}
                    aria-label="close"
                    size="small"
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                    }}
                >
                    <CloseIcon/>
                </IconButton>

                <Typography variant="h2" sx={{mb: 2}}>
                    Create the Sending Client
                </Typography>

                <Formik
                    initialValues={{
                        name: '',
                        street1: '',
                        street2: '',
                        city: '',
                        country: 'US',
                        stateProvince: '',
                        postalZipCode: '',
                        accountNumber: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({values, handleChange, handleBlur, touched, errors}) => (
                        <Form>
                            <CreateClientFields
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                            <Button type="submit" variant="contained" color="primary" disabled={!values.totp}
                                    sx={{mt: 2}}>
                                Submit
                            </Button>
                        </Form>
                    )}
                </Formik>

                {response && (
                    <Box sx={{mt: 2}}>
                        <ResponseDisplay response={response} typeOfRequest={"Client Creation"}/>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default CreateClientModal;