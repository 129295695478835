import React from 'react';
import PropTypes from 'prop-types';
// materialUI imports
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';

/// ============================|| Basic Modal ||============================ //
const BasicModal = ({open, onClose, onConfirm, content, title}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" variant="contained">Cancel</Button>
                <Button onClick={onConfirm} color="secondary" variant="contained">Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};

BasicModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    content: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
};

export default BasicModal;