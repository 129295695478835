import {Grid, IconButton, ToggleButton, ToggleButtonGroup} from "@mui/material";
import CustomFormField from "../form-fields/CustomFormField";
import React, {useEffect, useState} from "react";
import AddIcon from "@mui/icons-material/Add";

// ============================|| Wire - Form ||============================ //
export const WireForm = ({values, handleChange, handleBlur, touched, errors, submitCount, setErrors}) => {
    const [showAddressLines, setShowAddressLines] = useState({
        line2: false,
        line3: false,
    });
    const [showBankAddressLines, setShowBankAddressLines] = useState({
        line2: false,
        line3: false,
    });

    const handleAddBankAddressLine = (line) => {
        setShowBankAddressLines(prev => ({
            ...prev,
            [line]: true
        }));
    };

    const handleAddAddressLine = (line) => {
        setShowAddressLines(prev => ({
            ...prev,
            [line]: true
        }));
    };

    const isMaxedOut = (value, maxLength) => value.length === maxLength;

    if (!values.paymentType) {
        handleChange({target: {name: 'paymentType', value: 'iban'}});
    }

    useEffect(() => {
        if (values.paymentType === 'iban') {
            setErrors(prevErrors => ({
                ...prevErrors,
                account: undefined
            }));
        } else if (values.paymentType === 'account') {
            setErrors(prevErrors => ({
                ...prevErrors,
                iban: undefined
            }));
        }
    }, [values.paymentType, setErrors]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center" justifyContent="flex-start">
                    <Grid item xs={2}>
                        <ToggleButtonGroup
                            exclusive
                            name="paymentType"
                            value={values.paymentType}
                            onChange={(_, newValue) => handleChange({target: {name: 'paymentType', value: newValue}})}
                            aria-label="Payment Type"
                        >
                            <ToggleButton value="iban" aria-label="IBAN">
                                IBAN
                            </ToggleButton>
                            <ToggleButton value="account" aria-label="Account">
                                Account
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {submitCount > 0 && errors.paymentType && (
                            <div style={{
                                color: 'rgb(244, 67, 54)',
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                                fontFamily: '"Open Sans", sans-serif',
                                fontSize: '12px',
                                marginTop: '8px',
                                textAlign: 'center'
                            }}>
                                {errors.paymentType}
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}  sx={{ml: { md: 2 }}}>
                        <CustomFormField
                            name={values.paymentType === 'iban' ? 'iban' : 'account'}
                            label={values.paymentType === 'iban' ? 'IBAN' : 'Account'}
                            value={values.paymentType === 'iban' ? values.iban : values.account}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <CustomFormField
                    name="addressLine1"
                    label="Address Line 1"
                    value={values.addressLine1}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    maxLength={35}
                    endAdornment={(
                        <IconButton
                            onClick={() => handleAddAddressLine('line2')}
                            disabled={showAddressLines.line2}
                            aria-label="Add address line 2"
                        >
                            <AddIcon/>
                        </IconButton>
                    )}
                />
                {isMaxedOut(values.addressLine1, 35) && !showAddressLines.line2 && handleAddAddressLine('line2')}
            </Grid>
            {showAddressLines.line2 && (
                <Grid item xs={12} sm={4}>
                    <CustomFormField
                        name="addressLine2"
                        label="Address Line 2"
                        value={values.addressLine2}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        maxLength={35}
                        endAdornment={(
                            <IconButton
                                onClick={() => handleAddAddressLine('line3')}
                                disabled={showAddressLines.line3}
                                aria-label="Add address line 3"
                            >
                                <AddIcon/>
                            </IconButton>
                        )}
                    />
                    {isMaxedOut(values.addressLine2, 35) && !showAddressLines.line3 && handleAddAddressLine('line3')}
                </Grid>
            )}
            {showAddressLines.line3 && (
                <Grid item xs={12} sm={4}>
                    <CustomFormField
                        name="addressLine3"
                        label="Address Line 3"
                        value={values.addressLine3}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        maxLength={35}
                    />
                </Grid>
            )}

            <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center" justifyContent="flex-start">
                    <Grid item xs={4}>
                        <CustomFormField
                            name="bankName"
                            label="Bank Name"
                            value={values.bankName}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CustomFormField
                            name="swift"
                            label="SWIFT Code"
                            value={values.swift}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <CustomFormField
                    name="bankAddressLine1"
                    label="Bank Address Line 1"
                    value={values.bankAddressLine1}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    maxLength={35}
                    endAdornment={(
                        <IconButton
                            onClick={() => handleAddBankAddressLine('line2')}
                            disabled={showBankAddressLines.line2}
                            aria-label="Add bank address line 2"
                        >
                            <AddIcon/>
                        </IconButton>
                    )}
                />
                {isMaxedOut(values.bankAddressLine1, 35) && !showBankAddressLines.line2 && handleAddBankAddressLine('line2')}
            </Grid>
            {showBankAddressLines.line2 && (
                <Grid item xs={12} sm={4}>
                    <CustomFormField
                        name="bankAddressLine2"
                        label="Bank Address Line 2"
                        value={values.bankAddressLine2}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        maxLength={35}
                        endAdornment={(
                            <IconButton
                                onClick={() => handleAddBankAddressLine('line3')}
                                disabled={showBankAddressLines.line3}
                                aria-label="Add bank address line 3"
                            >
                                <AddIcon/>
                            </IconButton>
                        )}
                    />
                    {isMaxedOut(values.bankAddressLine2, 35) && !showBankAddressLines.line3 && handleAddBankAddressLine('line3')}
                </Grid>
            )}
            {showBankAddressLines.line3 && (
                <Grid item xs={12} sm={4}>
                    <CustomFormField
                        name="bankAddressLine3"
                        label="Bank Address Line 3"
                        value={values.bankAddressLine3}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        maxLength={35}
                    />
                </Grid>
            )}
        </Grid>
    );
};