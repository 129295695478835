// Project imports
import makeArrayBuffer from './makeArrayBuffer'
import makePDFLibDocument from './makePDFLibDocument'
import updatePDFLibDocument from './updatePDFLibDocument'
import visab2bTemplateOptions from './templates/visab2bTemplateOptions'
import visab2bTemplateOptionsBank from "./templates/visab2bTemplateOptionsBankTransaction";

// ============================|| PDF GENERATOR ||============================ //

const pdfGeneratorHandler = async (templateData, pdfTemplate, fileNamePrefix) => {
    // 1 - fetch pdf and convert to arraybuffer
    const arrayBuffer = await makeArrayBuffer(pdfTemplate)
    // 2 - use arrayBuffer to create a PDF-lib object
    let pdfLibDoc = await makePDFLibDocument(arrayBuffer)
    // 3 - update the pdf-lib document with templateData and return an array buffer
    pdfLibDoc =  await updatePDFLibDocument(pdfLibDoc, templateData)

    return { arrayBuffer: pdfLibDoc, fileNamePrefix }
}

const pdfGenerator = (data) => {

    const templateName = sessionStorage.getItem('pdfTemplate');

    const transactionData = data.credit;
    const details = transactionData.receiver.details
    // Determine what PDF Template to use:
    let detailType

    if (details.ach) {
        detailType = "ach";
    } else if (data.credit.bank) {
        detailType = "visab2bBank";
    }
    else if (details.visab2b) {
        detailType = "visab2b";
    }


    switch (detailType) {
        case "visab2b":
            const visab2bOptions = visab2bTemplateOptions(transactionData, templateName);
            return pdfGeneratorHandler(visab2bOptions.templateData, visab2bOptions.pdfTemplate, visab2bOptions.fileNamePrefix);
        case "visab2bBank":
            const visab2bBankOptions = visab2bTemplateOptionsBank(transactionData, templateName);
            return pdfGeneratorHandler(visab2bBankOptions.templateData, visab2bBankOptions.pdfTemplate, visab2bBankOptions.fileNamePrefix);
    }
}

export default pdfGenerator