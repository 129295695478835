import {useContext, useEffect, useState} from 'react';

// 3rd party imports
import axios from 'axios';

// project imports
import ConfigContext from "../context/ConfigContext";
import {useAuthHeaders} from "../../auth/AuthHeaders";

// ============================|| Get Ledgers Data Hook   ||============================ //
const useLedgers = () => {
    const [ledgersData, setLedgersData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeLedgers, setActiveLedgers] = useState([]);
    const authHeaders = useAuthHeaders();
    const clientId = sessionStorage.getItem('clientId');
    const config = useContext(ConfigContext);

    useEffect(() => {
        const fetchLedgers = async () => {
            try {
                const response = await axios.post(
                    `${config.API_URL}/figaro/v1/ledgers`,
                    { clientId },
                    { headers: authHeaders }
                );
                if (response.data && response.data.error && response.data.error.code === '401') {
                    sessionStorage.clear();
                    window.location.href = '/permission-denied';
                } else {
                    // Filter ledgers for state - active
                    setError(null);
                    const activeLedgers = response.data.ledgers.filter((ledger) => ledger.state === 'ready');
                    setActiveLedgers(activeLedgers);
                    setLedgersData(response.data.ledgers);
                }
            } catch (error) {
                console.error('Error fetching ledgers details:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchLedgers();
    }, [clientId]);

    return { ledgersData, activeLedgers, loading, error, clientId };
};

export default useLedgers;

useLedgers.propTypes = {};