import * as Yup from "yup";

// ============================|| Form Data - Validation Schemas ||============================ //
export const ACHSchema = Yup.object().shape({
    InfoType: Yup.string().required('Network Type is required'),
    type: Yup.string().required('Entity Type is required'),
    entityId: Yup.string().required('Entity Reference is required'),
    name: Yup.string().required('Name is required').max(22,'Name must be 22 characters or less'),
    achType: Yup.string().required('ACH Type is required'),
    aba: Yup.string()
        .required('ABA is required')
        .length(9, 'ABA must be exactly 9 digits')
        .matches(/^\d{9}$/, 'ABA must contain only digits')
        .test(
            'aba-checksum',
            'Invalid ABA Number',
            value => {
                if (!value) return false;
                const weights = [3, 7, 1];
                let sum = 0;
                for (let i = 0; i < 9; i++) {
                    sum += parseInt(value[i], 10) * weights[i % 3];
                }
                return sum % 10 === 0;
            }
        ),
    account: Yup.string().required('Account is required'),
    description: Yup.string().required('Description is required'),
    shortName: Yup.string()
        .test(
            'shortName-conditional',
            'Short name is required due to name length or special characters.',
            function (value) {
                const { name } = this.parent;
                const needsShortName = name && (name.length > 16 || /[^\x00-\x7F]+/.test(name));
                return !(needsShortName && !value);
            }
        )
        .max(16, 'Short name must be 16 characters or less')
});

