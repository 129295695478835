import React, {useContext, useState} from 'react';
import MainCard from "../ui-component/cards/MainCard";
import RolesDataGrid from "../ui-component/roles-table/RolesDataGrid";
import RoleSelectionModal from "../ui-component/roles-table/RolesSelectionModal";
import UserRolesSettingsModal from 'ui-component/roles-table/UserRolesSettingsModal';
import {RolesContext} from "../store/context/RolesContext";
import {useNavigate} from "react-router-dom";

// ============================|| USER TABLE DISPLAY ||============================ //
const RolesList = () => {
    const [isGrantModalOpen, setIsGrantModalOpen] = useState(false);
    const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const {roles} = useContext(RolesContext)
    const navigate = useNavigate();

    if (!roles.includes("grant") && !roles.includes("revoke")) {
        navigate("/");
    }

    const handleGrantClick = (userData) => {
        setSelectedUser(userData);
        setIsGrantModalOpen(true);
    };
    const handleRevokeClick = (userData) => {
        setSelectedUser(userData);
        setIsRevokeModalOpen(true);
    };
    const handleSettingsClick = (userData) => {
        setSelectedUser(userData);
        setIsSettingsModalOpen(true);
    };
    const handleGrantCloseModal = () => {
        setIsGrantModalOpen(false);
        setSelectedUser(null);
    };
    const handleRevokeCloseModal = () => {
        setIsRevokeModalOpen(false);
        setSelectedUser(null);
    };
    const handleSettingsCloseModal = () => {
        setIsSettingsModalOpen(false);
        setSelectedUser(null);
    };
    return (
        <MainCard
            title={
                ""
            }
            content={false}
        >
            <RolesDataGrid onEditClick={handleGrantClick} onRevokeClick={handleRevokeClick} onSettingsClick={handleSettingsClick}/>
            {isGrantModalOpen && (
                <RoleSelectionModal
                    open={isGrantModalOpen}
                    onClose={handleGrantCloseModal}
                    user={selectedUser}
                    actionType={"grant"}
                />
            )}
            {isRevokeModalOpen && (
                <RoleSelectionModal
                    open={isRevokeModalOpen}
                    onClose={handleRevokeCloseModal}
                    user={selectedUser}
                    actionType={"revoke"}
                />
            )}
            {isSettingsModalOpen && (
                <UserRolesSettingsModal
                    open={isSettingsModalOpen}
                    onClose={handleSettingsCloseModal}
                    user={selectedUser}
                />
            )}
        </MainCard>
    );
};

export default RolesList;