import React, {useState} from 'react';
// materialUI imports
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';

// project imports
import {renderBankDetails} from "../../../store/renderBankDetails";
import {getFormattedCurrencyAmountWithName} from "../../../store/getFormattedCurrencyAmount";

// 3rd Party imports
import OtpInput from "react18-input-otp";

const styles = {
    card: {
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        backgroundColor: 'white',
    },
    header: {
        backgroundColor: '#f2f2f2',
        padding: '18px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
    },
    table: {
        border: 'none',
    },
    tableCell: {
        fontSize: '18px',
        fontWeight: 'normal',
    },
};

export const getDisplayedAmounts = (sellAmount, buyAmount, sellCurrency, buyCurrency) => {
    const sendsAmount = getFormattedCurrencyAmountWithName(sellAmount, sellCurrency);
    const receivesAmount = getFormattedCurrencyAmountWithName(buyAmount, buyCurrency);

    return {
        sendsAmount,
        receivesAmount
    };
};

const ConfirmationModal = ({
                               open,
                               onConfirm,
                               onCancel,
                               requestData,
                               entityDetails,
                               onTOTPChange

                           }) => {
    const [totp, setTotp] = useState('');
    const sendingEntity = sessionStorage.getItem('clientName');

    const {sendsAmount, receivesAmount} = getDisplayedAmounts(
        requestData.response.sellAmount,
        requestData.response.buyAmount,
        requestData.sendCurrency,
        requestData.response.currency
    );

    const handleConfirmClick = async () => {
        await onConfirm();
    };
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogContent>
                <Paper style={styles.card}>
                    <div style={styles.header}>
                        <Typography variant="h2">Transaction Confirmation</Typography>
                    </div>
                    <TableContainer component={Paper} style={styles.table}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{...styles.tableCell}}>
                                        {entityDetails.details.name} receives: <b>   {receivesAmount} </b>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{...styles.tableCell}}>
                                        {sendingEntity} sends: <b>  {sendsAmount}</b>
                                    </TableCell>
                                </TableRow>
                                {requestData.rate !== "1" &&
                                    <TableRow>
                                        <TableCell sx={{...styles.tableCell}}>
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <div>Rate : <b>{requestData.response.rate}</b></div>
                                                <div>Inverse Rate : <b>{requestData.response.inverse}</b></div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                }
                                <TableRow>
                                    <TableCell sx={{...styles.tableCell}}> <Typography variant="h3" gutterBottom>Beneficiary
                                        Details:</Typography>
                                        {renderBankDetails(entityDetails.details, entityDetails.details.name)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{...styles.tableCell}}>
                                        Enter 6-digit authentication code:
                                        <OtpInput
                                            value={totp}
                                            onChange={(totpNumber) => {
                                                setTotp(totpNumber);
                                                onTOTPChange(totpNumber);

                                            }}
                                            numInputs={6}
                                            containerStyle={{justifyContent: 'space-between'}}
                                            inputStyle={{
                                                width: '100%',
                                                margin: '8px',
                                                padding: '10px',
                                                border: `1px solid `,
                                                borderRadius: 4,
                                                ':hover': {
                                                    borderColor: "#000"
                                                }
                                            }}
                                            focusStyle={{
                                                outline: 'none',
                                                border: `2px solid #000`
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant={"contained"} sx={{backgroundColor: "#FF0000", color: "#fff"}}
                        size={"large"}>
                    Cancel
                </Button>
                <Button
                    onClick={handleConfirmClick}
                    sx={{backgroundColor: '#008000', color: '#fff'}}
                    variant="contained"
                    size="large"
                    disabled={totp.length !== 6}
                >
                    Approve
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationModal;