// ==============================|| GET MONEY AMOUNT FORMATTED ||============================== //

const getFormattedAmount = (amount) => {
    if (!isNaN(amount)) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);
    }
    return amount;
};

export default getFormattedAmount;

