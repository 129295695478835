
// ============================|| ENSURE ALL NULLS ARE REMOVED ||============================ //
const ensureNotNull = value => ( value !== null ? value : '' )
const ensureNotNullAll = (obj) => {
  
    const result = {};
  
    for (const [fieldName, fieldValue] of Object.entries(obj)) {
      result[fieldName] = ensureNotNull(fieldValue);
    }
    return result;
  }

export default ensureNotNullAll

