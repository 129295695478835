import React, {useContext, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, Divider, Typography,} from '@mui/material';
import axios from "axios";
import OtpInput from "react18-input-otp";
import ConfigContext from "../../../store/context/ConfigContext";
import {useClientAccount} from "../../../store/context/ClientAccountContext";
import {useAuthHeaders} from "../../../auth/AuthHeaders";
import {useTheme} from "@mui/material/styles";
import CircularSpinner from "../../CircularSpinner";

const CancelModal = ({open, onCancel, transactionData, onConfirm}) => {
    const [totp, setTotp] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const config = useContext(ConfigContext);
    const {clientId} = useClientAccount();
    const authHeaders = useAuthHeaders();
    const theme = useTheme();

    const handleCancelClick = () => {
        onCancel();
    };

    const handleConfirmClick = async () => {
        setIsLoading(true);
        setError('');

        // Introduce a delay of 2 seconds before making the API call
        setTimeout(async () => {
            try {
                const response = await axios.post(`${config.API_URL}/figaro/v1/cancel`,
                    {
                        clientId: clientId,
                        totp: totp,
                        request: {
                            creditReference: transactionData.reference
                        }
                    },
                    { headers: authHeaders }
                );

                // Check for an error in the response data
                if (response.data.error) {
                    throw new Error(response.data.error.message || 'Failed to cancel the transaction');
                }

                // If no error, proceed with onConfirm
                setIsLoading(false);
                onConfirm();
            } catch (error) {
                console.error("Error canceling transaction:", error);
                setError("Error canceling transaction. Please try again.");
                setIsLoading(false);
            }
        }, 2000);
    };

    const handleTOTPChange = (totpValue) => {
        setTotp(totpValue);
    };

    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogContent>
                <Typography variant="h4">
                    Are you sure you want to cancel this transaction?
                </Typography>
                <Divider sx={{
                    borderColor: theme.palette.secondary.main,
                    my: 2
                }}/>
                <Typography variant="body2" color="error">
                    {error}
                </Typography>
                {isLoading ? <CircularSpinner/> :
                    <>
                        <Typography variant="body1">
                            Enter 6-digit authentication code:
                        </Typography>

                        <OtpInput
                            value={totp}
                            onChange={handleTOTPChange}
                            numInputs={6}
                            containerStyle={{justifyContent: 'space-between'}}
                            inputStyle={{
                                width: '100%',
                                margin: '8px',
                                padding: '10px',
                                border: `1px solid `,
                                borderRadius: 4,
                            }}
                            focusStyle={{
                                outline: 'none',
                                border: `2px solid #000`,
                            }}
                        />
                    </>
                }
                <Divider sx={{
                    borderColor: theme.palette.secondary.main,
                    my: 2
                }}/>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCancelClick}
                    color="secondary"
                    variant='contained'
                >
                    No
                </Button>
                <Button
                    onClick={handleConfirmClick}
                    color="primary"
                    variant='contained'
                >
                    Yes, Cancel
                </Button>

            </DialogActions>
        </Dialog>
    );
};

export default CancelModal;