import React, {useContext, useEffect, useRef, useState} from "react";
//3rd party imports
import {Formik} from "formik";
import * as Yup from 'yup';
// project imports
import {Box} from "@mui/material";
import BankTransactionForm from "./BankTransactionForm";
import CreateClientModal from "../../../ui-component/extended/modal/create-client-modal/CreateClientModal";
import {useAuthHeaders} from "../../../auth/AuthHeaders";
import ConfigContext from "../../../store/context/ConfigContext";
import CreditResponse from "../../response/CreditResponse";
import axios from "axios";
import CreateEntityModal from "../../../ui-component/extended/modal/CreateEntityModal";

//-------------------------------------|| Validation Schema || --------------------------------------//
const validationSchema = Yup.object().shape({
    // sendingClient: Yup.mixed.required("Sending client is required"),
    entityId: Yup.object().required("Beneficiary name is required"),
    amount: Yup.number().required("Amount is required").positive("Amount must be a positive number"),
    currency: Yup.string().required("Currency is required"),
    description: Yup.string()
        .required('Purpose is required')
        .max(35, "Purpose can't be longer than 35 characters"),
    descriptionOther: Yup.string().when('description', (description, schema) => {
        const isSelectedOther = Array.isArray(description) && description.includes('Other');
        return isSelectedOther
            ? schema.required('Please specify the purpose').max(500, "Purpose can't be longer than 500 characters")
            : schema.notRequired();
    }),
    totp: Yup.string().length(6, "TOTP must be exactly 6 digits"),
    reference: Yup.string().required("Internal Tracking Reference is required"),
    ledgerId: Yup.string().required("Ledger is required"),
    fxQuoteId: Yup.string().when('currency', {
        is: (currency) => currency !== 'USD',
        then: (schema) => schema.required('Please get a rate'),
        otherwise: (schema) => schema.notRequired()
    }),
});

//-------------------------------------|| BankTransaction || --------------------------------------//
export function BankTransaction() {
    const responseRef = useRef(null);
    const [transactionType, setTransactionType] = useState('fxCredit');
    const [showCreateBeneficiary, setShowCreateBeneficiary] = useState(false);
    const [showAddClientModal, setShowAddClientModal] = useState(false);
    const [responseModalOpen, setResponseModalOpen] = useState(false);
    const [clientData, setClientData] = useState({});
    const [bankCreditResponse, setBankCreditResponse] = useState(null);
    const clientId = sessionStorage.getItem('clientId');

    const authHeaders = useAuthHeaders();
    const config = useContext(ConfigContext);

    useEffect(() => {
        const options = {
            method: 'POST',
            url: `${config.API_URL}/bank/v1/clients`,
            headers: authHeaders,
            data: {
                bankId: clientId
            },
        };

        axios.request(options)
            .then(res => {
                setClientData(res.data);
            })
            .catch(err => {
                console.error('Error occurred while fetching clients:', err);
            });
    }, [showAddClientModal]);

    const handleTransactionSubmit = async (values, {setSubmitting}) => {
        const requestData = {
            clientId: values.sendingClient.value,
            request: {
                reference: values.reference,
                ledgerId: values.ledgerId,
                amount: values.amount,
                currency: values.currency,
                entityId: values.entityId,
                ...(values.currency !== 'USD' && values.fxQuoteId && {fxQuoteId: values.fxQuoteId}),
                when: values.when,
                text: {
                    description: values.description === 'Other' ? values.descriptionOther : values.description,
                    ...(values.addenda && {addenda: values.addenda})

                },
                transactionNote: values.transactionNote
            },
            totp: values.totp
        };

        try {
            const options = {
                method: 'POST',
                url: `${config.API_URL}/figaro/v1/payment`,
                headers: authHeaders,
                data: requestData,
            };

            const response = await axios.request(options);
            setResponseModalOpen(true);
            setBankCreditResponse(response.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                sessionStorage.clear();
                window.location.href = '/token-expiry';
            }
            console.error('Error:', error);
        } finally {
            setSubmitting(false);
        }
    };

    const clearCreditResponse = () => {
        setResponseModalOpen(false);
    };

    const initialValues = {
        sendingClient: '',
        amount: '',
        currency: '',
        description: '',
        totp: '',
        reference: '',
        ledgerId: '',
        when: 'standard',
        entityId: '',
        fxQuoteId: '',
        transactionNote: null,
    };

    const handleAddClient = () => {
        setShowAddClientModal(true);
    };

    const handleCloseCreateEntityModal = () => {
        setShowCreateBeneficiary(false);
    };

    const handleAddBeneficiary = () => {
        setShowCreateBeneficiary(true);
    };

    const handleClientModalClose = () => {
        setShowAddClientModal(false);
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleTransactionSubmit}
                enableReinitialize={true}
            >
                {({values}) => (
                    <>
                        <BankTransactionForm
                            clientData={clientData}
                            handleAddClient={handleAddClient}
                            handleAddBeneficiary={handleAddBeneficiary}
                            transactionType={transactionType}
                            setTransactionType={setTransactionType}
                            showCreateBeneficiary={showCreateBeneficiary}
                        />
                        {bankCreditResponse && (
                            <Box mt={2} ref={responseRef}>
                                <CreditResponse
                                    response={bankCreditResponse}
                                    open={responseModalOpen}
                                    clearResponse={clearCreditResponse}
                                    clientId={values.sendingClient.value}
                                />
                            </Box>
                        )}
                        <CreateEntityModal open={showCreateBeneficiary}
                                           handleClose={handleCloseCreateEntityModal}
                                           sendingClient={values.sendingClient}
                        />
                        <CreateClientModal open={showAddClientModal} handleClose={handleClientModalClose}/>
                    </>

                )}

            </Formik>
        </>
    )
        ;
}