import {DataGrid, GridToolbarQuickFilter} from "@mui/x-data-grid";
import axios from "axios";
import {useAuthHeaders} from "../../auth/AuthHeaders";
import ConfigContext from "../../store/context/ConfigContext";
import {columnGroupingModel, RolesColumns} from "./RolesColumns";
import React, {useContext, useEffect, useState} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useLocation} from "react-router-dom";
import {roleMapping} from "../../auth/availableRoles";


// ==============================|| Custom Roles Toolbar ||============================= //
export const CustomRolesToolBar = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                pb: 1,
                pl: 2,
                pr: 2,
                pt: 2,
                '& .MuiFormControl-root > .MuiInputBase-root': {
                    p: 0.6,
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: 2,
                    background: theme.palette.grey[50]
                },
                '& .MuiFormControl-root > .MuiInputBase-root:after': {
                    display: 'none'
                },
                '& .MuiFormControl-root > .MuiInputBase-root:before': {
                    display: 'none'
                },
                '& .MuiFormControl-root > .Mui-focused': {
                    border: `1px solid ${theme.palette.primary.main}`
                },
                '& .MuiSvgIcon-root': {
                    color: `${theme.palette.primary.main} !important`,
                },

            }}
        >
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h3">Permissions and Roles</Typography>
                </Grid>
                <GridToolbarQuickFilter/>
            </Grid>
        </Box>
    );
};


// ==============================|| USER ROLES DATA GRID  ||============================= //

const RolesDataGrid = ({onEditClick, onRevokeClick, onSettingsClick}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const authHeaders = useAuthHeaders();
    const config = useContext(ConfigContext);
    const realm = sessionStorage.getItem("clientId");
    const location = useLocation();
    const email = location.state?.email;

    useEffect(() => {
        const apiUrl = `${config.API_URL}/login/v1/users`;
        const requestData = {
            realm: realm,
        };

        axios.post(apiUrl, requestData, {
            headers: authHeaders,
        })
            .then((response) => {
                const uniqueUsers = {};
                response.data.users.forEach(user => {
                    uniqueUsers[user.reference] = {
                        ...user,
                        id: user.reference,
                        payment_create: user.roles.some(role => role.role === 'payment_create'),
                        beneficiary_create: user.roles.some(role => role.role === 'beneficiary_create'),
                        beneficiary_disable: user.roles.some(role => role.role === 'beneficiary_disable'),
                        quote: user.roles.some(role => role.role === 'quote'),
                        invite: user.roles.some(role => role.role === 'invite'),
                        grant: user.roles.some(role => role.role === 'grant'),
                        revoke: user.roles.some(role => role.role === 'revoke'),
                        approve_payment: user.roles.some(role => role.role === roleMapping['approve_payment']),
                        payment_cancel: user.roles.some(role => role.role === roleMapping['payment_cancel']),
                        payment_view: user.roles.some(role => role.role === roleMapping['payment_view']),
                        beneficiary_view: user.roles.some(role => role.role === roleMapping['beneficiary_view']),
                        users_view: user.roles.some(role => role.role === roleMapping['users_view']),
                    };
                });
                const updatedData = Object.values(uniqueUsers);
                setData(updatedData);
                setLoading(false);
            })

            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    sessionStorage.clear();
                    window.location.href = '/token-expiry';
                }
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [onEditClick, onRevokeClick, onSettingsClick]);

    return (
        <div style={{height: '85vh', width: '100%'}}>
            <DataGrid
                rows={data}
                columns={RolesColumns(onEditClick, onRevokeClick, onSettingsClick)}
                columnGroupingModel={columnGroupingModel}
                slots={{
                    toolbar: CustomRolesToolBar
                }}
                initialState={{
                    pagination: {paginationModel: {pageSize: 10}},
                    filter: {
                        filterModel: {
                            items: [],
                            quickFilterValues: [email]
                        }
                    }
                }}
                loading={loading}
                pageSizeOptions={[5, 10, 25]}
                sx={{
                    '& .MuiDataGrid-columnHeader': {
                        whiteSpace: 'normal',
                        padding: '5px',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontSize: {xs: '0.5rem', md: '0.60rem', lg: '0.70rem', xl: '0.80rem'},
                        whiteSpace: 'break-spaces',
                        lineHeight: 'normal',
                        textAlign: 'center',
                    },
                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                        flexDirection: 'row',
                    },
                    '& .MuiDataGrid-cell': {
                        padding: '0px',
                    }
                }}
            />
        </div>
    );
};

export default RolesDataGrid