import React, {useContext, useEffect, useState} from "react";
// materialUI imports
import {Button, Divider, IconButton, Tooltip, Typography} from "@mui/material";
import ArchiveIcon from '@mui/icons-material/Archive';
import AddIcon from '@mui/icons-material/Add';
import {useTheme} from "@mui/material/styles";
// project imports
import {DataGrid, GridFooterContainer, GridPagination} from '@mui/x-data-grid';
import {useBeneficiaries} from "../../store/context/EntityContext";
import CreateEntityModal from "../../ui-component/extended/modal/CreateEntityModal";
import useEntityDetails from "../../store/hooks/useEntityDetails";
import {RolesContext} from "../../store/context/RolesContext";
import PulsatingDot from "../../ui-component/PulsatingDot";
import {BeneficiaryDetailsModal} from "../../ui-component/extended/modal/BeneficiaryDetailsModal";
import {displayBeneficiary} from 'store/displayBeneficiary';
import {RefreshQueryToolbar} from "../../ui-component/datagrid/RefreshQueryToolbar";
import BasicModal from "../../ui-component/extended/modal/BasicModal";
import ConfigContext from "../../store/context/ConfigContext";
import {useAuthHeaders} from "../../auth/AuthHeaders";
// 3rd party imports
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import axios from 'axios';
import OtpInput from "react18-input-otp";
import {CustomNoRowsOverlay} from "../../ui-component/datagrid/CustomEmptyDataTable";

// Todo:Refactor into several files
/// ============================|| Beneficiaries Table  ||============================ //
const EntityTable = () => {
    const {entityData, triggerReload} = useBeneficiaries();
    const [sortedData, setSortedData] = useState([]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [basicModalOpen, setBasicModalOpen] = useState(false);
    const [selectedEntityId, setSelectedEntityId] = useState(null);
    const [totp, setTotp] = useState("");
    const [initialValues, setInitialValues] = useState(null);
    const [archiveClicked, setArchiveClicked] = useState(false);
    const clientId = sessionStorage.getItem('clientId');

    const location = useLocation();
    const {roles} = useContext(RolesContext);
    const config = useContext(ConfigContext);
    const apiUrl = config.API_URL;
    const authHeaders = useAuthHeaders();
    const theme = useTheme();
    const {entityDetails, error, loading} = useEntityDetails(selectedEntityId);
    const duplicateEntity = location.state?.duplicateEntityName;

    useEffect(() => {
        if (location.state) {
            if (location.state.fromCreateEntity) {
                toast.success("Success! Beneficiary was added.", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    theme: "colored",
                });
            } else if (location.state.fromDuplicateEntity) {
                toast.error("Hmm, looks like this beneficiary already exists.", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    theme: "colored",
                });
            }
        }
        handleCloseCreateModal();
        triggerReload();
    }, [location.state]);

    useEffect(() => {
        const sortEntitiesByStateAndReference = (entities) => {
            const statePriority = {
                ready: 1,
                pending: 2,
                rejected: 3,
                failed: 4,
                disabled: 5
            };

            return [...entities].sort((a, b) => {
                if (statePriority[a.state] === statePriority[b.state]) {
                    return a.reference.localeCompare(b.reference);
                }
                return statePriority[a.state] - statePriority[b.state];
            });
        };

        if (Array.isArray(entityData)) {
            setSortedData(sortEntitiesByStateAndReference(entityData));
        }
    }, [entityData]);

    const handleOpenCreateModal = (initialValues = null) => {
        setInitialValues(initialValues);
        setCreateModalOpen(true);
    };

    const handleCloseCreateModal = () => {
        setCreateModalOpen(false);
        triggerReload();
    };

    const handleOpenDetailsModal = () => {
        if (!archiveClicked) {
            setDetailsModalOpen(true);
        }
        setArchiveClicked(false); // Reset the flag
    };

    const handleCloseDetailsModal = () => {
        setDetailsModalOpen(false);
    };

    const handleRefresh = () => {
        triggerReload();
    };

    const handleRowClick = (params) => {
        setSelectedEntityId(params.row.reference);
        handleOpenDetailsModal();
    };

    const handleOpenBasicModal = (entityId) => {
        setSelectedEntityId(entityId);
        setBasicModalOpen(true);
    };

    const handleCloseBasicModal = () => {
        setBasicModalOpen(false);
    };

    const handleConfirmArchive = async () => {
        try {
            const requestData = {
                clientId: clientId,
                totp: totp,
                disable: true,
                request: {

                    entityId: selectedEntityId
                }
            };
            const config = {
                url: `${apiUrl}/figaro/v1/beneficiary`,
                method: 'POST',
                data: requestData,
                headers: authHeaders,
            };
            await axios(config);
            toast.success("Beneficiary archived successfully.");
            setBasicModalOpen(false);
            triggerReload();
        } catch (error) {
            toast.error("Failed to archive beneficiary.");
        }
    };


    let columns = [
        {
            field: 'state',
            headerName: '',
            headerAlign: 'left',
            width: 5,
            renderCell: (params) => (
                <PulsatingDot status={params.value}/>
            )
        },
        {
            field: 'name',
            headerName: 'Beneficiary Name',
            flex: 1,
            valueGetter: (value, row) => displayBeneficiary(row)
        }
    ];

    if (roles.includes("beneficiary_disable")) {
        columns.push({
            field: 'archive',
            headerName: 'Archive',
            flex: 0.25,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params) => (
                params.row.state !== 'disabled' ? (
                    <Tooltip title="Archive Beneficiary">
                        <IconButton onClick={(e) => {
                            e.stopPropagation();
                            setArchiveClicked(true);
                            handleOpenBasicModal(params.row.reference);
                        }}>
                            <ArchiveIcon/>
                        </IconButton>
                    </Tooltip>
                ) : null
            )
        });
    }

    const CustomFooter = ({onOpenCreateModal}) => (
        <GridFooterContainer>
            {roles.includes("beneficiary_create") &&
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onOpenCreateModal()}
                    startIcon={<AddIcon/>}
                    sx={{
                        m: 2,
                    }}
                    size="small"
                >
                    Create Beneficiary
                </Button>
            }
            <GridPagination/>
        </GridFooterContainer>
    );
    const archiveBeneficiaryModalContent = (
        <>
            <Typography variant="h4">
                Are you sure you want to archive {selectedEntityId}? Once confirmed below, this action is permanent and
                cannot be reversed.
            </Typography>
            <Divider sx={{my: 2}}/>
            <Typography variant="body1" sx={{mb: 2}}>
                Enter 6-digit authentication code and click confirm to proceed.
            </Typography>
            <OtpInput
                value={totp}
                onChange={setTotp}
                numInputs={6}
                containerStyle={{justifyContent: 'space-between'}}
                inputStyle={{
                    width: '100%',
                    margin: '8px',
                    padding: '10px',
                    border: `1px solid `,
                    borderRadius: 4,
                    ':hover': {
                        borderColor: "#000"
                    }
                }}
                focusStyle={{
                    outline: 'none',
                    border: `2px solid #000`
                }}
                shouldAutoFocus={true}
            />
        </>
    );
    return (
        <div style={{height: 650, width: '100%', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <BeneficiaryDetailsModal
                    open={detailsModalOpen}
                    handleClose={handleCloseDetailsModal}
                    entityDetails={entityDetails}
                    loading={loading}
                    error={error}
                    onCopy={() => handleOpenCreateModal(entityDetails)}
                />
                <CreateEntityModal
                    open={createModalOpen}
                    handleClose={handleCloseCreateModal}
                    initialValues={initialValues}
                />
                <BasicModal
                    open={basicModalOpen}
                    onClose={handleCloseBasicModal}
                    onConfirm={handleConfirmArchive}
                    content={archiveBeneficiaryModalContent}
                    title={""}
                />
            </div>
            <DataGrid
                rows={Array.isArray(sortedData) ? sortedData : []}
                columns={columns}
                getRowId={(row) => row.reference}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                style={{backgroundColor: 'white'}}
                sx={{
                    boxShadow: 2,
                    borderRadius: '8px',
                    overflow: 'hidden',
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    },
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: '700',
                            fontSize: '1rem',
                        },
                    },
                }}
                slots={{
                    toolbar: () => <RefreshQueryToolbar onRefresh={handleRefresh}/>,
                    footer: () => <CustomFooter onOpenCreateModal={handleOpenCreateModal}/>,
                    noRowsOverlay: CustomNoRowsOverlay,
                }}
                initialState={{
                    filter: {
                        filterModel: {
                            items: [],
                            quickFilterValues: [duplicateEntity]
                        }
                    }
                }}
                onRowClick={handleRowClick}
            />
        </div>
    );
};

export default EntityTable;