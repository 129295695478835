import React from 'react';
import {styled} from "@mui/system";

const StyledSpinnerContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
const MultiColorSpinner = () => (
    <svg
        width="60"
        height="60"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="20"
            cy="20"
            r="18"
            fill="none"
            strokeWidth="4"
            stroke="#0EBCF8"
            strokeDasharray="90, 150"
            strokeDashoffset="-35"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 20 20"
                to="360 20 20"
                dur="1.5s"
                repeatCount="indefinite"
            />
            <animate
                attributeName="stroke"
                values="#012169; #00B5E2;"
                dur="2s"
                repeatCount="indefinite"
            />
        </circle>
    </svg>
);


export default function CircularSpinner() {
    return (
        <StyledSpinnerContainer>
            <MultiColorSpinner/>
        </StyledSpinnerContainer>
    );
}