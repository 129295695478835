import React, {Fragment, useContext} from "react";

// material-ui
import {Box, Card, CardHeader, Divider, Grid, Typography} from "@mui/material";
// project imports
import GetFxQuote from "../requests/GetFxQuote";
import {useNavigate} from "react-router-dom";
import {RolesContext} from "../store/context/RolesContext";
import {useTheme} from "@mui/material/styles";
import useLedgers from "../store/hooks/useLedgerDetails";


// ============================|| PAGE - Quote ||============================ //
const Quote = () => {
    const {roles} = useContext(RolesContext)
    const navigate = useNavigate();
    const theme = useTheme();
    const {activeLedgers, loading: ledgerLoading} = useLedgers();

    if (!roles.includes("quote")) {
        navigate("/");
    }

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={8} sx={{mt:5}}>
                <Card
                    sx={{
                        border: '1px solid',
                        borderColor: theme.palette.primary.light,
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        minHeight: '150px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        ':hover': {
                            boxShadow: '2px 2px 24px 0 rgb(32 40 45 / 8%)'
                        },
                    }}
                >
                    <CardHeader sx={{p: 2.5}} title={<Typography variant="h4"> Get Fx Quote <Fragment></Fragment></Typography>}/>
                    <Divider
                        sx={{
                            opacity: 1,
                            borderColor: theme.palette.primary.light
                        }}
                    />
                    <Box sx={{p: 2}}>
                        <GetFxQuote activeLedgers={activeLedgers} ledgerLoading={ledgerLoading}/>
                    </Box>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Quote;

