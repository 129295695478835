import * as Yup from "yup";
import {ibanRegex} from "./Visab2bForm";

const ansRegex = /^[A-Za-z0-9 .?!,:;_@&\/\\'"~()<>#$%+\-=]*$/;
const swiftRegex = /^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/;

// ============================|| Form Data - Validation Schemas ||============================ //

const createAnsErrorMessage = field => `${field} must contain only alphanumeric characters and special characters (.?!,:;_@&/'"~()<>#$%+-=).`;

export const WireSchema = Yup.object().shape({
    InfoType: Yup.string().required('Network Type is required'),
    type: Yup.string().required('Entity Type is required'),
    entityId: Yup.string().required('Entity Reference is required'),
    name: Yup.string()
        .required('Name is required')
        .matches(ansRegex, createAnsErrorMessage('Name')),

    paymentType: Yup.string().required('Payment Account Type is required'),
    iban: Yup.string()
        .test(
            'conditional required',
            'IBAN is required',
            function (value) {
                const { paymentType } = this.parent;
                return paymentType === 'iban' ? !!value : true;
            }
        )
        .matches(ibanRegex, 'Invalid IBAN format')
        .matches(ansRegex, createAnsErrorMessage('IBAN')),

    account: Yup.string()
        .test(
            'conditional required',
            'Account is required',
            function (value) {
                const { paymentType } = this.parent;
                return paymentType === 'account' ? !!value : true;
            }
        )
        .matches(/^\d+$/, 'Account Number must be in correct format')
        .matches(ansRegex, createAnsErrorMessage('Account Number')),

    addressLine1: Yup.string().required('Address Line 1 is required')
        .max(35, 'Address Line 1 must be at most 35 characters')
        .matches(ansRegex, createAnsErrorMessage('Address Line 1')),

    addressLine2: Yup.string()
        .max(35, 'Address Line 2 must be at most 35 characters')
        .matches(ansRegex, createAnsErrorMessage('Address Line 2')),

    addressLine3: Yup.string()
        .max(35, 'Address Line 3 must be at most 35 characters')
        .matches(ansRegex, createAnsErrorMessage('Address Line 3')),

    bankName: Yup.string()
        .required('Bank name is required')
        .matches(ansRegex, createAnsErrorMessage('Bank name')),

    swift: Yup.string()
        .required('SWIFT code is required')
        .matches(swiftRegex, 'Invalid SWIFT code format')
        .matches(ansRegex, createAnsErrorMessage('SWIFT code')),

    bankAddressLine1: Yup.string().required('Bank Address Line 1 is required')
        .max(35, 'Bank Address Line 1 must be at most 35 characters')
        .matches(ansRegex, createAnsErrorMessage('Bank Address Line 1')),

    bankAddressLine2: Yup.string()
        .max(35, 'Bank Address Line 2 must be at most 35 characters')
        .matches(ansRegex, createAnsErrorMessage('Bank Address Line 2')),

    bankAddressLine3: Yup.string()
        .max(35, 'Bank Address Line 3 must be at most 35 characters')
        .matches(ansRegex, createAnsErrorMessage('Bank Address Line 3')),
});