import React from 'react';
import {Button, Card, CardHeader, Divider, Typography} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";

const StartPayment = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleStartPayment = () => {
        navigate('/payment');
    };
    return (
        <>
            <Card
                sx={{
                    border: '1px solid',
                    borderColor: theme.palette.primary.light,
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    minHeight: '150px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    ':hover': {
                        boxShadow: '2px 2px 24px 0 rgb(32 40 45 / 8%)'
                    },
                }}
            >
                <CardHeader sx={{p: 2.5}} title={<Typography variant="h4"> Start Payment</Typography>}/>
                <Divider
                    sx={{
                        opacity: 1,
                        borderColor: theme.palette.primary.light
                    }}
                />
                <Typography variant="h6" gutterBottom align="center" sx={{flexGrow: 1}}>
                </Typography>
                <Button
                    variant="contained"
                    color="primary" sx={{mb: 2, alignSelf: 'center'}}
                    onClick={handleStartPayment}
                >
                    Start Payment
                </Button>
            </Card>
        </>
    );
}

export default StartPayment;