import React from 'react';

// material-ui
import {DataGrid} from '@mui/x-data-grid';

// project imports
import getFormattedCurrencyAmount from "../store/getFormattedCurrencyAmount";
import useLedgers from "../store/hooks/useLedgerDetails";


// ============================|| Get Ledgers Table  ||============================ //
const GetLedgers = () => {
    const {ledgersData, loading, error} = useLedgers();

    const columns = [
        // { field: 'ledgerId', headerName: 'Currency Account', flex: 2 },
        // { field: 'state', headerName: 'State', flex: 1 },

        {field: 'description', headerName: 'Account Name', flex: 1},
        {
            field: 'balance', headerName: 'Balance', flex: 1,
            valueGetter: (value, row) => {
                if (row) {
                    return `${getFormattedCurrencyAmount(row.balance, row.currency)}`;
                }
                return '';
            }
        },
        // {field: 'default', headerName: 'Default', width: 120, type: 'boolean'},
        // {field: 'accounts', headerName: 'Accounts', width: 130, valueGetter: (value,row) => row.value.join(', ')},
        {
            field: 'available', headerName: 'Available', flex: 1,
            valueGetter: (value, row) => {
                if (row) {
                    return `${getFormattedCurrencyAmount(row.available, row.currency)}`;
                }
                return '';
            }
        },
    ];

    return (
        <div style={{height: 650, width: '100%', display: 'flex', flexDirection: 'column'}}>
            {loading ? <p>Loading ledgers...</p> :
                error ? <p>{error.message}</p> :
                    <DataGrid
                        rows={ledgersData}
                        columns={columns}
                        getRowId={(row) => row.ledgerId}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 20]}
                        sx={{
                            boxShadow: 2,
                            borderRadius: '8px',
                            overflow: 'hidden',
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#f5f5f5',
                                },
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: 'primary.main',
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: '700',
                                    fontSize: '1rem',
                                },
                            },
                        }}
                    />
            }
        </div>
    );
};

GetLedgers.propTypes = {};

export default GetLedgers;