const imagePaths = [
    "https://res.cloudinary.com/dpjmfhwkb/image/upload/v1718747821/background11_qoyv2h.jpg",
    "https://res.cloudinary.com/dpjmfhwkb/image/upload/v1718747820/background6_bxias6.jpg",
    "https://res.cloudinary.com/dpjmfhwkb/image/upload/v1718747820/background13_y2vgry.jpg",
    "https://res.cloudinary.com/dpjmfhwkb/image/upload/v1718747818/background12_euit0l.jpg",
    "https://res.cloudinary.com/dpjmfhwkb/image/upload/v1718747818/background9_gwp3fq.jpg",
    "https://res.cloudinary.com/dpjmfhwkb/image/upload/v1718747818/background10_ftia3r.jpg",
    "https://res.cloudinary.com/dpjmfhwkb/image/upload/v1718747816/background2_y6azjo.jpg",
    "https://res.cloudinary.com/dpjmfhwkb/image/upload/v1718747817/background8_saghwf.jpg",
    "https://res.cloudinary.com/dpjmfhwkb/image/upload/v1718747817/background4_ai5i5r.jpg",
    "https://res.cloudinary.com/dpjmfhwkb/image/upload/v1718747817/background7_v0mys1.jpg",
    "https://res.cloudinary.com/dpjmfhwkb/image/upload/v1718747815/background5_f5wiy3.jpg",
    "https://res.cloudinary.com/dpjmfhwkb/image/upload/v1718747815/background3_ua8n8x.jpg",
    "https://res.cloudinary.com/dpjmfhwkb/image/upload/v1718747815/background1_neyrrn.jpg",
];
export const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * imagePaths.length);
    return imagePaths[randomIndex];
};